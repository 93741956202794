import { Button, Card, Col, Divider, Row } from "antd";
import { VictoryPie } from "victory";
import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import colors, { chartColors } from "../../../utils/colors";
import CardHeader from "../../molecules/CardHeader";
import { RootState } from "../../../configureStore";
import LoadOverlay from "../../atoms/LoadOverlay";
import NoYet from "../../atoms/NoYet";

const WorkLoad = ({ isAdmin = false }: { isAdmin?: boolean }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [selected, setSelected] = useState<
        'Not Started'
        | 'In Progress'
        | 'Completed'
    >('Not Started');
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['insights/GET_INSIGHTS']);
    const itemCounts = useSelector<RootState, { 'Not Started': number; 'In Progress': number; 'Completed': number; }>(({ insight }) => Object.values(insight.allInsights).reduce((acc, curr) => {
        const status = curr.status === 'Not started' ? 'Not Started' : curr.status === 'In progress' ? 'In Progress' : curr.status === 'Completed' ? 'Completed' : null;
        if (status) {
            return {
                ...acc,
                [status]: acc[status] + 1,
            };
        }
        return acc;
    }, {
        'Not Started': 0,
        'In Progress': 0,
        Completed: 0,
    }));

    const data = useMemo(() => {
        const active = itemCounts[selected];
        const inactive = Object.values(itemCounts).reduce((acc, curr) => acc + curr, 0);
        return [
            { x: selected, y: active },
            { x: 'inactive', y: inactive - active },
        ];
    }, [selected, itemCounts]);
    const total = useMemo(() => Object.values(itemCounts).reduce((acc: number, curr) => acc + curr, 0), [itemCounts]);

    return (
        <Card
            style={{
                height: 320,
            }}
        >
            <CardHeader
                title="Work Load"
                subtitle={`${total} tasks`}
                icon="CheckSquareFilled"
            />
            <Divider style={{ marginBottom: 0 }} />
            {
                loading
                    ? (<LoadOverlay height={240} width="95%" bgColor={colors.white} />)
                    : !total
                        ? (<NoYet height={300} title="insights" />)
                        : (
                            <div className="col flex-center-between w100" style={{ height: 240 }}>
                                <Row className="justify-between" style={{ height: 180, width: '100%' }}>
                                    <Col span={8} className="col" style={{ height: 180, justifyContent: 'space-around' }}>
                                        <div onClick={() => { setSelected('Not Started'); }} className="pointer flex-center-between"><p className={selected === 'Not Started' ? 'activeText lightBlue--text' : ''}>Not Started</p><h6 className={selected === 'Not Started' ? 'activeText lightBlue--text' : ''}>{itemCounts['Not Started']}</h6></div>
                                        <div onClick={() => { setSelected('In Progress'); }} className="pointer flex-center-between"><p className={selected === 'In Progress' ? 'activeText lightBlue--text' : ''}>In Progress</p><h6 className={selected === 'In Progress' ? 'activeText lightBlue--text' : ''}>{itemCounts['In Progress']}</h6></div>
                                        <div onClick={() => { setSelected('Completed'); }} className="pointer flex-center-between"><p className={selected === 'Completed' ? 'activeText lightBlue--text' : ''}>Completed</p><h6 className={selected === 'Completed' ? 'activeText lightBlue--text' : ''}>{itemCounts.Completed}</h6></div>
                                    </Col>
                                    <Col span={4} />
                                    <Col span={10}>
                                        <svg width={180} height={180}>
                                            <text x="50%" y="46%" dominantBaseline="middle" textAnchor="middle" style={{ fontSize: 24 }}>{itemCounts[selected]}</text>
                                            <text x="50%" y="58%" dominantBaseline="middle" textAnchor="middle" style={{ fontSize: 10 }}>{selected}</text>
                                            <VictoryPie
                                                animate
                                                standalone={false}
                                                colorScale={chartColors}
                                                width={180}
                                                height={180}
                                                innerRadius={50}
                                                labels={[]}
                                                data={data}
                                            />
                                        </svg>
                                    </Col>
                                    <Col span={2} />
                                </Row>
                                <Button
                                    type="default"
                                    className="w100 mb-4"
                                    style={{ justifySelf: 'flex-end' }}
                                    onClick={() => { navigate(isAdmin ? `${location.pathname}/insights` : 'insights'); }}
                                >
                                    View All
                                </Button>
                            </div>
                        )
            }
        </Card>
    );
};
export default WorkLoad;
