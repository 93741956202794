import { useMemo, useState } from "react";
import './style.css';
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, MenuProps, Radio } from "antd";
import Icon from "../../atoms/Icon";
import colors from "../../../utils/colors";
import { RootState } from "../../../configureStore";
import { Insight, updateInsight } from "../../../redux/reducer/insight";
import InsightEventPicker from "../InsightEventPicker";
import InsightModal from "../../organisms/Insights/InsightModal";
import { Client } from "../../../redux/reducer/clients";

interface InsightMenuParams {
    id: string;
}

const InsightMenu = ({
    id,
}: InsightMenuParams) => {
    const dispatch = useDispatch<any>();
    const [showTooltip, setShowTooltip] = useState(false);
    const [insightModal, setInsightModal] = useState(false);
    const insight = useSelector<RootState, Insight>(({ insight: { allInsights } }) => allInsights[id]);
    const client = useSelector<RootState, Client>(({ clients }) => (insight.id ? clients.allClients[insight.clientId] : {}));

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    const setPriority = (priority: 'Low' | 'Medium' | 'High') => {
        dispatch(updateInsight({
            insight,
            priority,
        }));
    };

    const priorityItems: MenuProps['items'] = useMemo(() => [
        {
            key: 'priority-low',
            label: (
                <Radio checked={insight.priority === 'Low'} onClick={() => { setPriority('Low'); }}>Low</Radio>
            ),
        },
        {
            key: 'priority-med',
            label: (
                <Radio checked={insight.priority === 'Medium'} onClick={() => { setPriority('Medium'); }}>Medium</Radio>
            ),
        },
        {
            key: 'priority-hgh',
            label: (
                <Radio checked={insight.priority === 'High'} onClick={() => { setPriority('High'); }}>High</Radio>
            ),
        },
    ], [insight.priority]);

    const eventItem: MenuProps['items'] = useMemo(() => [
        {
            key: 'date',
            label: (
                <InsightEventPicker id={id} />
            ),
        },
    ], [id]);

    return (
        <div
            onClick={(event) => {
                event?.stopPropagation();
            }}
        >
            <InsightModal
                setOpen={setInsightModal}
                open={insightModal}
                insight={insight}
                client={client}
            />
            <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <Icon
                    name="CaretRightOutlined"
                />
                {showTooltip && (
                    <div
                        style={{
                            position: 'absolute',
                            width: 100,
                            height: 30,
                            top: 16,
                            right: 10,
                        }}
                        className="br-md background tooltipMenu px-4 flex-center-between"
                    >
                        <Dropdown
                            menu={{ items: priorityItems }}
                            trigger={["hover", "click"]}
                        >
                            <Icon
                                name={insight.priority === 'Low' ? 'FlagOutlined' : 'FlagFilled'}
                                className="z1"
                                color={insight.priority === 'High' ? colors.danger : insight.priority === 'Medium' ? colors.midYellow : undefined}
                                onClick={(event) => {
                                    event?.stopPropagation();
                                }}
                            />
                        </Dropdown>
                        <Dropdown
                            menu={{ items: eventItem }}
                            trigger={["hover", "click"]}
                        >
                            <Icon
                                name={insight.event ? "CalendarFilled" : "CalendarOutlined"}
                                className="z1"
                                onClick={(event) => {
                                    event?.stopPropagation();
                                }}
                            />
                        </Dropdown>
                        <Icon
                            name={insight.event ? "CheckCircleFilled" : "CheckOutlined"}
                            className="z1"
                            onClick={(event) => {
                                event?.stopPropagation();
                                setInsightModal(true);
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default InsightMenu;
