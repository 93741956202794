import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { VictoryPie } from "victory";
import { RootState } from "../../configureStore";
import { Employee, Performance } from "../../redux/reducer/employees";
import colors from "../../utils/colors";
import { formatShortUSD } from "../../utils";
import Icon from "../atoms/Icon";

interface PerformerParams {
    performance: Performance;
}

const Performer = ({
    performance,
}: PerformerParams) => {
    const navigate = useNavigate();
    const advisor = useSelector<RootState, Employee>(({ employees: { allEmployees } }) => allEmployees[performance.advisorId]);

    return !advisor ? (<Icon name="LoadingOutlined" />) : (
        <Row
            key={advisor.id}
            className="flex-center-between w100"
            onClick={() => { navigate(`/employees/${advisor.id}`); }}
        >
            <Col span={6}>
                <h6 className="truncated">
                    {advisor.firstName} {advisor.lastName}
                </h6>
            </Col>
            <Col span={14} className="flex-r flex-center-around">
                <svg width={50} height={50}>
                    <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" className="h3">CM</text>
                    <VictoryPie
                        animate
                        standalone={false}
                        width={50}
                        height={50}
                        innerRadius={16}
                        radius={24}
                        data={[{ x: 1, y: performance.completedCount }, { x: 2, y: performance.totalCount - performance.completedCount }]}
                        // labels={() => null}
                        style={{
                            data: {
                                fill: ({ datum }) => (datum.x === 1 ? colors.darkGreen : colors.background),
                            },
                        }}
                    />
                </svg>
                <svg width={50} height={50}>
                    <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" className="h3">PR</text>
                    <VictoryPie
                        animate
                        standalone={false}
                        width={50}
                        height={50}
                        innerRadius={16}
                        radius={24}
                        data={[{ x: 1, y: performance.inProgressCount }, { x: 2, y: performance.totalCount - performance.inProgressCount }]}
                        // labels={() => null}
                        style={{
                            data: {
                                fill: ({ datum }) => (datum.x === 1 ? colors.darkGreen : colors.background),
                            },
                        }}
                    />
                </svg>
                <svg width={50} height={50}>
                    <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" className="h3">NS</text>
                    <VictoryPie
                        animate
                        standalone={false}
                        width={50}
                        height={50}
                        innerRadius={16}
                        radius={24}
                        data={[{ x: 1, y: performance.notStartedCount }, { x: 2, y: performance.totalCount - performance.notStartedCount }]}
                        // labels={() => null}
                        style={{
                            data: {
                                fill: ({ datum }) => (datum.x === 1 ? colors.darkGreen : colors.background),
                            },
                        }}
                    />
                </svg>
            </Col>
            <Col span={4} className="flex-r flex-center-around">
                <h5>{formatShortUSD(performance.completedAmount)}</h5>
            </Col>
        </Row>
    );
};

export default Performer;
