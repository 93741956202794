import { useEffect, useMemo } from "react";
import { MenuOutlined } from "@ant-design/icons";
import { useMsal } from "@azure/msal-react";
import { Dropdown, MenuProps, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../../configureStore";
import { Me } from "../../redux/reducer/auth";
import Icon from "../atoms/Icon";
import Profile from "../molecules/Profile";
import { Institution, getInstitutions, setViewAs } from "../../redux/reducer/administration";

interface AppHeaderParams {
    toggleMenu: () => void,
}

const AppHeader = ({
    toggleMenu,
}: AppHeaderParams) => {
    const location = useLocation();
    const { instance } = useMsal();
    const navigate = useNavigate();
    const me = useSelector<RootState, Me>(({ auth }) => auth.me ?? {});
    const fiId = useSelector<RootState, string>(({ auth }) => auth.fiId ?? '');
    const level = useSelector<RootState, number>(({ auth }) => auth.level);
    const institutions = useSelector<RootState, Institution[]>(({ administration }) => administration.institutions);
    const viewAs = useSelector<RootState, string>(({ administration }) => administration.viewAs);
    const dispatch = useDispatch<any>();

    useEffect(() => {
        if (!level) {
            dispatch(getInstitutions());
        }
    }, [level]);

    const insts = useMemo<{ value: string; label: string; }[]>(() => institutions.map(({ name, id }) => ({ label: name, value: id })), [level, institutions]);

    const onChange = (id: string) => {
        dispatch(setViewAs({
            id: id === fiId ? null : id,
        }));
    };

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <div
                    className="flex-center row pointer"
                    onClick={() => { navigate('/settings'); }}
                >
                    <Profile
                        size="30px"
                        className="mr-2 br-sm"
                        firstName={me.firstName}
                        lastName={me.lastName}
                        image={me.imageUrl}
                    />
                </div>
            ),
        },
        {
            label: 'Logout',
            key: '4',
            danger: true,
            onClick: () => {
                instance.logoutRedirect();
            },
        },
    ];

    return (
        <div
            className="flex-center-between row"
            style={{ height: '91px' }}
        >
            <div className="flex-center row">
                <img
                    width={170}
                    src="/wordmark.png"
                    alt="Halcyon"
                    style={{
                        marginRight: '16px',
                    }}
                />
                <MenuOutlined onClick={() => toggleMenu()} />
            </div>
            <div className="flex-center-center row-r">
                <Dropdown
                    menu={{ items }}
                    trigger={["click"]}
                >
                    <div className="flex-center row ml-8">
                        <Profile
                            size="50px"
                            className="mr-2 br-sm"
                            firstName={me.firstName}
                            lastName={me.lastName}
                            image={me.imageUrl}
                        />
                        <Icon
                            name="DownOutlined"
                        />
                    </div>
                </Dropdown>
                {level === 0 && (
                    <Select
                        style={{ width: 240 }}
                        defaultValue={viewAs ?? fiId}
                        options={insts}
                        onChange={onChange}
                        disabled={location?.pathname?.startsWith('/administration/')}
                    />
                )}
                {/* <Icon
                    name="BellOutlined"
                    badge={5}
                    size="32px"
                    onClick={() => { }}
                /> */}
            </div>
        </div>
    );
};

export default AppHeader;
