import { Radio, Row, Select } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment, { now } from "moment";

import { RootState } from "../../configureStore";
import InfoCard from "../organisms/InfoCard";
import Icon from "../atoms/Icon";
import AddInsight from "../molecules/AddInsight";
import InsightsTable from "../organisms/Insights/Table";
import Board from "../organisms/Insights/Board";

const AdvisorHome = () => {
    const [tab, setTab] = useState('list');
    const [priority, setPriority] = useState<string | null>(null);
    const [status, setStatus] = useState<string | null>(null);
    const level = useSelector<RootState, number>(({ auth }) => auth.level);
    const totals = useSelector<RootState, { newClients: number; clients: number; enrolled: number; newInsights: number; tasks: number }>(({ insight: { allInsights, insights }, clients: { myClients, allClients } }) => {
        let enrolled = 0;
        let newClients = 0;
        let newInsights = 0;
        let tasks = 0;

        myClients.forEach((id: string) => {
            enrolled += allClients[id].accepted ? 1 : 0;
            newClients += moment(allClients[id].createdAt).add(1, 'd').isAfter(now()) ? 1 : 0;
        });

        insights.forEach((id: string) => {
            if (myClients.includes(allInsights[id].clientId)) {
                newInsights += moment(allInsights[id].createdAt).add(1, 'd').isAfter(now()) ? 1 : 0;
                tasks += allInsights[id].status === 'Not started' ? 1 : 0;
            }
        });

        return {
            clients: myClients.length,
            newClients,
            enrolled,
            newInsights,
            tasks,
        };
    });

    return (
        <div>
            <Row
                justify="space-between"
            >
                <InfoCard
                    iconName="UserOutlined"
                    title={`${totals.newClients} new clients`}
                    iconColor="#2b3494"
                    iconBackground="#f2f3f9"
                    subtitle={(
                        <div>
                            {totals.enrolled} enrolled / <Link to="/clients">{totals.clients} total</Link>
                        </div>
                    )}
                />
                <InfoCard
                    iconName="BulbTwoTone"
                    title={`${totals.newInsights} new insights`}
                    span={7}
                    iconColor="#009280"
                    iconBackground="#F2F9F8"
                    subtitle={(
                        <div>
                            <Link to="/insights">See history</Link>
                        </div>
                    )}
                />
                <InfoCard
                    iconName="CheckOutlined"
                    iconColor="#2A4EF6"
                    iconBackground="#edf5fd"
                    title={`${totals.tasks} new tasks`}
                />
            </Row>
            <div
                style={{
                    width: "100%",
                    position: 'relative',
                }}
                className="mt-4 flex-center-center"

            >
                <Radio.Group
                    value={tab}
                    onChange={(e) => setTab(e.target.value)}
                    buttonStyle="solid"
                    className="mb-2"
                >
                    <Radio.Button value="list">List</Radio.Button>
                    <Radio.Button value="board">Board</Radio.Button>
                </Radio.Group>
                <div
                    style={{
                        position: 'absolute',
                        right: 0,
                    }}
                >
                    {
                        tab === "list" ? (
                            <div className="flex row-r">
                                <div
                                    className="mx-2"
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    <Icon
                                        name="TagFilled"
                                        className="z1"
                                        style={{
                                            position: 'absolute',
                                            left: 8,
                                            top: 8,
                                        }}
                                    />
                                    <Select
                                        defaultValue="All"
                                        style={{ width: 120 }}
                                        onChange={(e) => {
                                            setStatus(e === "All" ? null : e);
                                        }}
                                        className="select-offset"
                                        options={[
                                            { value: 'All', label: 'All' },
                                            { value: 'Not started', label: 'Not started' },
                                            { value: 'In progress', label: 'In progress' },
                                            { value: 'Completed', label: 'Completed' },
                                        ]}
                                    />
                                </div>
                                <div
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    <Icon
                                        name="FlagFilled"
                                        className="z1"
                                        style={{
                                            position: 'absolute',
                                            left: 8,
                                            top: 8,
                                        }}
                                    />
                                    <Select
                                        defaultValue="All"
                                        style={{ width: 120 }}
                                        onChange={(e) => {
                                            setPriority(e === "All" ? null : e);
                                        }}
                                        className="select-offset white"
                                        options={[
                                            { value: 'All', label: 'All' },
                                            { value: 'High', label: 'High' },
                                            { value: 'Medium', label: 'Medium' },
                                            { value: 'Low', label: 'Low' },
                                        ]}
                                    />
                                </div>
                                <div className="flex-center-center mx-2">
                                    {
                                        process.env.REACT_APP_ENVIRONMENT !== 'production' && level === 0 && (
                                            <AddInsight />
                                        )
                                    }
                                </div>
                            </div>
                        ) : <div />
                    }
                </div>
            </div>
            {
                tab === "list" ? (
                    <InsightsTable
                        priority={priority}
                        status={status}
                        myClients
                    />
                ) : <Board />
            }
        </div>
    );
};

export default AdvisorHome;
