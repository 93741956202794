import { combineReducers } from "redux";

import administration from "./administration";
import auth from "./auth";
import clients from "./clients";
import employees from "./employees";
import insight from "./insight";
import notification from "./notification";
import status from "./status";

const rootReducer = combineReducers({
    administration,
    auth,
    clients,
    employees,
    insight,
    notification,
    status,
});
export default rootReducer;
