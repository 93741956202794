import axios from "axios";

const endpoint = process.env.REACT_APP_ENDPOINT || 'http://localhost:7071/api';

export const get = (path: string, payload?: any, viewAs?: string) => {
    let params = '';
    if (Object.keys(payload ?? {}).length > 0) {
        params = '?';
        Object.keys(payload).forEach((key) => {
            params += `${key}=${payload[key]}&`;
        });
        params = params.substring(0, params.length - 1);
    }
    return axios.get(`${endpoint}${path}${params}`, {
        ...viewAs && {
            headers: {
                'X-View-As': viewAs,
            },
        },
    });
};

export const post = (path: string, payload: any, viewAs?: string) => axios.post(`${endpoint}${path}`, payload, {
    ...viewAs && {
        headers: {
            'X-View-As': viewAs,
        },
    },
});
export const postMulti = (path: string, data: FormData) => fetch(
    `${endpoint}${path}`,
    {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_SUB_KEY ?? '',
        },
        body: data,
    },
);

export const patch = (path: string, payload: any, viewAs?: string) => axios.patch(`${endpoint}${path}`, payload, {
    ...viewAs && {
        headers: {
            'X-View-As': viewAs,
        },
    },
});

export const del = (path: string, _: any, viewAs?: string) => axios.delete(`${endpoint}${path}`, {
    ...viewAs && {
        headers: {
            'X-View-As': viewAs,
        },
    },
});

const request = (dispatch: any, type: string, payload: any) => {
    // eslint-disable-next-line no-param-reassign
    if (payload.onSuccess) delete payload.onSuccess;

    dispatch({
        type,
        payload,
    });
};

export default request;
