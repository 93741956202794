import { Button, Col, Form, Modal, Row, Switch, Table } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../configureStore";
import { createInstitution, getInstitutions, updateInstitution } from "../../redux/reducer/administration";
import Icon from "../atoms/Icon";
import InputField from "../atoms/InputField";
import InstitutionModal from "../organisms/InstitutionModal";
import colors from "../../utils/colors";

const Institutions = () => {
    const [add, setAdd] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [institution, setInstitution] = useState<any>();
    const dispatch = useDispatch<any>();
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['administration/GET_INSTITUTIONS']);
    const updating = useSelector<RootState, boolean>(({ status }) => status.loading['administration/UPDATE_INSTITUTION'] || status.loading['administration/CREATE_INSTITUTION']);
    const institutions = useSelector<RootState, any[]>(({ administration }) => administration.institutions);

    useEffect(() => {
        dispatch(getInstitutions());
    }, []);

    const delInst = () => { };

    const updateInst = (form: {
        name: string;
        fqdn: string;
        id: string;
        defaultAdmin: string;
        enabled: boolean;
        firstName: string;
        lastName: string;
        phone: string;
        spDomain: string;
        surePrep: {
            domain: string;
            username: string;
            password: string;
            apiKey: string;
        };
        tenants: string[];
    }) => {
        const {
            name,
            fqdn,
            defaultAdmin,
            enabled,
        } = form;
        if (
            institution
            && (
                name !== institution?.name
                || fqdn !== institution?.fqdn
                || defaultAdmin !== institution?.defaultAdmin
                || enabled !== institution?.enabled
                || Boolean(form.spDomain && form.surePrep.username && form.surePrep.password && form.surePrep.apiKey)
            )
        ) {
            dispatch(updateInstitution({
                id: institution.id,
                ...form.defaultAdmin && { defaultAdmin: form.defaultAdmin },
                ...form.name && { name: form.name },
                ...form.fqdn && { fqdn: form.fqdn },
                ...(typeof form.enabled === 'boolean') && { enabled: Boolean(form.enabled) },
                ...Boolean(form.spDomain && form.surePrep.username && form.surePrep.password && form.surePrep.apiKey) && {
                    surePrep: {
                        ...form.spDomain && { domain: form.spDomain },
                        ...form.surePrep.username && { userName: form.surePrep.username },
                        ...form.surePrep.password && { password: form.surePrep.password },
                        ...form.surePrep.apiKey && { apiKey: form.surePrep.apiKey },
                    },
                },
                ...form.tenants && { tenants: form.tenants },
            }));
        } else if (!institution) {
            dispatch(createInstitution({
                defaultAdmin: form.defaultAdmin,
                name: form.name,
                fqdn: form.fqdn,
                enabled: true,
                id: form.id,
                firstName: form.firstName,
                lastName: form.lastName,
                phone: form.phone,
                surePrep: {
                    domain: form.spDomain,
                    userName: form.surePrep.username,
                    password: form.surePrep.password,
                    apiKey: form.surePrep.apiKey,
                },
                tenants: form.tenants,
            }));
        }

        // setAdd(false);
    };

    const removeTenant = (idx: number) => {
        try {
            const newTen: string[] = [...institution.tenants];
            newTen.splice(idx, 1);
            dispatch(
                updateInstitution({
                    id: institution.id,
                    tenants: newTen,
                    onSuccess: () => {
                        setAdd(false);
                    },
                }),
            );
        } catch (err) {
            console.error(err);
        }
    };

    const tenants = useMemo<number>(() => institution?.tenants?.length ?? 1, [institution]);

    return (
        <div>
            <Modal
                open={confirm}
                onCancel={() => { setConfirm(false); }}
                onOk={delInst}
                okText="Yes"
                cancelText="No, keep institution"
                zIndex={1000}
            >
                Deleting this institution cannot be undone. Continue and delete institution?
            </Modal>
            <InstitutionModal
                add={add}
                setAdd={setAdd}
                onCancel={() => { if (institution) setInstitution(undefined); setAdd(false); }}
                onOk={() => { if (institution) setInstitution(undefined); setAdd(false); }}
                institution={institution}
                updateInst={updateInst}
            />
            <Modal
                open={add}
                closable={false}
                onCancel={() => { if (institution) setInstitution(undefined); setAdd(false); }}
                onOk={() => { if (institution) setInstitution(undefined); setAdd(false); }}
                destroyOnClose
                footer={null}
            >
                <>
                    <h3 className="mb-4">
                        {institution ? 'Edit' : 'Add'} Institution
                    </h3>
                    <Form onFinish={updateInst} initialValues={institution}>
                        {institution && <h4 className="mb-2">FI Code: {institution.id}</h4>}
                        {institution ? (
                            <>
                                <h5>Tenant IDs</h5>
                                {
                                    institution.tenants.map((_tid: string, idx: number) => (
                                        <Row key={`inst-${idx}`} className="flex-start" gutter={16}>
                                            <Col span={22}><Form.Item name={["tenants", idx]}><InputField disabled={Boolean(institution)} placeholder="Tenant ID" required={idx === 0} /></Form.Item></Col>
                                            <Col span={2}>
                                                <Icon
                                                    className="mt-2 pointer"
                                                    size={24}
                                                    color={colors.danger}
                                                    name="CloseSquareOutlined"
                                                    onClick={(updating || institution.tenants.length === 1) ? undefined : () => removeTenant(idx)}
                                                />
                                            </Col>
                                        </Row>
                                    ))
                                }
                                <Row className="flex-start" gutter={16}>
                                    <Col span={24}><Form.Item name={["tenants", tenants]}><InputField placeholder="Tenant ID" /></Form.Item></Col>
                                </Row>
                            </>
                        ) : <Row><Col span={24}><Form.Item name={["tenants", 0]}><InputField disabled={Boolean(institution)} placeholder="Tenant ID" required /></Form.Item></Col></Row>}
                        <Row><Col span={24}><Form.Item name="name"><InputField placeholder="Institution Name" required /></Form.Item></Col></Row>
                        <Row><Col span={24}><Form.Item name="fqdn"><InputField placeholder="Domain" required /></Form.Item></Col></Row>
                        <Row><Col span={24}><Form.Item name="defaultAdmin"><InputField placeholder="Default Admin" required /></Form.Item></Col></Row>
                        <Row gutter={16} className="flex-center-between">
                            <Col span={12}><Form.Item name="firstName"><InputField placeholder="Admin First Name" /></Form.Item></Col>
                            <Col span={12}><Form.Item name="lastName"><InputField placeholder="Admin Last Name" /></Form.Item></Col>
                        </Row>
                        <Row gutter={16} className="flex-center-between">
                            <Col span={12}><Form.Item name="phone"><InputField placeholder="Admin Phone Number" /></Form.Item></Col>
                            <Col className="flex-center-center" style={{ marginTop: -24 }} span={12}><h3 className="mr-3">Enabled</h3><Form.Item valuePropName="checked" name="enabled" className="mb-0"><Switch defaultChecked /></Form.Item></Col>
                        </Row>

                        <>
                            <Row gutter={16}>
                                <Col span={12}><Form.Item name="spDomain"><InputField placeholder="SurePrep Domain Name" /></Form.Item></Col>
                                <Col span={12}><Form.Item name={["surePrep", "username"]}><InputField placeholder={institution?.spDomain ? "SP Username Already Set" : "SurePrep Username"} /></Form.Item></Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}><Form.Item name={["surePrep", "password"]}><InputField placeholder={institution?.spDomain ? "SP Password Already Set" : "SurePrep Password"} /></Form.Item></Col>
                                <Col span={12}><Form.Item name={["surePrep", "apiKey"]}><InputField placeholder={institution?.spDomain ? "SP API Key Already Set" : "SurePrep API Key"} /></Form.Item></Col>
                            </Row>
                        </>
                        <div className="flex-center row-r">
                            <Button type="primary" htmlType="submit">
                                {institution ? 'Save' : 'Create'}
                            </Button>
                            {!institution && (
                                <Button
                                    type="ghost"
                                    className="mx-2"
                                    loading={updating}
                                    onClick={() => { setAdd(false); }}
                                >
                                    Cancel
                                </Button>
                            )}
                        </div>
                    </Form>
                </>
            </Modal>
            <div className="flex-center-between ma-2">
                <h2>
                    Institutions
                </h2>
                <Button
                    type="primary"
                    onClick={() => setAdd(true)}
                >
                    Add
                </Button>
            </div>
            <Table
                columns={[
                    {
                        title: 'Name',
                        dataIndex: 'name',
                    },
                    {
                        title: 'Domain',
                        dataIndex: 'fqdn',
                    },
                    {
                        title: 'Default Admin',
                        dataIndex: 'defaultAdmin',
                    },
                    {
                        title: 'Enabled',
                        dataIndex: 'enabled',
                        render: (enabled) => <Switch checked={enabled} disabled />,
                    },
                    {
                        title: 'Edit',
                        dataIndex: 'id',
                        render: (id: string, inst: any) => <Button type="default" onClick={() => { setInstitution(inst); setAdd(true); }}><Icon name="EditOutlined" /></Button>,
                    },
                ]}
                rowKey={(id) => id.id}
                dataSource={institutions}
                loading={loading}
            />
        </div>
    );
};

export default Institutions;
