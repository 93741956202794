import { Button, Col, Form, Modal, Row, Tooltip } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { INSIGHT_CODES, InsightCategory } from "../../../utils";
import { RootState } from "../../../configureStore";
import InsightFilter from "../../molecules/InsightFilter";
import Icon from "../../atoms/Icon";
import colors from "../../../utils/colors";
import { Filter, getInsightFilters, getInsights, updateInsightFilters } from "../../../redux/reducer/insight";

interface CategoryFilter {
    [category: string]: Filter[]
}

const Filters = () => {
    const [open, setOpen] = useState<boolean>(false);
    const dispatch = useDispatch<any>();
    const [opened, setOpened] = useState<{
        [key: string]: boolean;
    }>({
        Income: false,
        Investment: false,
        Property: false,
        Other: false,
    });

    useEffect(() => {
        dispatch(getInsightFilters());
    }, []);

    const filters = useSelector<RootState, Filter[]>(({ insight }) => insight.filters);

    const arrays = useMemo<CategoryFilter>(() => {
        const obj: CategoryFilter = {
            Income: [],
            Investment: [],
            Property: [],
            Other: [],
        };

        const activeFilters: { [key: string]: Filter } = filters.reduce((acc: { [key: string]: Filter }, curr: Filter) => ({
            ...acc,
            [curr.code]: curr,
        }), {});

        Object.keys(INSIGHT_CODES).forEach((code: string) => {
            if (activeFilters[code]) {
                obj[INSIGHT_CODES[code].category].push(activeFilters[code]);
            } else {
                obj[INSIGHT_CODES[code].category].push({
                    active: true,
                    code,
                    name: INSIGHT_CODES[code].name,
                    category: INSIGHT_CODES[code].category,
                });
            }
        });

        Object.keys(obj).forEach((key) => {
            obj[key] = obj[key].sort((a, b) => (a.name > b.name ? 1 : -1));
        });
        return obj;
    }, [filters]);

    const onFinish = (form: {
        [category: string]: { [name: string]: { name: string; active: boolean; threshold: string; code: string; } }
    }) => {
        const toUpdate: Filter[] = [];
        Object.keys(form).forEach((key: string) => {
            const newFilters: Filter[] = Object.values(form[key]).map((obj) => {
                const parsed = Number(obj.threshold.replace(/,/g, ''));
                return {
                    name: obj.name,
                    code: obj.code,
                    category: key as InsightCategory,
                    active: obj.active,
                    threshold: Number.isNaN(parsed) ? 0 : parsed,
                };
            });

            toUpdate.push(...newFilters);
        });

        dispatch(updateInsightFilters(toUpdate));
    };

    useEffect(() => {
        dispatch(getInsights());
    }, [filters]);

    return (
        <>
            <Button
                type="primary"
                onClick={() => { setOpen(!open); }}
            >
                Manage Insight Filters
            </Button>
            <Modal
                open={open}
                onOk={() => { setOpen(false); }}
                onCancel={() => { setOpen(false); }}
                footer={null}
                width="40%"
                destroyOnClose
                closable={false}
            >
                <Form onFinish={onFinish}>
                    {
                        Object.keys(arrays).map((key: string) => (
                            <div className="mb-4">
                                <Row
                                    justify="space-between"
                                    align="middle"
                                    onClick={() => {
                                        setOpened({
                                            ...opened,
                                            [key]: !opened[key],
                                        });
                                    }}
                                    className="pointer mb-1 br-sm mb-4 pa-2"
                                    style={{
                                        border: '1px solid',
                                        borderColor: colors.lightGray,
                                    }}
                                >
                                    <Col span={23} className="pl-1">
                                        <h5 style={{ fontSize: 16 }}>
                                            {key}
                                        </h5>
                                    </Col>
                                    <Col span={1} className="pr-1" style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Icon name="DownOutlined" />
                                    </Col>
                                </Row>
                                <div style={{ display: opened[key] ? undefined : 'none' }}>
                                    <Row gutter={16}>
                                        <Col span={8}><h2>Insight</h2></Col>
                                        <Col span={6}><h2>Enabled</h2></Col>
                                        <Col span={10} className="flex-center-start">
                                            <h2 className="mr-2">Threshold</h2>
                                            <Tooltip
                                                trigger={['hover', 'focus', "click"]}
                                                // eslint-disable-next-line react/no-unstable-nested-components
                                                title="Insights less than this amount will be hidden"
                                                overlayInnerStyle={{ textAlign: 'center' }}
                                            >
                                                <Icon
                                                    name="InfoCircleOutlined"
                                                    className="ml-1"
                                                />
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                    {arrays[key].map((insight) => (
                                        <InsightFilter key={insight.code} insight={insight} category={key} />
                                    ))}
                                </div>
                            </div>
                        ))
                    }
                    <Row gutter={16} justify="end">
                        <Button onClick={() => { setOpen(false); }}>
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="mx-2"
                            onClick={() => { setOpen(false); }}
                        >
                            Save
                        </Button>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default Filters;
