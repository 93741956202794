import { useNavigate } from "react-router-dom";
import Icon from "../atoms/Icon";

interface HeaderBackParams {
    title?: string;
}

const HeaderBack = ({
    title,
}: HeaderBackParams) => {
    const navigate = useNavigate();
    return (
        <div className="flex-center">
            <Icon
                className="mr-1"
                name="ArrowLeftOutlined"
                onClick={() => { navigate(-1); }}
            />
            {title && <h2>{title}</h2>}
        </div>
    );
};

export default HeaderBack;
