import { Button, Card, Divider } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../configureStore";
import CardHeader from "../../molecules/CardHeader";
import { Performance, getPerformers } from "../../../redux/reducer/employees";
import Performer from "../../molecules/Performer";
import LoadOverlay from "../../atoms/LoadOverlay";
import colors from "../../../utils/colors";
import NoYet from "../../atoms/NoYet";

const Performers = () => {
    const location = useParams();
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();
    const [top, setTop] = useState(true);
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['employees/GET_PERFORMERS']);

    useEffect(() => {
        dispatch(getPerformers({}, location?.id));
    }, []);

    const topPerformers = useSelector<RootState, Performance[]>(({ employees: { performance: { top: tp } } }) => tp);
    const underPerformers = useSelector<RootState, Performance[]>(({ employees: { performance: { under } } }) => under);

    const performers = useMemo(() => (top ? topPerformers : underPerformers), [
        top,
        topPerformers,
        underPerformers,
    ]);

    return (
        <Card
            style={{
                height: 320,
            }}
        >
            <CardHeader
                title="Performers"
                subtitle={(
                    <div className="flex-center">
                        <h4
                            className={`selectText ${top ? 'activeText' : ''}`}
                            onClick={() => { setTop(true); }}
                        >
                            Top performers
                        </h4>
                        <Divider type="vertical" />
                        <h4
                            className={`selectText ${!top ? 'activeText' : ''}`}
                            onClick={() => { setTop(false); }}
                        >
                            Underperformers
                        </h4>
                    </div>
                )}
                icon="FundFilled"
            />
            <Divider style={{ marginBottom: 0 }} />
            {
                loading
                    ? (<LoadOverlay height={240} width="95%" bgColor={colors.white} />)
                    : !performers.length
                        ? (<NoYet height={240} title="insights" />)
                        : (
                            <div className="col flex-center-between w100" style={{ height: 240 }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-around',
                                        height: 180,
                                        width: '100%',
                                    }}
                                >

                                    {performers.map((perf) => <Performer key={perf.advisorId} performance={perf} />)}
                                </div>
                                <Button
                                    type="default"
                                    className="w100 mb-4"
                                    style={{ justifySelf: 'flex-end' }}
                                    onClick={() => navigate('/employees')}
                                >
                                    View All
                                </Button>
                            </div>
                        )
            }
        </Card>
    );
};

export default Performers;
