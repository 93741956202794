/* eslint-disable default-param-last */
import request, { del, get, patch, post } from "../http/request";
import execute from "../http/execute";
import success from "../http/success";
import failure from "../http/failure";
import sandbox from "../../sandbox";

export const GET_INSTITUTIONS_REQUEST = 'administration/GET_INSTITUTIONS_REQUEST';
export const GET_INSTITUTIONS_SUCCESS = 'administration/GET_INSTITUTIONS_SUCCESS';
export const GET_INSTITUTIONS_FAILURE = 'administration/GET_INSTITUTIONS_FAILURE';

export const CREATE_INSTITUTION_REQUEST = 'administration/CREATE_INSTITUTION_REQUEST';
export const CREATE_INSTITUTION_SUCCESS = 'administration/CREATE_INSTITUTION_SUCCESS';
export const CREATE_INSTITUTION_FAILURE = 'administration/CREATE_INSTITUTION_FAILURE';

export const UPDATE_INSTITUTION_REQUEST = 'administration/UPDATE_INSTITUTION_REQUEST';
export const UPDATE_INSTITUTION_SUCCESS = 'administration/UPDATE_INSTITUTION_SUCCESS';
export const UPDATE_INSTITUTION_FAILURE = 'administration/UPDATE_INSTITUTION_FAILURE';

export const GET_TENANT_REQUEST = 'administration/GET_TENANT_REQUEST';
export const GET_TENANT_SUCCESS = 'administration/GET_TENANT_SUCCESS';
export const GET_TENANT_FAILURE = 'administration/GET_TENANT_FAILURE';

export const ADD_GROUP_REQUEST = 'administration/ADD_GROUP_REQUEST';
export const ADD_GROUP_SUCCESS = 'administration/ADD_GROUP_SUCCESS';
export const ADD_GROUP_FAILURE = 'administration/ADD_GROUP_FAILURE';

export const DELETE_GROUP_REQUEST = 'administration/DELETE_GROUP_REQUEST';
export const DELETE_GROUP_SUCCESS = 'administration/DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILURE = 'administration/DELETE_GROUP_FAILURE';

export const ADD_CONNECTOR_REQUEST = 'administration/ADD_CONNECTOR_REQUEST';
export const ADD_CONNECTOR_SUCCESS = 'administration/ADD_CONNECTOR_SUCCESS';
export const ADD_CONNECTOR_FAILURE = 'administration/ADD_CONNECTOR_FAILURE';

export const DELETE_CONNECTOR_REQUEST = 'administration/DELETE_CONNECTOR_REQUEST';
export const DELETE_CONNECTOR_SUCCESS = 'administration/DELETE_CONNECTOR_SUCCESS';
export const DELETE_CONNECTOR_FAILURE = 'administration/DELETE_CONNECTOR_FAILURE';

export const UPDATE_EMAIL_REQUEST = 'administration/UPDATE_EMAIL_REQUEST';
export const UPDATE_EMAIL_SUCCESS = 'administration/UPDATE_EMAIL_SUCCESS';
export const UPDATE_EMAIL_FAILURE = 'administration/UPDATE_EMAIL_FAILURE';

export const SET_VIEW_AS = 'administration/SET_VIEW_AS';

interface Role {
    id: string;
    role: string;
    name: string;
}

export interface Connector {
    id: string;
    name: string;
}

export interface Institution {
    id: string;
    createdAt: Date | string;
    updatedAt: Date | string;
    fqdn: string;
    name: string;
    enabled: boolean;
    defaultAdmin: string;
    modifiedBy: string;
    parentId: string;
    level: 0 | 1 | 2 | 3;
    userRoles: {
        id: string;
        name: string;
        role: string;
    }[];
    connectors: { id: string; name: string; }[];
    tenants: string[];
    spDomain: string;
}

const initialState: {
    viewAs?: string;
    institutions: Institution[];
    tenant: {
        name: string;
        userRoles: Role[];
        connectors: Connector[];
        inviteEmail?: string;
    };
    roleNames: string[];
} = {
    institutions: [],
    tenant: {
        name: '',
        userRoles: [],
        connectors: [],
    },
    roleNames: [],
};

function reducer(state = initialState, action: any) {
    switch (action.type) {
        case GET_TENANT_SUCCESS:
            return {
                ...state,
                tenant: action.data.tenant,
                roleNames: action.data.roleNames,
            };

        case ADD_GROUP_SUCCESS:
            return {
                ...state,
                tenant: action.data,
            };

        case GET_INSTITUTIONS_SUCCESS:
            return {
                ...state,
                institutions: action.data,
            };

        case UPDATE_INSTITUTION_SUCCESS:
            // eslint-disable-next-line no-case-declarations
            const newInsts = [...state.institutions];
            // eslint-disable-next-line no-case-declarations
            const institutionIdx = state.institutions.findIndex(({ id }) => id === action.data.id);
            if (institutionIdx > -1) {
                newInsts[institutionIdx] = action.data;
            }
            return {
                ...state,
                institutions: newInsts,
            };

        case CREATE_INSTITUTION_SUCCESS:
            return {
                ...state,
                institutions: [action.data, ...state.institutions],
            };

        case SET_VIEW_AS:
            sandbox.set('viewAs', action.payload.id);
            return {
                ...state,
                viewAs: action.payload.id,
            };

        case ADD_CONNECTOR_SUCCESS:
        case DELETE_CONNECTOR_SUCCESS:
            return {
                ...state,
                tenant: action.data,
            };

        default:
            return state;
    }
}

export const getTenant = (payload?: any) => (dispatch: any) => {
    execute(get, '/tenant')
        .then(success(dispatch, GET_TENANT_SUCCESS, payload))
        .catch(failure(dispatch, GET_TENANT_FAILURE, payload));

    dispatch({
        type: GET_TENANT_REQUEST,
        payload,
    });
};

export const getInstitutions = (payload?: any) => (dispatch: any) => {
    execute(get, '/institution')
        .then(success(dispatch, GET_INSTITUTIONS_SUCCESS, payload))
        .catch(failure(dispatch, GET_INSTITUTIONS_FAILURE, payload));

    dispatch({
        type: GET_INSTITUTIONS_REQUEST,
        payload,
    });
};

export const createInstitution = (payload: any) => (dispatch: any) => {
    execute(post, '/institution', payload)
        .then(success(dispatch, CREATE_INSTITUTION_SUCCESS, payload))
        .catch(failure(dispatch, CREATE_INSTITUTION_FAILURE, payload));

    dispatch({
        type: CREATE_INSTITUTION_REQUEST,
        payload,
    });
};

export const updateInstitution = (payload: any) => (dispatch: any) => {
    execute(patch, '/institution', payload)
        .then(success(dispatch, UPDATE_INSTITUTION_SUCCESS, payload))
        .catch(failure(dispatch, UPDATE_INSTITUTION_FAILURE, payload));

    dispatch({
        type: UPDATE_INSTITUTION_REQUEST,
        payload,
    });
};

export const addGroup = (payload?: any) => (dispatch: any) => {
    execute(post, '/tenant/group', payload)
        .then(success(dispatch, ADD_GROUP_SUCCESS, payload))
        .catch(failure(dispatch, ADD_GROUP_FAILURE, payload));

    dispatch({
        type: ADD_GROUP_REQUEST,
        payload,
    });
};

export const delGroup = (payload: any) => (dispatch: any) => {
    execute(del, `/tenant/group?id=${payload.id}`, payload)
        .then(success(dispatch, DELETE_GROUP_SUCCESS, payload))
        .catch(failure(dispatch, DELETE_GROUP_FAILURE, payload));

    dispatch({
        type: DELETE_GROUP_REQUEST,
        payload,
    });
};

export const addConnector = (payload: any) => (dispatch: any) => {
    execute(post, `/tenant/connector`, payload)
        .then(success(dispatch, ADD_CONNECTOR_SUCCESS, payload))
        .catch(failure(dispatch, ADD_CONNECTOR_FAILURE, payload));

    request(dispatch, ADD_CONNECTOR_REQUEST, payload);
};

export const updateInviteEmail = (payload: any) => (dispatch: any) => {
    execute(patch, `/institution/email`, payload)
        .then(success(dispatch, UPDATE_EMAIL_SUCCESS, payload))
        .catch(failure(dispatch, UPDATE_EMAIL_FAILURE, payload));

    request(dispatch, UPDATE_EMAIL_REQUEST, payload);
};

export const delConnector = (payload: any) => (dispatch: any) => {
    execute(del, `/tenant/connector?id=${payload.id}`, payload)
        .then(success(dispatch, DELETE_CONNECTOR_SUCCESS, payload))
        .catch(failure(dispatch, DELETE_CONNECTOR_FAILURE, payload));

    request(dispatch, DELETE_CONNECTOR_REQUEST, payload);
};

export const setViewAs = (payload: { id: string | null }) => (dispatch: any) => {
    dispatch({
        type: SET_VIEW_AS,
        payload,
    });
};

export default reducer;
