import { useMsal } from "@azure/msal-react";
import { Button } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    BrowserRouter,
    Route,
    Routes,
} from "react-router-dom";
import Administration from "../components/templates/Administration";
import Groups from "../components/templates/Groups";
import Institutions from "../components/templates/Institutions";
import Layout from "../components/templates/Layout";
import { RootState } from "../configureStore";
import { getMe, getSession, Roles } from "../redux/reducer/auth";
import Clients from "./Clients";
import Home from "./Home";
import EmployeesView from "../components/templates/EmployeesView";
import colors from "../utils/colors";
import Icon from "../components/atoms/Icon";
import InsightsView from "../components/templates/InsightsView";
import NotFound from "../components/templates/NotFound";
import EmployeeView from "../components/templates/EmployeeView";
import ClientView from "../components/templates/ClientView";
import ManagerView from "../components/templates/ManagerView";
import Settings from "../components/templates/Settings";

const Router = () => {
    const { instance } = useMsal();
    const dispatch = useDispatch<any>();

    const me = useSelector<RootState, boolean>(({ auth }) => Boolean(Object.keys(auth.me).length));
    const meFailed = useSelector<RootState, boolean>(({ auth }) => auth.meFailed);
    const sessFailed = useSelector<RootState, boolean>(({ auth }) => auth.sessionFailed || auth.meFailed);
    const level = useSelector<RootState, number>(({ auth }) => auth.level);
    const roles = useSelector<RootState, Roles>(({ auth }) => auth.roles);
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['auth/GET_ME']);
    const sessionLoading = useSelector<RootState, boolean>(({ status }) => status.loading['auth/GET_SESSION']);
    const gotSession = useSelector<RootState, boolean>(({ auth }) => auth.level !== 3);

    useEffect(() => {
        if (!Object.values(roles).some((val) => val) && !sessionLoading && !sessFailed && !gotSession) {
            dispatch(getSession());
        }
        if (!me && !loading && !meFailed) dispatch(getMe());
    }, [roles, loading]);
    return (me && !loading) ? (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    {(roles.isManager || roles.isAdvisor) && (
                        <Route path="/clients">
                            <Route index element={<Clients />} />
                            <Route path="/clients/:clientId" element={<ClientView />} />
                        </Route>
                    )}
                    {(roles.isAdmin || roles.isManager) && (
                        <Route path="/employees">
                            <Route index element={<EmployeesView />} />
                            <Route path="/employees/:id" element={<EmployeeView />} />
                        </Route>
                    )}
                    {roles.isAdmin && (
                        <Route path="/manager">
                            <Route index element={<NotFound />} />
                            <Route path="/manager/:id" element={<ManagerView />} />
                            <Route path="/manager/:id/insights" element={<InsightsView />} />
                            <Route path="/manager/:advisorId/client/:clientId" element={<ClientView />} />
                        </Route>
                    )}
                    {(roles.isAdmin || roles.isManager || roles.isAdvisor) && (
                        <Route path="/insights" element={<InsightsView />} />
                    )}
                    {(roles.isAdmin || roles.isDefaultAdmin) && (
                        <Route path="/administration">
                            <Route index element={<Administration />} />
                            <Route path="/administration/groups" element={<Groups />} />
                            {level === 0 && <Route path="/administration/institutions" element={<Institutions />} />}
                        </Route>
                    )}
                    <Route path="/settings" element={<Settings />} />
                </Route>
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    ) : (loading) ? (
        <div
            className="flex-center-center"
            style={{
                height: '100vh',
            }}
        >
            <Icon
                name="LoadingOutlined"
                color={colors.lightBlue}
                size={64}
            />
        </div>
    ) : (
        <div
            style={{
                width: '100vw',
                height: '100vh',
                textAlign: 'center',
            }}
            className="flex-center-center col"
        >
            <h2 className="mb-4">
                You do not have permission to access Halcyon TaxPrep.
            </h2>
            <h3 className="mb-4">
                If your organization uses Halcyon TaxPrep please contact your administrator.<br />Otherwise contact <a href="mailto:sales@halcyonsw.com">sales@halcyonsw.com</a>
            </h3>
            <Button
                onClick={() => { instance.logoutRedirect(); }}
                type="primary"
            >
                Sign out
            </Button>
        </div>
    );
};

export default Router;
