// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Draggable } from 'react-smooth-dnd';
import { RootState } from '../../../configureStore';
import { Insight, updateInsight } from '../../../redux/reducer/insight';
import LoadOverlay from '../../atoms/LoadOverlay';
import InsightCard from './InsightCard';

const Board = () => {
    const dispatch = useDispatch<any>();
    const me = useSelector<RootState, Me>(({ auth }) => auth.me);
    const data = useSelector<RootState, Insight[]>(({ insight: { insights, allInsights }, clients: { allClients } }) => {
        const arr: Insight[] = [];
        insights.forEach((id: string) => {
            const insight: Insight = allInsights[id];
            if (me.id === allClients[insight.clientId].advisorId) {
                arr.push(allInsights[id]);
            }
        });
        return arr;
    });
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['insights/UPDATE_INSIGHTS']);
    const [moving, setMoving] = useState({});

    const toDoTemp: Insight[] = [];
    const inProgressTemp: Insight[] = [];
    const completedTemp: Insight[] = [];
    data.forEach((datum) => {
        switch (datum.status) {
            case 'Not started':
                toDoTemp.push(datum);
                break;
            case 'In progress':
                inProgressTemp.push(datum);
                break;
            case 'Completed':
                completedTemp.push(datum);
                break;
            default:
        }
    });

    const toDo = useSelector<RootState, Insight[]>(({ insight }) => insight.toDo);
    const inProgress = useSelector<RootState, Insight[]>(({ insight }) => insight.inProgress);
    const completed = useSelector<RootState, Insight[]>(({ insight }) => insight.completed);

    const updateStatus = (insight, status) => {
        dispatch(updateInsight({
            insight,
            status,
        }));
    };

    const onCardDrop = (columnId, dropResult) => {
        if (dropResult.removedIndex && dropResult.addedIndex) { /* empty */ } else if (dropResult.addedIndex != null) {
            updateStatus(moving, columnId === 0 ? 'Not started' : columnId === 1 ? 'In progress' : 'Completed');
        }
    };

    return (
        <>
            {loading && <LoadOverlay size={(320 * 3) + 60} />}
            <div className="flex ma-4">
                <div className="mr-4">
                    <Container
                        groupName="col"
                        onDrop={(e) => {
                            onCardDrop(0, e);
                        }}
                        getChildPayload={(index) => {
                            setMoving(toDo[index - 1]);
                        }}
                        style={{
                            minWidth: "320px",
                        }}
                    >
                        <h5>
                            To do
                        </h5>
                        {
                            toDo.length ? toDo.map((item) => (
                                <Draggable
                                    key={item.id}
                                >
                                    <InsightCard
                                        insight={item}
                                    />
                                </Draggable>
                            )) : (
                                <Draggable
                                    key="column-2"
                                >
                                    <div
                                        style={{
                                            height: "128px",
                                        }}
                                    />
                                </Draggable>
                            )
                        }
                    </Container>
                </div>
                <div style={{ marginRight: "16px" }}>
                    <Container
                        groupName="col"
                        onDrop={(e) => {
                            onCardDrop(1, e);
                        }}
                        getChildPayload={(index) => {
                            setMoving(inProgress[index - 1]);
                        }}
                        style={{
                            minWidth: "320px",
                        }}
                    >
                        <h5>
                            In progress
                        </h5>
                        {
                            inProgress.length ? inProgress.map((item) => (
                                <Draggable
                                    key={item.id}
                                >
                                    <InsightCard
                                        insight={item}
                                    />
                                </Draggable>
                            )) : (
                                <Draggable
                                    key="column-2"
                                >
                                    <div
                                        style={{
                                            height: "128px",
                                        }}
                                    />
                                </Draggable>
                            )
                        }
                    </Container>
                </div>
                <div style={{ marginRight: "16px" }}>
                    <Container
                        groupName="col"
                        onDrop={(e) => {
                            onCardDrop(2, e);
                        }}
                        getChildPayload={(index) => {
                            setMoving(completed[index - 1]);
                        }}
                        style={{
                            minWidth: "320px",
                        }}
                    >
                        <h5>
                            Completed
                        </h5>
                        {
                            completed.length ? completed.map((item) => (
                                <Draggable
                                    key={item.id}
                                >
                                    <InsightCard
                                        insight={item}
                                    />
                                </Draggable>
                            )) : (
                                <Draggable
                                    key="column-2"
                                >
                                    <div
                                        style={{
                                            height: "128px",
                                        }}
                                    />
                                </Draggable>
                            )
                        }
                    </Container>
                </div>
            </div>
        </>
    );
};

export default Board;
