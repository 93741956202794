import Icon from "./Icon";

interface NoYetParams {
    title: string;
    icon?: string;
    height?: string | number;
}

const NoYet = ({
    title,
    icon = 'StopOutlined',
    height = undefined,
}: NoYetParams) => (
    <div
        className="flex-center-center col"
        style={{
            height,
        }}
    >
        <Icon
            name={icon}
            size={48}
            className="mb-4"
        />
        <h2>
            No {title} yet!
        </h2>
    </div>
);

export default NoYet;
