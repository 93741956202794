import { InteractionType } from '@azure/msal-browser';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { RootState } from './configureStore';
import { getSession } from './redux/reducer/auth';

import Router from './routes/Router';
import { ToastNotification } from './redux/reducer/notification';

const App = () => {
    const dispatch = useDispatch<any>();
    const request = {
        scopes: [`api://${process.env.REACT_APP_TOKEN_AUDIENCE}/api`, "GroupMember.Read.All", "Calendars.ReadWrite.Shared"],
    };
    const { result } = useMsalAuthentication(InteractionType.Redirect, request);
    const session = useSelector<RootState, boolean>(({ auth }) => auth.level < 3);
    const sessFailed = useSelector<RootState, boolean>(({ auth }) => auth.sessionFailed || auth.meFailed);
    const notif = useSelector<RootState, ToastNotification>(({ notification: ntf }) => ntf);

    useEffect(() => {
        if (result) {
            const { accessToken, idToken } = result;
            localStorage.setItem('ACCESS_TOKEN', accessToken);
            localStorage.setItem('ID_TOKEN', idToken);
            try {
                localStorage.setItem('ACCESS_EXPIRY', (JSON.parse(Buffer.from(accessToken.split('.')[1], 'base64').toString('ascii')).exp * 1000).toString());
            } catch (err) {
                console.error(err);
            }
            if (!session && !sessFailed) dispatch(getSession());
        }
    }, [result]);

    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        if (notif.level) {
            api[notif.level]({
                message: notif.title,
                description: notif.message,
                placement: 'topRight',
            });
        }
    }, [notif, api]);

    return (
        <>
            <AuthenticatedTemplate>
                <div className="background">
                    {contextHolder}
                    <Router />
                </div>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate />
        </>
    );
};

export default App;
