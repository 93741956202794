import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';

import { useState } from "react";
import { Modal } from "antd";
import { RootState } from "../../configureStore";
import { Employee, removeEmployee } from "../../redux/reducer/employees";
import Profile from "../molecules/Profile";
import Icon from "../atoms/Icon";
import AddEditUserModal from "../organisms/AddEditUserModal";
import Clients from "../../routes/Clients";

const EmployeeView = () => {
    const id = useParams();
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();
    const employee = useSelector<RootState, Employee>(({ employees: { allEmployees } }) => allEmployees[id?.id ?? '']);

    const [editing, setEditing] = useState<boolean>(false);
    const [confirm, setConfirm] = useState(false);

    const deleteUser = () => {
        if (employee) {
            dispatch(removeEmployee({ id: id?.id ?? '' }));
        }
        setConfirm(false);
    };

    if (!employee) navigate(-1);
    return (
        <div>
            <h1 className="mb-2" style={{ textTransform: 'capitalize' }}>
                {employee.tlr}
            </h1>
            <div className="flex-center-between pa-4 white br-md">
                <Profile
                    firstName={employee.firstName}
                    lastName={employee.lastName}
                    image={employee.imageUrl}
                    size={42}
                />
                <Icon
                    name="EditOutlined"
                    size={24}
                    onClick={() => setEditing(true)}
                />
            </div>
            <AddEditUserModal
                user={employee}
                open={editing}
                setConfirm={setConfirm}
                setOpen={setEditing}
            />
            <Modal
                open={confirm}
                onCancel={() => { setConfirm(false); }}
                onOk={deleteUser}
                okText="Yes"
                cancelText="No, keep user"
                zIndex={1000}
            >
                Deleting this user cannot be undone. Continue and delete user?
            </Modal>
            <Clients
                advisorId={employee.id}
            />
        </div>
    );
};

export default EmployeeView;
