import { Input, InputProps } from "antd";
import colors from "../../utils/colors";

const InputField = (props: InputProps) => (
    <Input
        className="background input-field"
        color={colors.white}
        prefix={props.required && <p className="danger--text" style={{ fontSize: props.size }}>*</p>}
        placeholder={props.placeholder}
        size={props.size ?? "large"}
        {...props}
    />
);

export default InputField;
