import colors from "../../utils/colors";
import Icon from "../atoms/Icon";

interface CardHeaderParams {
    title: string;
    subtitle?: string | JSX.Element;
    icon?: string;
    onFilter?: () => void;
}

const CardHeader = ({
    title,
    subtitle,
    icon,
    onFilter,
}: CardHeaderParams) => (
    <div className="flex-center-between" style={{ width: '100%' }}>
        <div className="flex-center">
            {
                    icon && (
                        <Icon
                            className="mr-4"
                            name={icon}
                            size={24}
                            color={colors.darkGreen}
                            backgroundColor={colors.lightGreen}
                            padding={4}
                            borderRadius={4}
                        />
                    )
                }
            <div>
                <h3>
                    {title}
                </h3>
                {
                        typeof subtitle === 'string' ? (
                            <h4>
                                {subtitle}
                            </h4>
                        ) : subtitle || <div />
                    }
            </div>
        </div>
        {
                Boolean(onFilter) && (
                    <Icon
                        size={24}
                        name="SlidersOutlined"
                        borderRadius={8}
                        className="border"
                        padding={6}
                        onClick={onFilter}
                    />
                )
            }
    </div>
    );

export default CardHeader;
