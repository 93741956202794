import { useState } from "react";
import { Modal } from "antd";
import { useSelector } from "react-redux";
import colors from "../../utils/colors";
import Icon from "../atoms/Icon";
import Employees from "../organisms/Employees";
import AddEditUser from "../organisms/AddEditUser";
import { RootState } from "../../configureStore";

const EmployeesView = () => {
    const [add, setAdd] = useState(false);
    const isAdmin = useSelector<RootState, boolean>(({ auth: { roles } }) => roles.isAdmin);

    return (
        <>
            <Modal
                open={add}
                // onOk={() => { setOpen(false); }}
                onCancel={() => { setAdd(false); }}
                destroyOnClose
                footer={null}
                closable={false}
                width="70%"
                zIndex={800}
            >
                <AddEditUser setOpen={setAdd} allowAssign={isAdmin} />
            </Modal>
            <div className="flex-center-end mb-2">
                <Icon
                    name="PlusSquareOutlined"
                    size="32px"
                    className="mr-2"
                    color={colors.lightBlue}
                    onClick={() => setAdd(true)}
                />
            </div>
            <Employees />
        </>
    );
};

export default EmployeesView;
