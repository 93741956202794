import { Tooltip } from "antd";
import { formatUSD } from "../../utils";

interface InsightInstitutionsParams {
    parameters: {
        amount: string | number;
        institution: string | {
            fieldName: string;
            fieldValue: string;
        };
    }[];
    institutionName: string;
}

const InsightInstitutions = ({ institutionName, parameters }: InsightInstitutionsParams) => (
    <Tooltip
        trigger={['hover', 'focus', "click"]}
        overlayStyle={{ opacity: 1 }}
        // eslint-disable-next-line react/no-unstable-nested-components
        title={() => (
            <div>
                {
                    parameters.map(({ amount, institution }, idx) => (institution ? (
                        (typeof institution === 'string') ? (
                            <div key={`${idx}-${institution}`} style={{ textTransform: "capitalize" }}>
                                {institution?.toLowerCase()}: {formatUSD(typeof amount === 'string' ? Number(amount) : amount)}
                            </div>
                        ) : (
                            <div key={`${idx}-${institution.fieldValue}`} style={{ textTransform: "capitalize" }}>
                                {institution?.fieldValue?.toLowerCase()}: {formatUSD(typeof amount === 'string' ? Number(amount) : amount)}
                            </div>
                        )
                    ) : <div key={idx} />
                    ))
                }
            </div>
        )}
    >
        {institutionName}
    </Tooltip>
);

export default InsightInstitutions;
