import { Tag } from "antd";
import colors from "../../utils/colors";

interface PriorityTagParams {
    priority: string;
}

const PriorityTag = ({
    priority,
}: PriorityTagParams) => (
    <Tag
        className="flex-center-center br-md"
        style={{
            border: 'none',
            color: priority === 'High' ? colors.danger : priority === "Medium" ? colors.darkBlue : colors.success,
            backgroundColor: priority === 'High' ? 'rgba(240, 83, 121, 0.06)' : priority === 'Medium' ? 'rgba(5, 95, 252, 0.06)' : 'rgba(42, 100, 42, 0.06)',
        }}
    >
        {priority}
    </Tag>
);

export default PriorityTag;
