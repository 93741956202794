/* eslint-disable max-len */
import { Col, Divider, Row, Tooltip } from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "../../../configureStore";
import { Client } from "../../../redux/reducer/clients";
import { Insight } from "../../../redux/reducer/insight";
import colors from "../../../utils/colors";
import Icon from "../../atoms/Icon";

interface DetailsParams {
    insightId: string;
}

const Details = ({
    insightId,
}: DetailsParams) => {
    const location = useLocation();
    const isClientPage = location.pathname.startsWith('/clients');
    const insight = useSelector<RootState, Insight>(({ insight: { allInsights } }) => allInsights[insightId] ?? {});
    const client = useSelector<RootState, Client>(({ clients }) => clients.allClients[insight.clientId] ?? {});

    return (
        <Row>
            {!isClientPage && (
                <Col span={4}>
                    <Row
                        align="middle"
                        className="mt-2 mb-2"
                    >
                        <Col span={4}>
                            <Icon
                                name="UserOutlined"
                            />
                        </Col>
                        <Col span={18}>
                            <h5 className="m0">
                                Age
                            </h5>
                            <p className="m0">
                                {moment().diff(moment(client.dob), 'years')}
                            </p>
                        </Col>
                    </Row>
                    <Row
                        align="middle"
                        className="mt-2 mb-2"
                    >
                        <Col span={4}>
                            <Icon
                                name="CompassOutlined"
                            />
                        </Col>
                        <Col span={18}>
                            <h5>
                                Address
                            </h5>
                            <p className="m0">
                                {client.address.addressLineOne}
                            </p>
                            {client.address.addressLineTwo && (
                                <p className="m0">
                                    {client.address.addressLineTwo}
                                </p>
                            )}
                            {(client.address.city && client.address.state && client.address.zipCode) && (
                                <p>
                                    {client.address.city}, {client.address.state} {client.address.zipCode}
                                </p>
                            )}
                        </Col>
                    </Row>
                    <Row
                        align="middle"
                        className="mt-2 mb-2"
                    >
                        <Col span={4}>
                            <Icon
                                name="PhoneOutlined"
                            />
                        </Col>
                        <Col span={18}>
                            <h5>
                                Mobile
                            </h5>
                            <p>
                                {client.phone}
                            </p>
                        </Col>
                    </Row>
                </Col>
            )}
            {!isClientPage && (
                <Col span={1}>
                    <Divider
                        type="vertical"
                        orientation="center"
                        style={{
                            height: '100%',
                        }}
                    />
                </Col>
            )}
            <Col
                className="flex-center col"
                span={isClientPage ? 8 : 6}
            >
                <h5>
                    Event
                </h5>
                <Icon
                    name="DollarCircleFilled"
                    size="55px"
                    color={colors.mediumBlue}
                    className="my-2"
                />
                <h4>
                    {insight.insight}
                </h4>
            </Col>
            <Col
                className="mb-2 flex-center col"
                span={isClientPage ? 8 : 6}
            >
                <h5>
                    Insight
                </h5>
                <Icon
                    name="BulbFilled"
                    color="white"
                    backgroundColor={colors.mediumBlue}
                    size="35px"
                    padding={10}
                    className="my-2"
                />
                <h4>
                    {insight.description}
                </h4>
            </Col>
            <Col
                className="flex-center col"
                span={isClientPage ? 8 : 6}
            >
                <h5>
                    Propensities
                </h5>
                <Tooltip
                    trigger={['hover', 'focus', "click"]}
                    placement="left"
                    overlayStyle={{ opacity: 0.8 }}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    title={() => (
                        <div className="pa-2">
                            {insight.propensities.map(({ likelihood, propType }) => (
                                <p className="white--text">
                                    {propType} - {(likelihood * 100).toFixed(0)}%
                                </p>
                            ))}
                        </div>
                    )}
                >
                    <Icon
                        name="RocketFilled"
                        color="white"
                        backgroundColor={colors.mediumBlue}
                        size="35px"
                        padding={10}
                        className="my-2"
                    />
                </Tooltip>
                <h4>
                    {insight.propensities.map(({ likelihood, propType }, idx) => `${propType} - ${(likelihood * 100).toFixed(0)}%${idx === insight.propensities.length - 1 ? '' : ', '}`)}
                </h4>
            </Col>
        </Row>
    );
};

export default Details;
