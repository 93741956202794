/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Buffer } from 'buffer';
// @ts-ignore
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useRef } from 'react';
import { Button, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../configureStore';
import { updateInviteEmail } from '../../redux/reducer/administration';
import { toastNotification } from '../../redux/reducer/notification';

const endpoint = process.env.REACT_APP_ENDPOINT || 'http://localhost:7071/api';

const WYSIWYG = ({
}) => {
    const tenantName = useSelector<RootState, string>(({ administration }) => administration.tenant.name);
    const inviteEmail = useSelector<RootState, string>(({ administration }) => Buffer.from(administration.tenant.inviteEmail ?? '', 'base64').toString('ascii'));
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading["administration/UPDATE_EMAIL"]);
    const editorRef = useRef<Editor>();

    const dispatch = useDispatch<any>();
    const saveEmail = (customEmail: string) => {
        const missEmail = !customEmail.includes("{{email}}");
        const missInv = !customEmail.includes("{{invitationCode}}");
        if (missEmail || missInv) {
            dispatch(toastNotification({ message: `Missing required variables.${missEmail ? ' {{email}}' : ''}${missInv ? ' {{invitationCode}}' : ''}` }));
            return;
        }
        const b64 = Buffer.from(customEmail, 'ascii').toString('base64');

        dispatch(updateInviteEmail({ text: b64 }));
    };

    return (
        <div className="my-8">
            <h2 className="my-3">Custom client invite email</h2>
            The following variables are included in your email and will be replaced with their corresponding value:
            <ul>
                <li><h5><span className="danger--text">*</span>{`{{email}}`} - Client&apos;s Email Address</h5></li>
                <li><h5><span className="danger--text">*</span>{`{{invitationCode}}`} - Invitation for the Client to use on Sign Up</h5></li>
                <li><h5>{`{{name}}`} - Auto Generated Password for the Client</h5></li>
                <li><h5>{`{{institutionName}}`} - {tenantName}</h5></li>
            </ul>
            <CKEditor
                editor={Editor}
                config={{
                    simpleUpload: {
                        uploadUrl: `${endpoint}/institution/email/image`,
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
                            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_SUB_KEY ?? '',
                        },
                    },
                }}
                data={inviteEmail}
                onReady={(editor) => {
                    editorRef.current = editor;
                }}
            />
            <Row
                className="row-r mt-1"
            >
                <Button
                    type="primary"
                    loading={loading}
                    onClick={() => {
                        saveEmail(editorRef.current.getData());
                    }}
                >
                    Save
                </Button>
            </Row>
        </div>
    );
};

export default WYSIWYG;
