import { useMsal } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";

import "../../App.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import colors from "../../utils/colors";
import Icon from "../atoms/Icon";
import { RootState } from "../../configureStore";
import { Roles } from "../../redux/reducer/auth";

const routeItems = [
    {
        display: 'Home',
        pathname: '/',
        icon: "HomeOutlined",
        activeIcon: "HomeFilled",
        isDefaultAdmin: false,
        isAdmin: true,
        isManager: true,
        isAdvisor: true,
    },
    {
        display: 'Clients',
        pathname: '/clients',
        icon: "UserOutlined",
        isDefaultAdmin: false,
        isAdmin: false,
        isManager: true,
        isAdvisor: true,
    },
    {
        display: 'Insights',
        pathname: '/insights',
        icon: "BulbOutlined",
        activeIcon: "BulbFilled",
        isDefaultAdmin: false,
        isAdmin: true,
        isManager: true,
        isAdvisor: false,
    },
    {
        display: 'Employees',
        pathname: '/employees',
        icon: "TeamOutlined",
        isDefaultAdmin: false,
        isAdmin: true,
        isManager: true,
        isAdvisor: false,
    },
    {
        display: 'Administration',
        pathname: '/administration',
        icon: "SettingOutlined",
        activeIcon: "SettingFilled",
        isDefaultAdmin: true,
        isAdmin: false,
        isManager: false,
        isAdvisor: false,
    },
    {
        display: 'Logout',
        pathname: '/logout',
        icon: "LogoutOutlined",
        isDefaultAdmin: true,
        isAdmin: true,
        isManager: true,
        isAdvisor: true,
        onClick: (items: { instance: IPublicClientApplication }) => { items.instance.logoutRedirect(); },
    },
];

const MenuItem = ({
    route,
    active,
}: {
    route: {
        display: string;
        pathname: string;
        icon: string;
        activeIcon?: string;
        onClick?: (items: { instance: IPublicClientApplication }) => void;
    };
    active: boolean;
}) => {
    const navigation = useNavigate();
    const { instance } = useMsal();

    return (
        <div
            className="flex-center-start row mb-4 pl-4 pointer"
            style={{
                height: '60px',
                backgroundColor: active ? colors.lightGreen : undefined,
                borderTopRightRadius: '16px',
                borderBottomRightRadius: '16px',
                borderLeftColor: active ? colors.darkGreen : "transparent",
                borderLeftWidth: '8px',
                borderLeftStyle: "solid",
            }}
            onClick={() => { route.onClick ? route.onClick({ instance }) : navigation(route.pathname); }}
        >
            <div
                className="flex-center-row"
                style={{
                    justifyContent: 'start',
                }}
            >
                <Icon name={active ? route.activeIcon ?? route.icon : route.icon} color={active ? colors.darkGreen : undefined} />
                <p
                    style={{
                        color: active ? colors.darkGreen : undefined,
                        marginLeft: '8px',
                    }}
                >
                    {route.display}
                </p>
            </div>
            <div style={{ width: '32px' }} />
        </div>
    );
};

const AppMenu = () => {
    const location = useLocation();
    const { isAdmin, isAdvisor, isManager, isDefaultAdmin } = useSelector<RootState, Roles>(({ auth }) => auth.roles);

    const routes = useMemo(() => routeItems.filter((routeItem) => (routeItem.isDefaultAdmin && isDefaultAdmin)
        || (routeItem.isAdmin && isAdmin)
        || (routeItem.isManager && isManager)
        || (routeItem.isAdvisor && isAdvisor)), [
        isAdmin,
        isAdvisor,
        isManager,
        isDefaultAdmin,
    ]);

    return (
        <div className="py-4 pr-4">
            {
                routes.map((route) => (
                    <MenuItem
                        key={route.pathname}
                        route={route}
                        active={location.pathname === route.pathname}
                    />
                ))
            }
        </div>
    );
};

export default AppMenu;
