import { Card, Col, Divider, Row, Slider } from "antd";
import { VictoryPie } from "victory";
import { useSelector } from "react-redux";
import { CSSProperties, useMemo, useState } from "react";
import CardHeader from "../../molecules/CardHeader";
import colors, { chartColors } from "../../../utils/colors";
import { RootState } from "../../../configureStore";
import { Insight } from "../../../redux/reducer/insight";
import { formatShortUSD } from "../../../utils";
import LoadOverlay from "../../atoms/LoadOverlay";
import NoYet from "../../atoms/NoYet";

const trackStyle: CSSProperties = {
    backgroundColor: colors.darkBlue,
    height: 24,
    borderRadius: 16,
};

const railStyle: CSSProperties = {
    height: 24,
    borderRadius: 16,
};

const Thresholds = () => {
    const [showFilter, setShowFilters] = useState(false);
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['insights/GET_INSIGHTS']);
    const insights = useSelector<RootState, { [key: string]: Insight }>(({ insight: { allInsights } }) => allInsights);

    const categories = useMemo(() => Object.values(insights).reduce((acc: any, curr: Insight) => ({
        ...acc,
        [curr.category]: acc[curr.category] ? curr.amount > acc[curr.category] ? curr.amount : acc[curr.category] : curr.amount,
    }), {}), [insights]);

    const [filters, setFilters] = useState<any>({});

    const data = useMemo(() => {
        const amounts = Object.values(insights).reduce((acc: any, curr: Insight) => {
            if (!curr.category) return acc;
            const isNotFiltered = filters?.[curr.category] ? curr.amount > filters[curr.category] : true;

            if (isNotFiltered) {
                return {
                    ...acc,
                    [curr.category]: acc[curr.category] ? acc[curr.category] + 1 : 1,
                };
            }
            return acc;
        }, {});
        return Object.keys(amounts).map((key) => ({ x: key, y: amounts[key] }));
    }, [
        insights,
        filters,
    ]);

    const formatter = (value: number | undefined) => (value ? `${formatShortUSD(value)}` : '$0');

    return (
        <Card
            style={{
                height: 380,
            }}
        >
            <CardHeader
                title="Thresholds"
                subtitle={`${Object.values(data).reduce((acc: number, curr) => acc + curr.y, 0)} entries`}
                icon="PieChartFilled"
                onFilter={() => { setShowFilters(!showFilter); }}
            />
            <Divider style={{ marginBottom: 0 }} />
            {
                loading
                    ? (<LoadOverlay height={300} width="95%" bgColor={colors.white} />)
                    : !Object.keys(insights ?? {}).length
                        ? (<NoYet height={300} title="insights" />)
                        : (
                            <div
                                className={`card flex-center-center h300px ${showFilter ? 'rotated' : ''}`}
                            >
                                <div className="front">
                                    <VictoryPie
                                        padAngle={2}
                                        innerRadius={5}
                                        colorScale={chartColors}
                                        data={data}
                                        // eslint-disable-next-line react/no-unstable-nested-components
                                        labels={({ datum }) => [`${datum.xName}:`, `${filters?.[datum.xName] ? formatShortUSD(filters?.[datum.xName]) : '$0'} - ${formatShortUSD(categories[datum.xName])}`]}
                                        events={[
                                            {
                                                target: "data",
                                                eventHandlers: {
                                                    onMouseOver: (_e) => [
                                                        {
                                                            target: "data",
                                                            mutation: () => ({
                                                                style: { fill: colors.darkGreen, width: 30 },
                                                            }),
                                                        },
                                                        {
                                                            target: "labels",
                                                            mutation: () => ({ active: true }),
                                                        },
                                                    ],
                                                    onMouseOut: () => [
                                                        {
                                                            target: "data",
                                                            mutation: () => { },
                                                        },
                                                        {
                                                            target: "labels",
                                                            mutation: () => ({ active: false }),
                                                        },
                                                    ],
                                                },
                                            },
                                        ]}
                                    />
                                </div>
                                <div className="back pa-8 flex-end-start col">
                                    <div className="w100">
                                        {Object.keys(categories).map((cat) => (
                                            <Row key={cat} className="mt-8 flex-center-between">
                                                <Col span={6}>
                                                    <h5 style={{ marginBottom: "-8px" }}>{cat}</h5>
                                                </Col>
                                                <Col span={18}>
                                                    <Slider
                                                        onChange={(value) => setFilters({
                                                            ...filters,
                                                            [cat]: value,
                                                        })}
                                                        defaultValue={0}
                                                        min={0}
                                                        step={categories[cat] > 1_000_000 ? 10000 : categories[cat] > 1_000 ? 100 : 10}
                                                        max={categories[cat]}
                                                        tooltip={{ formatter }}
                                                        trackStyle={trackStyle}
                                                        railStyle={railStyle}
                                                    />
                                                </Col>
                                            </Row>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )
            }
        </Card>
    );
};
export default Thresholds;
