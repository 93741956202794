const colors = {
    white: "#FFFFFF",
    black: "#000000",
    background: "#F0F2F5",
    danger: "#F05379",
    lightBlue: "#0099FF",
    mediumBlue: "#3366CC",
    darkBlue: "#055FFC",
    success: "#4C9A2A",
    darkGreen: "#009280",
    lightTeal: "#c8f5eb",
    lightGreen: "#F2F9F8",
    lightGray: "#D3D3D3",
    lightPurple: "#bccbfb",
    mediumPurple: "#aa9ef7",
    midYellow: "#DAA520",
};

export const chartColors = [
    colors.mediumBlue,
    colors.lightPurple,
    colors.mediumPurple,
    colors.lightTeal,
];

export default colors;
