import { Button, Card, Col, Divider, Row } from "antd";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../../../configureStore";
import CardHeader from "../../molecules/CardHeader";
import LoadOverlay from "../../atoms/LoadOverlay";
import colors from "../../../utils/colors";
import NoYet from "../../atoms/NoYet";

const Missed = ({ isAdmin = false }: { isAdmin?: boolean }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['insights/GET_INSIGHTS']);
    const missed = useSelector<RootState, any>(({ insight: { missed: missedI, allInsights } }) => missedI.reduce((acc: any, curr) => {
            const { category } = allInsights[curr];
            return {
                ...acc,
                [allInsights[curr].category]: acc[category] ? acc[category] + 1 : 1,
            };
        }, {}));

    const total = useMemo<number>(() => Object.values(missed).reduce((acc: number, curr: any) => acc + Number(curr), 0), [missed]);

    return (
        <Card
            style={{
                height: 320,
            }}
        >
            <CardHeader
                title="Missed"
                subtitle={`${total} Missed opportunities`}
                icon="InfoCircleFilled"
            />
            <Divider style={{ marginBottom: 0 }} />
            {
                loading
                    ? (<LoadOverlay height={240} width="95%" bgColor={colors.white} />)
                    : !Object.keys(missed ?? {}).length
                        ? (<NoYet height={240} title="insights" />)
                        : (
                            <div
                                style={{
                                    height: 240,
                                    width: '100%',
                                }}
                                className="col flex-center-between"
                            >
                                <div className="col justify-around" style={{ height: 180, width: '100%' }}>
                                    <Row className="flex-between">
                                        <Col span={8} className="flex-center-between"><p>Investments</p><h6>{missed.Investment ?? 0}</h6></Col>
                                        <Col span={8} className="flex-center-between"><p>Life Events</p><h6>{missed['Life Events'] ?? 0}</h6></Col>
                                    </Row>
                                    <Row className="flex-between">
                                        <Col span={8} className="flex-center-between"><p>Income</p><h6>{missed.Income ?? 0}</h6></Col>
                                        <Col span={8} className="flex-center-between"><p>Property</p><h6>{missed.Property ?? 0}</h6></Col>
                                    </Row>
                                    <Row className="flex-between">
                                        <Col span={8} className="flex-center-between"><p>General Events</p><h6>{missed['General Events'] ?? 0}</h6></Col>
                                    </Row>
                                </div>
                                <Button
                                    type="default"
                                    className="w100 mb-4"
                                    style={{ justifySelf: 'flex-end' }}
                                    onClick={() => { navigate(isAdmin ? `${location.pathname}/insights` : 'insights'); }}
                                >
                                    View All
                                </Button>
                            </div>
                        )
            }
        </Card>
    );
};

export default Missed;
