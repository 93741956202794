import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
    const navigate = useNavigate();
    return (
        <div className="w100 flex-center-center col" style={{ height: '100vh' }}>
            <h2 className="mb-4">Page not found</h2>
            <Button type="primary" onClick={() => { navigate('/'); }}>Go Home</Button>
        </div>
    );
};

export default NotFound;
