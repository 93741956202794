/* eslint-disable no-case-declarations */
/* eslint-disable default-param-last */
import { Buffer } from 'buffer';
import { get, postMulti } from "../http/request";
import execute from "../http/execute";
import success from "../http/success";
import failure from "../http/failure";

export const GET_ME_REQUEST = 'auth/GET_ME_REQUEST';
export const GET_ME_SUCCESS = 'auth/GET_ME_SUCCESS';
export const GET_ME_FAILURE = 'auth/GET_ME_FAILURE';

export const GET_SESSION_REQUEST = 'auth/GET_SESSION_REQUEST';
export const GET_SESSION_SUCCESS = 'auth/GET_SESSION_SUCCESS';
export const GET_SESSION_FAILURE = 'auth/GET_SESSION_FAILURE';

export const UPLOAD_IMAGE_SUCCESS = 'auth/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'auth/UPLOAD_IMAGE_FAILURE';
export const UPLOAD_IMAGE_REQUEST = 'auth/UPLOAD_IMAGE_REQUEST';

export interface Me {
    id: string;
    createdAt: Date;
    updatedAt: Date;
    email: string;
    phone: string;
    firstName: string;
    lastName: string;
    roles: string[];
    salesforceEmail: string;
    imageUrl?: string;
    managerId?: string;
}

export interface Roles {
    isAdmin: boolean;
    isManager: boolean;
    isDefaultAdmin: boolean;
    isAdvisor: boolean;
}

const initialState: {
    me: Me | object;
    level: number;
    roles: Roles;
    fiId?: string
    meFailed: boolean;
    sessionFailed: boolean;
    tlr?: string;
} = {
    me: {},
    level: 3,
    roles: {
        isAdmin: false,
        isManager: false,
        isDefaultAdmin: false,
        isAdvisor: false,
    },
    meFailed: false,
    sessionFailed: false,
};

function reducer(state = initialState, action: any) {
    switch (action.type) {
        case GET_ME_FAILURE:
            return {
                ...state,
                meFailed: true,
            };

        case GET_SESSION_FAILURE:
            return {
                ...state,
                sessionFailed: true,
            };

        case GET_ME_SUCCESS:
            return {
                ...state,
                me: action.data,
                meFailed: false,
            };

        case UPLOAD_IMAGE_SUCCESS:
            return {
                ...state,
                me: {
                    ...state.me,
                    imageUrl: action.data.imageUrl,
                },
            };

        case GET_SESSION_SUCCESS:
            if (window) {
                const data = JSON.parse(Buffer.from(action.data.principal, 'base64').toString('utf-8'));
                return {
                    ...state,
                    level: data.level,
                    fiId: data.did,
                    roles: {
                        isAdmin: data.roles.includes('Admin'),
                        isManager: data.roles.includes('Manager'),
                        isDefaultAdmin: data.roles.includes('DefaultAdmin'),
                        isAdvisor: data.roles.includes('Advisor'),
                    },
                    tlr: data.tlr,
                    sessionFailed: false,
                };
            }
            return state;

        default:
            return state;
    }
}

export const getMe = (payload?: any) => (dispatch: any) => {
    execute(get, '/me')
        .then(success(dispatch, GET_ME_SUCCESS, payload))
        .catch(failure(dispatch, GET_ME_FAILURE, payload));

    dispatch({
        type: GET_ME_REQUEST,
        payload,
    });
};

export const getSession = (payload?: any) => (dispatch: any) => {
    execute(get, '/session')
        .then(success(dispatch, GET_SESSION_SUCCESS, payload))
        .catch(failure(dispatch, GET_SESSION_FAILURE, payload));

    dispatch({
        type: GET_SESSION_REQUEST,
        payload,
    });
};

export const uploadImage = (payload?: any) => (dispatch: any) => {
    execute(postMulti, '/me/image', payload.data)
        .then(success(dispatch, UPLOAD_IMAGE_SUCCESS, payload))
        .catch(failure(dispatch, UPLOAD_IMAGE_FAILURE, payload));

    dispatch({
        type: UPLOAD_IMAGE_REQUEST,
        payload,
    });
};

export default reducer;
