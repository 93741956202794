/* eslint-disable no-case-declarations */
import moment, { now } from "moment";
import { get, patch, post } from "../http/request";
import execute from "../http/execute";
import success from "../http/success";
import failure from "../http/failure";
import { InsightCategory } from "../../utils";

export const CLEAR_INSIGHTS = 'insights/CLEAR_INSIGHTS';

export const GET_INSIGHTS_REQUEST = 'insights/GET_INSIGHTS_REQUEST';
export const GET_INSIGHTS_SUCCESS = 'insights/GET_INSIGHTS_SUCCESS';
export const GET_INSIGHTS_FAILURE = 'insights/GET_INSIGHTS_FAILURE';

export const UPDATE_INSIGHTS_REQUEST = 'insights/UPDATE_INSIGHTS_REQUEST';
export const UPDATE_INSIGHTS_SUCCESS = 'insights/UPDATE_INSIGHTS_SUCCESS';
export const UPDATE_INSIGHTS_FAILURE = 'insights/UPDATE_INSIGHTS_FAILURE';

export const CREATE_INSIGHT_REQUEST = 'insights/CREATE_INSIGHT_REQUEST';
export const CREATE_INSIGHT_SUCCESS = 'insights/CREATE_INSIGHT_SUCCESS';
export const CREATE_INSIGHT_FAILURE = 'insights/CREATE_INSIGHT_FAILURE';

export const GET_INSIGHTS_FILTERS_REQUEST = 'insights/GET_INSIGHTS_FILTERS_REQUEST';
export const GET_INSIGHTS_FILTERS_SUCCESS = 'insights/GET_INSIGHTS_FILTERS_SUCCESS';
export const GET_INSIGHTS_FILTERS_FAILURE = 'insights/GET_INSIGHTS_FILTERS_FAILURE';

export const UPDATE_INSIGHTS_FILTERS_REQUEST = 'insights/UPDATE_INSIGHTS_FILTERS_REQUEST';
export const UPDATE_INSIGHTS_FILTERS_SUCCESS = 'insights/UPDATE_INSIGHTS_FILTERS_SUCCESS';
export const UPDATE_INSIGHTS_FILTERS_FAILURE = 'insights/UPDATE_INSIGHTS_FILTERS_FAILURE';

export interface Filter {
    name: string;
    code: string;
    category: InsightCategory;
    active: boolean;
    threshold?: number;
}

export interface ISubtask {
    completed: boolean;
    description: string;
}

interface Task {
    description: string;
    subtasks: ISubtask[]
}

export interface Insight {
    id: string;
    code: string;
    category: string;
    fICode: string;
    irsIncomeId: string;
    employeeId: string;
    file: string;
    taxYear: string;
    loanNumber: string;
    documentId: string;
    document: string;
    form: string;
    insight: string;
    institution: string;
    amount: string;
    insightId: string;
    clientId: string;
    description: string;
    createdAt: string;
    subtasks: any[]
    task: Task;
    clientName: string;
    priority: "High" | "Medium" | "Low";
    status: 'Not started' | 'In progress' | 'Completed';
    parameters: {
        amount: string | number;
        institution: string;
    }[];
    propensities: {
        propType: string;
        likelihood: number;
    }[];
    event: string | Date;
    doBye: string | Date;
    startedAt: string | Date;
    completedAt: string | Date;
}

/* eslint-disable default-param-last */
const initialState: {
    allInsights: { [key: string]: Insight };
    insights: string[];
    toDo: Insight[];
    inProgress: Insight[];
    completed: Insight[];
    missed: string[];
    filters: Filter[]
} = {
    allInsights: {},
    insights: [],
    toDo: [],
    inProgress: [],
    completed: [],
    missed: [],
    filters: [],
};

function reducer(state = initialState, action: any) {
    const allInsights: { [key: string]: Insight } = {};
    let toDo: Insight[] = [];
    let inProgress: Insight[] = [];
    let completed: Insight[] = [];
    const missed: string[] = [];
    const insights: string[] = [];

    switch (action.type) {
        case CLEAR_INSIGHTS:
            return initialState;

        case GET_INSIGHTS_SUCCESS:
            action.data.forEach((insight: Insight) => {
                insights.push(insight.id);
                allInsights[insight.id] = insight;
                switch (insight.status) {
                    case 'Not started':
                        toDo.push(insight);
                        break;
                    case 'In progress':
                        inProgress.push(insight);
                        break;
                    case 'Completed':
                        completed.push(insight);
                        break;
                    default:
                        break;
                }

                if (moment(insight.doBye).isBefore(now())) missed.push(insight.id);
            });
            return {
                ...state,
                insights,
                allInsights,
                toDo,
                inProgress,
                completed,
                missed,
            };

        case CREATE_INSIGHT_SUCCESS:
            allInsights[action.data.id] = action.data;
            return {
                ...state,
                allInsights: {
                    ...state.allInsights,
                    ...allInsights,
                },
                insights: [action.data.id, ...state.insights],
                toDo: [action.data, ...state.toDo],
            };
        case UPDATE_INSIGHTS_SUCCESS:
            const previousStatus = action.insight.status;
            const newStatus = action.data.status;

            switch (previousStatus) {
                case 'Not started':
                    toDo = state.toDo.filter((tod) => tod.id !== action.insight.id);
                    break;
                case 'In progress':
                    inProgress = state.inProgress.filter((tod) => tod.id !== action.insight.id);
                    break;
                case 'Completed':
                    completed = state.completed.filter((tod) => tod.id !== action.insight.id);
                    break;
                default:
                    break;
            }

            switch (newStatus) {
                case 'Not started':
                    toDo = [action.data, ...state.toDo];
                    break;
                case 'In progress':
                    inProgress = [action.data, ...state.inProgress];
                    break;
                case 'Completed':
                    completed = [action.data, ...state.completed];
                    break;
                default:
                    break;
            }

            return {
                ...state,
                allInsights: {
                    ...state.allInsights,
                    [action.data.id]: action.data,
                },
                toDo,
                inProgress,
                completed,
            };

        case GET_INSIGHTS_FILTERS_SUCCESS:
        case UPDATE_INSIGHTS_FILTERS_SUCCESS:
            return {
                ...state,
                filters: action.data,
            };

        default:
            return state;
    }
}

export const clearInsights = () => (dispatch: any) => {
    dispatch({
        type: CLEAR_INSIGHTS,
    });
};

export const getInsights = (payload?: any, empId?: string) => (dispatch: any) => {
    execute(get, `/insights${empId ? `?empId=${empId}` : ''}`, payload)
        .then(success(dispatch, GET_INSIGHTS_SUCCESS, payload))
        .catch(failure(dispatch, GET_INSIGHTS_FAILURE, payload));

    dispatch({
        type: GET_INSIGHTS_REQUEST,
        payload,
    });
};

export const updateInsight = (payload: any) => (dispatch: any) => {
    execute(patch, '/insights', payload)
        .then(success(dispatch, UPDATE_INSIGHTS_SUCCESS, payload))
        .catch(failure(dispatch, UPDATE_INSIGHTS_FAILURE, payload));

    dispatch({
        type: UPDATE_INSIGHTS_REQUEST,
        payload,
    });
};

export const addInsight = (payload: any) => (dispatch: any) => {
    execute(post, '/insights', payload)
        .then(success(dispatch, CREATE_INSIGHT_SUCCESS, payload))
        .catch(failure(dispatch, CREATE_INSIGHT_FAILURE, payload));

    dispatch({
        type: CREATE_INSIGHT_REQUEST,
        payload,
    });
};

export const getInsightFilters = (payload?: any) => (dispatch: any) => {
    execute(get, '/insights/filters', payload)
        .then(success(dispatch, GET_INSIGHTS_FILTERS_SUCCESS, payload))
        .catch(failure(dispatch, GET_INSIGHTS_FILTERS_FAILURE, payload));

    dispatch({
        type: GET_INSIGHTS_FILTERS_REQUEST,
        payload,
    });
};

export const updateInsightFilters = (payload: any) => (dispatch: any) => {
    execute(patch, '/insights/filters', payload)
        .then(success(dispatch, UPDATE_INSIGHTS_FILTERS_SUCCESS, payload))
        .catch(failure(dispatch, UPDATE_INSIGHTS_FILTERS_FAILURE, payload));

    dispatch({
        type: UPDATE_INSIGHTS_FILTERS_REQUEST,
        payload,
    });
};

export default reducer;
