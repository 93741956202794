import { Card, Divider, Select, Slider } from "antd";
import { VictoryPie } from 'victory';
import { useSelector } from "react-redux";
import { CSSProperties, useMemo, useState } from "react";
import moment, { now } from "moment";
import CardHeader from "../../molecules/CardHeader";
import colors, { chartColors } from "../../../utils/colors";
import { RootState } from "../../../configureStore";
import { Insight } from "../../../redux/reducer/insight";
import LoadOverlay from "../../atoms/LoadOverlay";
import NoYet from "../../atoms/NoYet";

const trackStyle: CSSProperties = {
    backgroundColor: colors.darkBlue,
    height: 24,
    borderRadius: 16,
};

const railStyle: CSSProperties = {
    height: 24,
    borderRadius: 16,
};

const TimeSensitive = () => {
    const [showFilter, setShowFilters] = useState(false);
    const [notStartedTime, setNotStartedTime] = useState<number>(24);
    const [onceStartedTime, setOnceStartedTime] = useState<number>(24);
    const [timeFrame, setTimeFrame] = useState<string>('Week');
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['insights/GET_INSIGHTS']);
    const insights = useSelector<RootState, { [key: string]: Insight }>(({ insight: { allInsights } }) => allInsights);

    const data = useMemo(() => {
        const amounts = Object.values(insights).reduce((acc: any, curr: Insight) => {
            if (moment(curr.createdAt).add(notStartedTime, timeFrame === 'Week' ? 'w' : 'month').isBefore(moment(curr.startedAt ?? now()))) {
                return acc;
            }
            if (curr.startedAt && moment(curr.startedAt).add(onceStartedTime, timeFrame === 'Week' ? 'w' : 'month').isBefore(moment(curr.completedAt ?? now()))) {
                return acc;
            }
            if (moment(curr.doBye).add(1, 'w').isBefore(now()) || !moment(curr.doBye).isAfter(now())) return acc;
            return {
                ...acc,
                [curr.category]: acc[curr.category] ? acc[curr.category] + 1 : 1,
            };
        }, {});
        return Object.keys(amounts).map((key) => ({ x: `${key} ${amounts[key]}`, y: amounts[key] }));
    }, [
        insights,
        notStartedTime,
        onceStartedTime,
        timeFrame,
    ]);

    const formatter = useMemo(() => (value: number | undefined) => `${value} ${timeFrame}`, [timeFrame]);

    return (
        <Card
            style={{ height: 380 }}
        >
            <CardHeader
                title="Time Sensitive"
                subtitle={`${Object.values(data).reduce((acc: number, curr) => acc + curr.y, 0)} total items`}
                icon="ClockCircleFilled"
                onFilter={() => { setShowFilters(!showFilter); }}
            />
            <Divider style={{ marginBottom: 0 }} />
            {
                loading
                    ? (<LoadOverlay height={300} width="95%" bgColor={colors.white} />)
                    : !Object.keys(insights ?? {}).length
                        ? (<NoYet height={300} title="insights" />)
                        : !Object.keys(data ?? {}).length
                            ? (<NoYet height={300} title="time sensitive insights" />)
                            : (
                                <div
                                    className={`card flex-center-center h300px ${showFilter ? 'rotated' : ''}`}
                                >
                                    <div className="front">
                                        <VictoryPie
                                            innerRadius={125}
                                            colorScale={chartColors}
                                            data={data}
                                        />
                                    </div>
                                    <div className="back pa-8 flex-end-start col">
                                        <Select
                                            className="ma-0"
                                            options={[{ value: 'Week' }, { value: 'Month' }]}
                                            defaultValue="Week"
                                            onChange={setTimeFrame}
                                            style={{ width: 100 }}
                                        />
                                        <div className="w100">
                                            <div
                                                className="my-8"
                                            >
                                                <h5 style={{ marginBottom: "-8px" }}>Not started within:</h5>
                                                <Slider
                                                    onChange={setNotStartedTime}
                                                    defaultValue={24}
                                                    min={1}
                                                    max={24}
                                                    tooltip={{ formatter }}
                                                    trackStyle={trackStyle}
                                                    railStyle={railStyle}
                                                />
                                            </div>
                                            <div
                                                className="mt-8"
                                            >
                                                <h5 style={{ marginBottom: "-8px" }}>Once started, not completed within:</h5>
                                                <Slider
                                                    onChange={setOnceStartedTime}
                                                    defaultValue={24}
                                                    min={1}
                                                    max={24}
                                                    tooltip={{ formatter }}
                                                    trackStyle={trackStyle}
                                                    railStyle={railStyle}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
            }
        </Card>
    );
};

export default TimeSensitive;
