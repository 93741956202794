import colors from "../../utils/colors";

interface LoadOverlayParams {
    size?: number | string;
    height?: number | string;
    width?: number | string;
    bgColor?: string;
}

const LoadOverlay = ({
    size = '100%',
    height,
    width,
    bgColor = colors.black,
}: LoadOverlayParams) => (
    <div
        className="flex-center-center br-md"
        style={{
            position: 'absolute',
            opacity: 0.6,
            backgroundColor: bgColor,
            height: height ?? size,
            width: width ?? size,
            zIndex: 900,
        }}
    >
        <div className="loading-spinner" />
    </div>
);

export default LoadOverlay;
