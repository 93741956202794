import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import AdvisorHome from "../components/templates/AdvisorHome";
import { RootState } from "../configureStore";
import ManagerHome from "../components/templates/ManagerHome";
import { getClients } from "../redux/reducer/clients";
import { getInsights } from "../redux/reducer/insight";
import AdminHome from "../components/templates/AdminHome";

const Home = () => {
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    // Top (highest) level role
    const tlr = useSelector<RootState, 'defaultadmin' | 'admin' | 'manager' | 'advisor' | undefined>(({ auth }) => auth.tlr);
    const onlyDefault = useSelector<RootState, boolean>(({ auth: { roles } }) => roles.isDefaultAdmin && !roles.isAdmin && !roles.isAdvisor && !roles.isManager);
    const isAdmin = useSelector<RootState, boolean>(({ auth: { roles } }) => roles.isAdmin);

    useEffect(() => {
        if (onlyDefault) navigate('/administration');
    }, [onlyDefault, navigate]);

    useEffect(() => {
        if (!onlyDefault) {
            dispatch(getClients());
            dispatch(getInsights());
        }
    }, [onlyDefault]);

    if (!tlr) {
        return (<div />);
    }

    return tlr === "admin" || (tlr === "defaultadmin" && isAdmin)
        ? <AdminHome />
        : tlr === "defaultadmin"
            ? <div />
            : tlr === "manager"
                ? <ManagerHome />
                : <AdvisorHome />;
};

export default Home;
