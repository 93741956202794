import {
    Modal,
    Form,
    Row,
    Col,
    Button,
    Select,
    Checkbox,
} from "antd";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../configureStore";
import { Me, Roles } from "../../../redux/reducer/auth";
import { Client, addClient, deleteClient, updateClient } from "../../../redux/reducer/clients";
import colors from "../../../utils/colors";
import InputField from "../../atoms/InputField";
import { STATES } from "../../../utils";
import { Employee, getEmployees } from "../../../redux/reducer/employees";

interface AddSingleParams {
    setOpen: (b: boolean) => void;
    open: boolean;
    user?: Client;
    advisorId?: string;
}

const AddSingle = ({
    setOpen,
    open,
    user,
    advisorId,
}: AddSingleParams) => {
    const dispatch = useDispatch<any>();
    const me = useSelector<RootState, Me>(({ auth }) => auth.me);
    const roles = useSelector<RootState, Roles>(({ auth }) => auth.roles);
    const myEmployees = useSelector<RootState, string[]>(({ employees }) => employees.myEmployees);
    const level = useSelector<RootState, number>(({ auth }) => auth.level);
    const viewAs = useSelector<RootState, boolean>(({ administration }) => Boolean(administration.viewAs));
    const allEmployees = useSelector<RootState, { [key: string]: Employee; }>(({ employees }) => employees.allEmployees);
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['client/UPDATE_CLIENT'] || status.loading['client/CREATE_CLIENT'] || status.loading['client/REMOVE_CLIENT']);

    const [confirm, setConfirm] = useState(false);
    const [hardDelete, setHardDelete] = useState(false);

    const advisors = useMemo(() => {
        if (viewAs) {
            return Object.values(allEmployees).map((emp) => ({
                value: emp.id,
                label: emp.email,
            }));
        }
        const arr = [{ value: me.id, label: `${me.firstName} ${me.lastName}` }];
        if (advisorId === me.id) return arr;
        if (advisorId) arr.splice(0);
        myEmployees.forEach((id) => {
            if (advisorId) {
                if (advisorId === id) {
                    arr.push({ value: id, label: `${allEmployees[id].firstName} ${allEmployees[id].lastName}` });
                }
            } else {
                arr.push({ value: id, label: `${allEmployees[id].firstName} ${allEmployees[id].lastName}` });
            }
        });

        if (arr.length === 0 && advisorId && allEmployees[advisorId]) {
            arr.push({ value: advisorId, label: `${allEmployees[advisorId].firstName} ${allEmployees[advisorId].lastName}` });
        }

        return arr;
    }, [
        me,
        myEmployees,
        allEmployees,
        advisorId,
    ]);

    useEffect(() => {
        if (roles.isAdmin || roles.isDefaultAdmin || roles.isManager) dispatch(getEmployees({ me: me.id }));
    }, [me, roles]);

    const submitClients = (added: Partial<Client>) => {
        if (user) {
            dispatch(updateClient({
                id: user.id,
                ...added,
                onSuccess: () => {
                    setOpen(false);
                },
            }));
            setOpen(false);
        } else {
            dispatch(addClient({
                clients: added,
                onSuccess: () => {
                    setOpen(false);
                },
            }));
            setOpen(false);
        }
    };

    const removeClient = () => {
        if (!user) return;
        dispatch(deleteClient({
            id: user.id,
            hard: hardDelete,
            onSuccess: () => {
                setConfirm(false);
            },
        }));
    };

    return (
        <>
            <Modal
                open={confirm}
                closable={false}
                okText="Yes"
                okButtonProps={{ loading }}
                cancelText="No"
                zIndex={1000}
                destroyOnClose
                wrapClassName="pt-2"
                onOk={removeClient}
                onCancel={() => setConfirm(false)}
            >
                <div style={{ textAlign: 'center' }} className="mt-4 mb-6">
                    <h3>
                        This action cannot be undone.
                    </h3>
                    <h4>
                        Are you sure you want to delete this client?
                    </h4>
                </div>
                {
                    level === 0 && (
                        <Checkbox
                            className="flex-center-end"
                            checked={hardDelete}
                            onChange={() => setHardDelete(!hardDelete)}
                        >
                            Hard delete
                        </Checkbox>
                    )
                }
            </Modal>
            <Modal
                open={open}
                onCancel={() => { setOpen(false); }}
                onOk={() => { setOpen(false); }}
                okButtonProps={{
                    disabled: true,
                    name: 'Add',
                }}
                destroyOnClose
                closable={false}
                footer={[]}
                width="70%"
                zIndex={600}
            >
                <div className="mb-4">
                    <h2>
                        {user ? 'Edit' : 'Add'} Client
                    </h2>
                </div>
                <Form
                    onFinish={submitClients}
                    initialValues={user ?? { advisorId: advisors?.[0]?.value ?? null }}
                >
                    <Row className="flex-center-between">
                        <Col span={7}><Form.Item name="firstName"><InputField placeholder="First Name" required /></Form.Item></Col>
                        <Col span={7}><Form.Item name="lastName"><InputField placeholder="Last Name" required /></Form.Item></Col>
                        <Col span={7}><Form.Item name="email"><InputField placeholder="Email" required /></Form.Item></Col>
                    </Row>
                    <Row className="flex-center-between">
                        <Col span={7}><Form.Item name="phone"><InputField placeholder="Mobile" type="tel" required /></Form.Item></Col>
                        <Col span={7}><Form.Item name="dob"><InputField type="date" placeholder="Date of Birth" required /></Form.Item></Col>
                        <Col span={7} />
                    </Row>
                    <Row className="flex-center-between">
                        <Col span={7}>
                            <Form.Item name="taxClientId">
                                <InputField
                                    disabled={Boolean(level)}
                                    placeholder="Tax Client ID"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item name="advisorId" required>
                                <Select
                                    className="background required br-md"
                                    style={{ backgroundColor: colors.background }}
                                    placeholder="Relationship Manager"
                                    options={advisors}
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={7} />
                    </Row>
                    <Row className="flex-center-between">
                        <Col span={7}><Form.Item name={["spouse", "firstName"]}><InputField placeholder="Spouse First Name" /></Form.Item></Col>
                        <Col span={7}><Form.Item name={["spouse", "lastName"]}><InputField placeholder="Spouse Last Name" /></Form.Item></Col>
                        <Col span={7}><Form.Item name={["spouse", "email"]}><InputField placeholder="Spouse Email" /></Form.Item></Col>
                    </Row>
                    <h3 className="mb-2">
                        Address
                    </h3>
                    <Row className="flex-center-between">
                        <Col span={7}><Form.Item name={["address", "addressLineOne"]}><InputField placeholder="Address Line One" /></Form.Item></Col>
                        <Col span={7}><Form.Item name={["address", "addressLineTwo"]}><InputField placeholder="Address Line Two" /></Form.Item></Col>
                        <Col span={7} />
                    </Row>
                    <Row className="flex-center-between">
                        <Col span={7}><Form.Item name={["address", "city"]}><InputField placeholder="City" /></Form.Item></Col>
                        <Col span={7}>
                            <Form.Item name={["address", "state"]}>
                                <Select
                                    className="background br-md"
                                    placeholder="State"
                                    options={STATES}
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={7}><Form.Item name={["address", "zipCode"]}><InputField placeholder="Zip Code" /></Form.Item></Col>
                    </Row>
                    <Row className="row-r">
                        <Col span={2}>
                            <Button loading={loading} type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Col>
                        <Col span={2}>
                            <Button disabled={loading} type="ghost" htmlType="reset" onClick={() => { setOpen(false); }}>
                                Cancel
                            </Button>
                        </Col>
                        <Col span={2}>
                            <Button
                                disabled={loading}
                                type="primary"
                                htmlType="button"
                                onClick={() => { setConfirm(true); }}
                                className="dangerButton"
                            >
                                Delete
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default AddSingle;
