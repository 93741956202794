import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { RootState } from "../../configureStore";
import { Client, getClients } from "../../redux/reducer/clients";
import Profile from "../molecules/Profile";
import Icon from "../atoms/Icon";
import InsightsTable from "../organisms/Insights/Table";
import { getInsights } from "../../redux/reducer/insight";
import AddSingle from "../organisms/Clients/AddSingle";
import { Employee } from "../../redux/reducer/employees";
import AddInsight from "../molecules/AddInsight";

const ClientView = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { id, clientId, advisorId } = params ?? {};
    const level = useSelector<RootState, number>(({ auth }) => auth.level);
    const dispatch = useDispatch<any>();
    const client = useSelector<RootState, Client>(({ clients: { allClients } }) => {
        if (clientId) {
            const uuidRegex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
            const isUUID = uuidRegex.test(clientId).valueOf();
            if (isUUID) {
                return allClients[clientId];
            }
            return Object.values(allClients).find((clnt: any) => clnt.email === clientId);
        }

        return allClients[id ?? ''];
    });
    const advisor = useSelector<RootState, Employee>(({ employees: { allEmployees } }) => (advisorId ? allEmployees[advisorId] : null));
    const [editing, setEditing] = useState<boolean>(false);

    useEffect(() => {
        if (!client) {
            dispatch(getClients({}, advisorId));
            dispatch(getInsights({}, advisorId));
        }
    }, [client, advisorId]);

    return (
        <div>
            {
                advisor && (
                    <div className="flex-center mb-4">
                        <Icon
                            className="mr-1"
                            name="ArrowLeftOutlined"
                            onClick={() => { navigate(-1); }}
                        />
                        <h2>
                            Viewing {advisor?.firstName} {advisor?.lastName}
                        </h2>
                    </div>
                )
            }
            <h1 className="mb-2">
                Client
            </h1>
            {
                client ? (
                    <>
                        <AddSingle
                            open={editing}
                            setOpen={setEditing}
                            advisorId={client.advisorId}
                            user={client}
                        />
                        <div className="flex-center-between pa-4 white br-md">
                            <Profile
                                firstName={client.firstName}
                                lastName={client.lastName}
                                image={client.imageUrl}
                                size={42}
                            />
                            <Icon
                                name="EditOutlined"
                                size={24}
                                onClick={() => setEditing(true)}
                            />
                        </div>
                        {
                            process.env.REACT_APP_ENVIRONMENT !== 'production' && level === 0 && (
                                <div className="flex-center mt-2 row-r">
                                    <AddInsight clientId={client.id ?? undefined} />
                                </div>
                            )
                        }
                        <InsightsTable
                            clientId={client.id}
                        />
                    </>
                ) : (<LoadingOutlined />)
            }
        </div>
    );
};

export default ClientView;
