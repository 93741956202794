import { Col, Row } from "antd";
import { CSSProperties } from "react";
import Icon from "../atoms/Icon";

interface InfoCardParams {
    iconName: string;
    iconBackground?: string;
    iconColor?: string;
    title?: string;
    subtitle?: string | JSX.Element;
    span?: number;
    style?: CSSProperties
}

const InfoCard = ({
    iconName,
    iconBackground = undefined,
    iconColor = undefined,
    title,
    subtitle,
    span = 8,
    style = {},
}: InfoCardParams) => (
    <Col span={span}>
        <Row
            className="flex-center-center br-md white"
            style={{
                height: '110px',
                ...style,
            }}
        >
            <Col span={20}>
                <Row
                    style={{
                        height: '50px',
                    }}
                    align="middle"
                >
                    <Col
                        span={4}
                    >
                        <Icon
                            name={iconName}
                            style={{
                                color: iconColor,
                            }}
                            padding="8px"
                            size="32px"
                            backgroundColor={iconBackground}
                            color={iconColor}
                            twoToneColor={iconColor}
                        />
                    </Col>
                    <Col span={1} />
                    <Col span={18}>
                        <h4
                            style={{
                                fontSize: '19px',
                                marginBottom: '0.1em',
                                marginTop: 0,
                            }}
                        >
                            {title}
                        </h4>
                        {subtitle}
                    </Col>
                </Row>
            </Col>
        </Row>
    </Col>
);

export default InfoCard;
