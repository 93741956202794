import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './redux/reducer';

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActionPaths: [
                'onSuccess',
                'payload.onSuccess',
                'onFailure',
                'payload.onFailure',
            ],
        },
    }),

});

export type RootState = ReturnType<typeof store.getState>

export default store;
