import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import TimeSensitive from "../organisms/Manager/TimeSensitive";
import Thresholds from "../organisms/Manager/Thresholds";
import Missed from "../organisms/Manager/Missed";
import WorkLoad from "../organisms/Manager/WorkLoad";
import Performers from "../organisms/Manager/Performers";
import { RootState } from "../../configureStore";
import { Me } from "../../redux/reducer/auth";
import { getEmployees } from "../../redux/reducer/employees";
import { getInsights } from "../../redux/reducer/insight";

const ManagerHome = ({ isAdmin = false }: { isAdmin?: boolean }) => {
    const id = useParams();

    const dispatch = useDispatch<any>();
    const me = useSelector<RootState, Me>(({ auth }) => auth.me);

    useEffect(() => {
        dispatch(getEmployees({ me: me.id }, id?.id));
        if (isAdmin) dispatch(getInsights({}, id?.id));
    }, []);

    return (
        <div>
            <Row gutter={16} className="mb-4">
                <Col span={12}>
                    <TimeSensitive />
                </Col>
                <Col span={12}>
                    <Thresholds />
                </Col>
            </Row>
            <Row gutter={16} className="pb-4">
                <Col span={8}>
                    <Missed isAdmin={isAdmin} />
                </Col>
                <Col span={8}>
                    <WorkLoad />
                </Col>
                <Col span={8}>
                    <Performers />
                </Col>
            </Row>
        </div>
    );
};
export default ManagerHome;
