import { Button, Divider, Modal } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../configureStore";
import { Roles } from "../../redux/reducer/auth";
import colors from "../../utils/colors";
import Icon from "../atoms/Icon";
import AddEditUser from "../organisms/AddEditUser";
import Employees from "../organisms/Employees";
import Connectors from "../organisms/Connectors";
import AddConnector from "../organisms/AddConnector";
import { Institution, delConnector, getTenant, updateInstitution } from "../../redux/reducer/administration";
import InstitutionModal from "../organisms/InstitutionModal";
import WYSIWYG from "../organisms/WYSIWYG";

const Administration = () => {
    const dispatch = useDispatch<any>();
    const level = useSelector<RootState, number>(({ auth }) => auth.level);
    const roles = useSelector<RootState, Roles>(({ auth }) => auth.roles);
    const institution = useSelector<RootState, any>(({ administration }) => (administration.viewAs ? administration.institutions.find((inst: Institution) => inst.id === administration.viewAs) : administration.tenant));
    const viewAs = useSelector<RootState, boolean>(({ administration }) => Boolean(administration.viewAs));
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading["administration/GET_TENANT"]);

    const [add, setAdd] = useState(false);
    const [editInst, setEditInst] = useState(false);
    const [hasSurePrep, setHasSurePrep] = useState(Boolean(institution?.spDomain));
    const [addConnector, setAddConnector] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        roles.isDefaultAdmin && dispatch(getTenant({
            onSuccess: ({ data: { tenant: { spDomain } } }: { data: { tenant: { spDomain: boolean; } } }) => {
                setHasSurePrep(Boolean(spDomain));
            },
        }));
    }, [roles.isDefaultAdmin]);

    const deleteConnector = (id: string) => {
        dispatch(delConnector({
            id,
        }));
    };

    const updateInst = (form: {
        name: string;
        fqdn: string;
        id: string;
        defaultAdmin: string;
        enabled: boolean;
        firstName: string;
        lastName: string;
        phone: string;
        spDomain: string;
        surePrep: {
            domain: string;
            username: string;
            password: string;
            apiKey: string;
        };
    }) => {
        const {
            name,
            fqdn,
            defaultAdmin,
            enabled,
        } = form;
        if (
            institution
            && (
                name !== institution?.name
                || fqdn !== institution?.fqdn
                || defaultAdmin !== institution?.defaultAdmin
                || enabled !== institution?.enabled
                || hasSurePrep
            )
        ) {
            dispatch(updateInstitution({
                id: form.id,
                ...form.defaultAdmin && { defaultAdmin: form.defaultAdmin },
                ...form.name && { name: form.name },
                ...form.fqdn && { fqdn: form.fqdn },
                ...(typeof form.enabled === 'boolean') && { enabled: Boolean(form.enabled) },
                ...hasSurePrep && {
                    surePrep: {
                        domain: form.spDomain,
                        userName: form.surePrep.username,
                        password: form.surePrep.password,
                        apiKey: form.surePrep.apiKey,
                    },
                },
            }));
        }

        setEditInst(false);
    };

    return (
        <div className="pb-8">
            <Modal
                open={add}
                // onOk={() => { setOpen(false); }}
                onCancel={() => { setAdd(false); }}
                destroyOnClose
                footer={null}
                closable={false}
                width="70%"
                zIndex={800}
            >
                <AddEditUser setOpen={setAdd} />
            </Modal>
            {
                (roles.isAdmin || roles.isDefaultAdmin || roles.isAdvisor) && (
                    <div className="flex-center-between row-r ma-2">
                        <Icon
                            name="PlusSquareTwoTone"
                            color={colors.mediumBlue}
                            twoToneColor={colors.mediumBlue}
                            size={32}
                            onClick={() => { setAdd(true); }}
                        />
                        <div className="flex-center">
                            {
                                roles.isDefaultAdmin && (
                                    <Button
                                        type="primary"
                                        className="mr-2"
                                        onClick={() => navigate('/administration/groups')}
                                        disabled={viewAs}
                                    >
                                        Manage Groups
                                    </Button>
                                )
                            }
                            {
                                level === 0 && (
                                    <Button
                                        type="primary"
                                        onClick={() => navigate('/administration/institutions')}
                                        disabled={viewAs}
                                    >
                                        Institutions
                                    </Button>
                                )
                            }
                            {
                                (roles.isDefaultAdmin && level !== 0) && (
                                    <>
                                        <InstitutionModal
                                            self
                                            add={editInst}
                                            setAdd={setEditInst}
                                            onCancel={() => { setEditInst(false); setHasSurePrep(false); }}
                                            onOk={() => { setEditInst(false); setHasSurePrep(false); }}
                                            institution={institution}
                                            updateInst={updateInst}
                                        />
                                        <Button
                                            type="primary"
                                            onClick={() => { setEditInst(true); }}
                                        >
                                            Edit Institution
                                        </Button>
                                    </>
                                )
                            }
                        </div>
                    </div>
                )
            }
            <Employees />
            {
                roles.isDefaultAdmin && (
                    <>
                        <Modal
                            open={addConnector}
                            // onOk={() => { setOpen(false); }}
                            onCancel={() => { setAddConnector(false); }}
                            destroyOnClose
                            footer={null}
                            closable={false}
                            width="50%"
                            zIndex={800}
                        >
                            <AddConnector setOpen={setAddConnector} />
                        </Modal>
                        <Divider />
                        <div className="flex-center-between row-r ma-2">
                            {
                                !viewAs && (
                                    <Icon
                                        name="PlusSquareTwoTone"
                                        color={colors.mediumBlue}
                                        twoToneColor={colors.mediumBlue}
                                        size={32}
                                        onClick={() => { setAddConnector(true); }}
                                    />
                                )
                            }
                        </div>
                        <Connectors
                            onDelete={(id) => { deleteConnector(id); }}
                        />
                    </>
                )
            }
            {!loading && <WYSIWYG />}
        </div>
    );
};

export default Administration;
