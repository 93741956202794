import { useDispatch } from "react-redux";
import { useEffect } from "react";
import Employees from "../organisms/Employees";
import { clearInsights } from "../../redux/reducer/insight";
import { clearClients } from "../../redux/reducer/clients";

const AdminHome = () => {
    const dispatch = useDispatch<any>();

    useEffect(() => {
        dispatch(clearInsights());
        dispatch(clearClients());
    }, []);

    return (
        <Employees
            mine
        />
    );
};

export default AdminHome;
