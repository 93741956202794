import { CSSProperties, ChangeEvent } from 'react';
import InputField from './InputField';

const DollarInput = ({
    id = 'dollarInput',
    value,
    onChange,
    style = {},
    className = '',
    allowClear = true,
}: {
    id: string;
    value: string;
    onChange: (item: string) => void;
    style?: CSSProperties;
    className?: string;
    allowClear?: boolean;
}) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        const formattedValue = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        onChange(formattedValue);
    };

    return (
        <InputField
            id={id}
            value={value}
            onChange={handleChange}
            placeholder="0"
            style={style}
            maxLength={12}
            prefix="$"
            className={className}
            allowClear={allowClear}
        />
    );
};

export default DollarInput;
