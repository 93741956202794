import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { Col, Row } from "antd";
import ManagerHome from "./ManagerHome";
import Clients from "../../routes/Clients";
import { RootState } from "../../configureStore";
import { Employee } from "../../redux/reducer/employees";
import Icon from "../atoms/Icon";

const ManagerView = () => {
    const navigate = useNavigate();
    const id = useParams();
    const [overview, setOverview] = useState<boolean>(true);
    const advisor = useSelector<RootState, Employee>(({ employees: { allEmployees } }) => allEmployees[id?.id ?? '']);

    return (
        <div>
            <div className="flex-center mb-4">
                <Icon
                    className="mr-1"
                    name="ArrowLeftOutlined"
                    onClick={() => { navigate(-1); }}
                />
                <h2>
                    Viewing {advisor?.firstName} {advisor?.lastName}
                </h2>
            </div>
            <Row className="white br-md pa-0 pointer" style={{ height: 40, width: 300, margin: '0 auto 16px' }}>
                <Col
                    style={{
                        borderStartStartRadius: 16,
                        borderEndStartRadius: 16,
                    }}
                    className={`flex-center-center ${overview ? 'lightBlue' : ''}`}
                    span={12}
                    onClick={() => { setOverview(true); }}
                >
                    <h5 className={`${overview ? 'white--text' : ''}`}>Overview</h5>
                </Col>
                <Col
                    style={{
                        borderEndEndRadius: 16,
                        borderStartEndRadius: 16,
                    }}
                    className={`flex-center-center ${!overview ? 'lightBlue' : ''}`}
                    span={12}
                    onClick={() => { setOverview(false); }}
                >
                    <h5 className={`${!overview ? 'white--text' : ''}`}>Clients</h5>
                </Col>
            </Row>
            {
                overview ? <ManagerHome isAdmin /> : <Clients isAdmin advisorId={id?.id} />
            }
        </div>
    );
};

export default ManagerView;
