import { Button, Checkbox, Dropdown, Input, MenuProps, Space } from "antd";
import { useState } from "react";
import colors from "../../../utils/colors";
import Icon from "../../atoms/Icon";
import { ISubtask } from "../../../redux/reducer/insight";

interface SubtaskParams {
    description: string;
    completed: boolean;
    onSave: (subtask: ISubtask) => void;
    onDelete: () => void;
}

const Subtask = ({
    description,
    completed,
    onSave,
    onDelete,
}: SubtaskParams) => {
    const [editing, setEditing] = useState<boolean>(description.length === 0);
    const [isComplete, setIsComplete] = useState<boolean>(completed);
    const [newDesc, setNewDesc] = useState<string>(description);

    const saveSubtask = () => {
        const subtask: ISubtask = {
            completed: isComplete,
            description: newDesc,
        };
        onSave(subtask);
    };

    const items: MenuProps['items'] = [
        {
            key: 1,
            label: 'Edit',
            onClick: () => { setEditing(!editing); },
        },
        {
            key: 2,
            label: 'Delete',
            onClick: () => { onDelete(); },
        },
    ];

    return (
        <div
            key={description}
            style={{
                borderColor: colors.lightGray,
                borderWidth: "1px",
                borderStyle: "solid",
            }}
            className="flex-between align-center br-sm pa-2 mt-2"
        >
            <Checkbox
                checked={isComplete}
                onChange={() => { setIsComplete(!isComplete); }}
                className="mr-3"
            />
            <Space.Compact style={{ width: '100%' }}>
                <Input
                    disabled={completed}
                    defaultValue={newDesc}
                    onChange={(e) => { setNewDesc(e.target.value); }}
                />
                <Button disabled={newDesc === description && completed === isComplete} onClick={() => saveSubtask()}>Save</Button>
            </Space.Compact>
            <Dropdown
                menu={{ items }}
                className="ml-2"
                trigger={["hover", "click"]}
            >
                <Icon
                    name="EllipsisOutlined"
                    rotate={90}
                />
            </Dropdown>
        </div>
    );
};

export default Subtask;
