import { Modal, Table, Tooltip } from "antd";
import { ColumnType } from "antd/es/table";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../../configureStore";
import { Me } from "../../redux/reducer/auth";
import { Employee, getEmployees, removeEmployee } from "../../redux/reducer/employees";
import { formatPhoneNumber } from "../../utils";
import Icon from "../atoms/Icon";
import Profile from "../molecules/Profile";
import AddEditUser from "./AddEditUser";

const getNameFilters = (clients: Employee[] = []) => {
    const names = Array.from(new Set<string>(clients.map((r: any) => `${r.firstName} ${r.lastName}`)));
    return names.map((clientName) => ({
        text: clientName,
        value: clientName,
    }));
};

const Employees = ({
    mine = false,
}: {
    mine?: boolean;
}) => {
    const navigation = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch<any>();
    const me = useSelector<RootState, Me>(({ auth }) => auth.me);
    const isAdmin = useSelector<RootState, boolean>(({ auth }) => auth.roles.isAdmin);
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['employees/GET_EMPLOYEES']);
    const data = useSelector<RootState, Employee[]>(({ employees: { allEmployees, myEmployees } }) => (mine ? myEmployees.map((id) => allEmployees[id]) : Object.values(allEmployees)));
    const viewAs = useSelector<RootState, boolean>(({ administration }) => administration.viewAs);

    useEffect(() => {
        dispatch(getEmployees({ me: me.id }));
    }, []);

    const [user, setUser] = useState<Employee>();
    const [open, setOpen] = useState(false);
    const [confirm, setConfirm] = useState(false);

    useEffect(() => {
        if (!open) setUser(undefined);
    }, [open]);

    const columns: ColumnType<any>[] = useMemo<ColumnType<any>[]>(() => [
        {
            title: 'User',
            dataIndex: 'firstName',
            sorter: (a: any, b: any) => (`${a.lastName} ${a.firstName}` > `${b.lastName} ${b.firstName}` ? -1 : 1),
            render: (_item: string, record: Employee) => (
                <Profile
                    firstName={record.firstName}
                    image={record.imageUrl}
                    lastName={record.lastName}
                    className="mr-2 br-sm"
                    size="52px"
                    onClick={() => { if ((location.pathname === '/employees' && isAdmin) || location.pathname === '/administration') { return; } navigation(`/${mine ? 'manager' : 'employees'}/${record.id}`); }}
                />
            ),
            filters: getNameFilters(data),
            filterSearch: true,
            onFilter: (value: any, r: any) => `${r.firstName} ${r.lastName}`.includes(value),
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            render: (phone: string) => <div>{formatPhoneNumber(phone)}</div>,
        },
        {
            title: 'Manager',
            dataIndex: 'managerName',
            render: (manager: string) => <div>{manager ?? '-'}</div>,
        },
        {
            // eslint-disable-next-line react/no-unstable-nested-components
            title: () => (
                <div>
                    Top-Level Role
                    <Tooltip
                        trigger={['hover', 'focus', "click"]}
                        // eslint-disable-next-line react/no-unstable-nested-components
                        title={() => (
                            <div style={{ textAlign: 'center' }}>
                                <p>User&apos;s highest role level</p>
                                <p>Will update after the employee logs in.</p>
                            </div>
                        )}
                    >
                        <Icon
                            className="ml-1"
                            name="InfoCircleOutlined"
                        />
                    </Tooltip>
                </div>
            ),
            dataIndex: 'tlr',
            render: (tlr: string) => <div style={{ textTransform: 'capitalize' }}>{tlr ? tlr === 'defaultadmin' ? 'Default Admin' : tlr : '-'}</div>,
        },
        {
            title: <div />,
            dataIndex: 'managerId',
            render: (managerId: string, r: Employee) => ((me.id === managerId || viewAs) ? (
                <Icon
                    name="EditOutlined"
                    size={24}
                    onClick={() => {
                        setUser(r);
                        setOpen(true);
                    }}
                />
            ) : <div />),
            filters: [{
                text: 'Assigned to me',
                value: 'mine',
            }],
            onFilter: (val: any, r: any) => { if (val === 'mine' && r.managerId === me.id) { return true; } return false; },
        },
    ], [data, me, navigation, mine, location, isAdmin]);

    const deleteUser = () => {
        if (user) {
            dispatch(removeEmployee({ id: user.id }));
        }
        setConfirm(false);
    };

    return (
        <>
            <Modal
                open={confirm}
                onCancel={() => { setConfirm(false); }}
                onOk={deleteUser}
                okText="Yes"
                cancelText="No, keep user"
                zIndex={1000}
            >
                Deleting this user cannot be undone. Continue and delete user?
            </Modal>
            <Modal
                open={open}
                // onOk={() => { setOpen(false); }}
                onCancel={() => { setOpen(false); }}
                destroyOnClose
                footer={null}
                closable={false}
                width="70%"
                zIndex={800}
            >
                {
                    user && (
                        <AddEditUser
                            user={user}
                            editing
                            setConfirm={setConfirm}
                            setOpen={setOpen}
                        />
                    )
                }
            </Modal>
            <Table
                rowKey={({ id }) => id}
                dataSource={data}
                columns={columns}
                loading={loading}
            />
        </>
    );
};

export default Employees;
