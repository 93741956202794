/* eslint-disable no-case-declarations */
/* eslint-disable default-param-last */
import { del, get, patch, post, postMulti } from "../http/request";
import execute from "../http/execute";
import success from "../http/success";
import failure from "../http/failure";
import { GET_ME_SUCCESS } from "./auth";

export const CLEAR_CLIENTS = 'client/CLEAR_CLIENTS';

export const GET_CLIENTS_REQUEST = 'client/GET_CLIENTS_REQUEST';
export const GET_CLIENTS_SUCCESS = 'client/GET_CLIENTS_SUCCESS';
export const GET_CLIENTS_FAILURE = 'client/GET_CLIENTS_FAILURE';

export const CREATE_CLIENT_REQUEST = 'client/CREATE_CLIENT_REQUEST';
export const CREATE_CLIENT_SUCCESS = 'client/CREATE_CLIENT_SUCCESS';
export const CREATE_CLIENT_FAILURE = 'client/CREATE_CLIENT_FAILURE';

export const UPDATE_CLIENT_REQUEST = 'client/UPDATE_CLIENT_REQUEST';
export const UPDATE_CLIENT_SUCCESS = 'client/UPDATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT_FAILURE = 'client/UPDATE_CLIENT_FAILURE';

export const CREATE_CLIENTS_REQUEST = 'client/CREATE_CLIENTS_REQUEST';
export const CREATE_CLIENTS_SUCCESS = 'client/CREATE_CLIENTS_SUCCESS';
export const CREATE_CLIENTS_FAILURE = 'client/CREATE_CLIENTS_FAILURE';

export const REMOVE_CLIENT_REQUEST = 'client/REMOVE_CLIENT_REQUEST';
export const REMOVE_CLIENT_SUCCESS = 'client/REMOVE_CLIENT_SUCCESS';
export const REMOVE_CLIENT_FAILURE = 'client/REMOVE_CLIENT_FAILURE';

export interface Client {
    email: string;
    firstName: string;
    lastName: string;
    advisorId: string;
    address: {
        addressLineOne: string;
        addressLineTwo?: string;
        city: string;
        state: string;
        zipCode: string;
    };
    imageUrl?: string;
    dob: string;
    phone: string;
    id: string;
    accepted: boolean;
    createdAt: string;
    updatedAt: string;
}

const initialState: {
    meId: string;
    allClients: {
        [key: string]: Client
    };
    clients: string[];
    myClients: string[];
} = {
    meId: '',
    allClients: {},
    clients: [],
    myClients: [],
};

function reducer(state = initialState, action: any) {
    let createdClients;
    let clients = [];
    switch (action.type) {
        case GET_ME_SUCCESS:
            return {
                ...state,
                meId: action.data.id,
            };

        case CLEAR_CLIENTS:
            return {
                ...initialState,
                meId: state.meId,
            };

        case GET_CLIENTS_SUCCESS:
            const allClients = action.data.reduce((acc: {
                [key: string]: Client
            }, curr: Client) => ({
                ...acc,
                [curr.id]: curr,
            }), {});
            clients = action.data.map(({ id }: { id: string; }) => id);

            return {
                ...state,
                allClients,
                clients,
                myClients: clients.filter((id: string) => allClients[id].advisorId === state.meId),
            };

        case UPDATE_CLIENT_SUCCESS:
            return {
                ...state,
                allClients: {
                    ...state.allClients,
                    [action.data.id]: action.data,
                },
            };

        case CREATE_CLIENTS_SUCCESS:
            createdClients = action.data?.successes || action.data;
            const newClients = createdClients.reduce((acc: {
                [key: string]: Client
            }, curr: Client) => ({
                ...acc,
                [curr.id]: curr,
            }), {});
            clients = createdClients.map(({ id }: { id: string; }) => id);

            return {
                ...state,
                allClients: {
                    ...state.allClients,
                    ...newClients,
                },
                clients: [
                    ...clients,
                    ...state.clients,
                ],
            };

        case CREATE_CLIENT_SUCCESS:
            return {
                ...state,
                allClients: {
                    ...state.allClients,
                    [action.data.id]: action.data,
                },
                clients: [
                    action.data.id,
                    ...state.clients,
                ],
            };

        case REMOVE_CLIENT_SUCCESS:
            const newAllClients = JSON.parse(JSON.stringify(state.allClients));
            delete newAllClients[action.id];
            const clientsPostDel = state.clients.filter((id) => id !== action.id);

            return {
                ...state,
                allClients: newAllClients,
                clients: clientsPostDel,
            };

        default:
            return state;
    }
}

export const clearClients = () => (dispatch: any) => {
    dispatch({
        type: CLEAR_CLIENTS,
    });
};

export const getClients = (payload?: any, empId?: string) => (dispatch: any) => {
    execute(get, `/clients${empId ? `?empId=${empId}` : ''}`)
        .then(success(dispatch, GET_CLIENTS_SUCCESS, payload))
        .catch(failure(dispatch, GET_CLIENTS_FAILURE, payload));

    dispatch({
        type: GET_CLIENTS_REQUEST,
        payload,
    });
};

export const addClient = (payload: { clients: Partial<Client>, onSuccess?: any }) => (dispatch: any) => {
    execute(post, '/clients', payload.clients)
        .then(success(dispatch, CREATE_CLIENT_SUCCESS, payload))
        .catch(failure(dispatch, CREATE_CLIENT_FAILURE, payload));

    dispatch({
        type: CREATE_CLIENT_REQUEST,
        payload,
    });
};

export const updateClient = (payload: any) => (dispatch: any) => {
    execute(patch, '/clients', payload)
        .then(success(dispatch, UPDATE_CLIENT_SUCCESS, payload))
        .catch(failure(dispatch, UPDATE_CLIENT_FAILURE, payload));

    dispatch({
        type: UPDATE_CLIENT_REQUEST,
        payload,
    });
};

export const bulkAddClient = (payload: { data: FormData, onSuccess?: () => void }) => (dispatch: any) => {
    execute(postMulti, '/clients/bulk', payload.data)
        .then(success(dispatch, CREATE_CLIENTS_SUCCESS, payload))
        .catch(failure(dispatch, CREATE_CLIENTS_FAILURE, payload));

    dispatch({
        type: CREATE_CLIENTS_REQUEST,
        payload,
    });
};

export const deleteClient = (payload: { id: string, hard: boolean, onSuccess: () => void }) => (dispatch: any) => {
    execute(del, `/clients?id=${payload.id}&hard=${payload.hard}`)
        .then(success(dispatch, REMOVE_CLIENT_SUCCESS, payload))
        .catch((err) => {
            console.error(err);
            failure(dispatch, REMOVE_CLIENT_FAILURE, payload);
        });

    dispatch({
        type: REMOVE_CLIENT_REQUEST,
        payload,
    });
};

export default reducer;
