import { Button, Col, Form, Modal, Row, Switch } from "antd";
import InputField from "../atoms/InputField";

interface InstitutionModalParams {
    add: boolean;
    setAdd: (b: boolean) => void;
    institution?: any;
    onCancel: () => void;
    onOk: () => void;
    updateInst: (_: any) => void;
    self?: boolean;
}

const InstitutionModal = ({
    add,
    setAdd,
    institution,
    onCancel,
    onOk,
    updateInst,
    self = false,
}: InstitutionModalParams) => (
    <Modal
        open={add}
        closable={false}
        onCancel={onCancel}
        onOk={onOk}
        destroyOnClose
        footer={null}
    >
        <>
            <h3 className="mb-4">
                {institution ? 'Edit' : 'Add'} Institution
            </h3>
            <Form onFinish={updateInst} initialValues={institution}>
                {!self && <Form.Item name="id"><InputField disabled={Boolean(institution)} placeholder="Tenant ID" required /></Form.Item>}
                <Form.Item name="name"><InputField placeholder="Institution Name" required /></Form.Item>
                <Form.Item name="fqdn"><InputField placeholder="Domain" required /></Form.Item>
                <Form.Item name="defaultAdmin"><InputField placeholder="Default Admin" required /></Form.Item>
                <Row gutter={16} className="flex-center-between">
                    <Col span={12}><Form.Item name="firstName"><InputField placeholder="Admin First Name" /></Form.Item></Col>
                    <Col span={12}><Form.Item name="lastName"><InputField placeholder="Admin Last Name" /></Form.Item></Col>
                </Row>
                <Row gutter={16} className="flex-center-between">
                    <Col span={12}><Form.Item name="phone"><InputField placeholder="Admin Phone Number" /></Form.Item></Col>
                    <Col className="flex-center-center" style={{ marginTop: -24 }} span={12}><h3 className="mr-3">Enabled</h3><Form.Item valuePropName="checked" name="enabled" className="mb-0"><Switch defaultChecked /></Form.Item></Col>
                </Row>
                {!self && (
                    <>
                        <Row gutter={16}>
                            <Col span={12}><Form.Item name="spDomain"><InputField placeholder="SurePrep Domain Name" /></Form.Item></Col>
                            <Col span={12}><Form.Item name={["surePrep", "username"]}><InputField placeholder={institution?.spDomain ? "SP Username Already Set" : "SurePrep Username"} /></Form.Item></Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}><Form.Item name={["surePrep", "password"]}><InputField placeholder={institution?.spDomain ? "SP Password Already Set" : "SurePrep Password"} /></Form.Item></Col>
                            <Col span={12}><Form.Item name={["surePrep", "apiKey"]}><InputField placeholder={institution?.spDomain ? "SP API Key Already Set" : "SurePrep API Key"} /></Form.Item></Col>
                        </Row>
                    </>
                )}
                <div className="flex-center row-r">
                    <Button type="primary" htmlType="submit">
                        {institution ? 'Save' : 'Create'}
                    </Button>
                    {!institution && (
                        <Button
                            type="ghost"
                            className="mx-2"
                            onClick={() => { setAdd(false); }}
                        >
                            Cancel
                        </Button>
                    )}
                </div>
            </Form>
        </>
    </Modal>
);

export default InstitutionModal;
