import { useState } from "react";
import { Button, Col, Form, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../atoms/InputField";
import { addConnector } from "../../redux/reducer/administration";
import { RootState } from "../../configureStore";

interface AddConnectorParams {
    setOpen: (b: boolean) => void;
}

interface Validations {
    id: "success" | "error" | "warning" | "validating";
    name: "success" | "error" | "warning" | "validating";
}

const AddConnector = ({
    setOpen,
}: AddConnectorParams) => {
    const dispatch = useDispatch<any>();
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['administration/ADD_CONNECTOR']);
    const [validations, setValidations] = useState<Validations>({
        id: "validating",
        name: "validating",
    });

    const onChange = (e: any) => {
        switch (e.target.id) {
            case 'id':
                setValidations({
                    ...validations,
                    id: (e.target.value?.length && /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/.test(e.target.value)) ? "success" : "error",
                });
                break;
            case 'name':
                setValidations({
                    ...validations,
                    name: e.target.value?.length ? "success" : "error",
                });
                break;
            default: break;
        }
    };

    const onSubmit = (props: {
        id: string;
        name: string
    }) => {
        dispatch(addConnector({
            id: props.id,
            name: props.name,
            onSuccess: () => {
                setOpen(false);
            },
        }));
    };

    return (
        <>
            <h3 className="mb-4">
                Add Connector
            </h3>
            <Form
                onFinish={onSubmit}
                onChange={onChange}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item name="id" validateStatus={validations.id}>
                            <InputField placeholder="ID" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name="name" validateStatus={validations.name}>
                            <InputField placeholder="Name" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="row-r">
                    <Button
                        loading={loading}
                        type="primary"
                        htmlType="submit"
                    >
                        Save
                    </Button>
                </Row>
            </Form>
        </>
    );
};

export default AddConnector;
