/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from "react";
import { Col, Form, Row, Switch } from "antd";

import DollarInput from "../atoms/DollarInput";
import { InsightCategory } from "../../utils";

interface InsightFilterParams {
    insight: {
        name: string;
        category: InsightCategory;
        active: boolean;
        threshold?: number;
        code: string;
    };
    category: string;
}

const InsightFilter = ({
    insight,
    category,
}: InsightFilterParams) => {
    const [value, setValue] = useState(String(insight.threshold ? insight.threshold : '').replace(/\B(?=(\d{3})+(?!\d))/g, ','));

    const handleInputChange = (newValue: string) => {
        setValue(newValue);
    };

    return (
        <Row justify="space-between" align="middle" gutter={16}>
            <Form.Item
                name={[category, insight.code, 'code']}
                initialValue={insight.code}
            />
            <Col span={8}>
                <Form.Item
                    name={[category, insight.code, 'name']}
                    initialValue={insight.name}
                    className="ma-0"
                >
                    <h4>{insight.name}</h4>
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item
                    name={[category, insight.code, 'active']}
                    initialValue={insight.active}
                >
                    <Switch defaultChecked={insight.active} />
                </Form.Item>
            </Col>
            <Col span={10}>
                <Form.Item
                    name={[category, insight.code, 'threshold']}
                    initialValue={value}
                >
                    <DollarInput
                        id="dollarInput"
                        value={value}
                        onChange={handleInputChange}
                        className="ml-2"
                    />
                </Form.Item>
            </Col>
        </Row>
    );
};

export default InsightFilter;
