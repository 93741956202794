import { Avatar } from "antd";
import ColorHash from 'color-hash';

interface ProfileParams {
    firstName: string;
    lastName: string;
    className?: string;
    image?: string;
    size?: string | number;
    onClick?: () => void;
    noName?: boolean
}

const Profile = ({
    size = "35px",
    className = "",
    firstName,
    lastName,
    image,
    onClick,
    noName = false,
}: ProfileParams) => {
    const inits = `${firstName?.charAt(0) ?? ""}${lastName?.charAt(0) ?? ""}`;
    const colorHash = new ColorHash({
        lightness: 0.5,
        hue: { min: 90, max: 270 },
    });

    return (
        <div
            className={`flex-center-row ${className}${onClick ? ' pointer' : ''}`}
            onClick={onClick}
        >
            {
                image ? (
                    <Avatar
                        alt={`${firstName} ${lastName}`}
                        src={image}
                        style={{
                            height: size,
                            width: size,
                        }}
                        className="mr-2 br-sm"
                    />
                ) : (
                    <div
                        style={{
                            height: size,
                            width: size,
                            backgroundColor: colorHash.hex(inits),
                        }}
                        className="mr-2 br-sm flex-center-center"
                    >
                        <h4 className="white--text" style={{ lineHeight: '35px' }}>
                            {inits}
                        </h4>
                    </div>
                )
            }
            {!noName && (
                <p>
                    {firstName} {lastName}
                </p>
            )}
        </div>
    );
};

export default Profile;
