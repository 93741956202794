import {
    Layout as AntLayout,
} from "antd";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import AppHeader from "../organisms/AppHeader";
import AppMenu from "../organisms/AppMenu";
import { RootState } from "../../configureStore";

const { Header, Content, Sider } = AntLayout;

const Layout = () => {
    const [menuOpen, setMenuOpen] = useState(true);
    const fiId = useSelector<RootState, string>(({ administration }) => administration.viewAs);

    return (
        <AntLayout
            style={{
                height: '100vh',
                overflowY: 'auto',
                padding: '20px',
            }}
        >
            <Header
                className="ant-layout-header br-md mb-5 white"
                style={{ height: 'fit-content' }}
            >
                <AppHeader
                    toggleMenu={() => { setMenuOpen(!menuOpen); }}
                />
            </Header>
            <AntLayout>
                <Sider
                    collapsed={!menuOpen}
                    collapsedWidth={0}
                    className={`br-md white ${!menuOpen ? '' : 'mr-5'}`}
                >
                    <AppMenu key={fiId} />
                </Sider>
                <Content key={fiId}>
                    <Outlet />
                </Content>
            </AntLayout>
        </AntLayout>
    );
};

export default Layout;
