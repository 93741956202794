import { Button, Form, Input, Modal } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bulkAddClient } from "../../../redux/reducer/clients";
import { RootState } from "../../../configureStore";

interface AddBulkParams {
    setOpen: (b: boolean) => void;
    open: boolean;
}

const AddBulk = ({
    setOpen,
    open,
}: AddBulkParams) => {
    const dispatch = useDispatch<any>();
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['client/CREATE_CLIENT']);
    const [selectedFile, setSelectedFile] = useState<any>({});
    const changeHandler = (event: any) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleSubmission = () => {
        const formData = new FormData();

        formData.append('File', selectedFile);

        dispatch(bulkAddClient({ data: formData, onSuccess: () => { setOpen(false); } }));
    };

    const handleDownload = () => {
        const fileUrl = '/TaxFilers.xlsx';
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', 'TaxFilers.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Modal
            open={open}
            onCancel={() => { setOpen(false); }}
            onOk={() => { setOpen(false); }}
            okButtonProps={{
                disabled: true,
                name: 'Add',
            }}
            destroyOnClose
            closable={false}
            footer={[]}
            width="40%"
        >
            <div className="mb-4 flex-center-between">
                <h2>
                    Bulk Add Client
                </h2>
                <Button
                    type="ghost"
                    onClick={handleDownload}
                    className="lightBlue--text"
                >
                    Download Example File
                </Button>
            </div>

            <Form onFinish={handleSubmission}>
                <Form.Item name="file" className="pointer">
                    <Input type="file" className="pointer" accept=".csv,.xlsx" onChange={changeHandler} />
                </Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                >
                    Upload
                </Button>
            </Form>
        </Modal>
    );
};

export default AddBulk;
