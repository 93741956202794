/* eslint-disable max-len */
import { Buffer } from 'buffer';
import { PublicClientApplication } from '@azure/msal-browser';
import axios, { InternalAxiosRequestConfig } from 'axios';

export function jwtInterceptor(msalInstance: PublicClientApplication) {
  axios.interceptors.request.use(async (request: InternalAxiosRequestConfig) => {
    const account = msalInstance.getAllAccounts()[0];
    const accessTokenRequest = {
      scopes: [`api://${process.env.REACT_APP_TOKEN_AUDIENCE}/api`, "GroupMember.Read.All", "Calendars.ReadWrite.Shared"],
      account,
    };

    const accExp = localStorage.getItem('ACCESS_EXPIRY');
    if (Number(accExp) < new Date().getTime()) {
      try {
        const accessTokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest);
        const { accessToken, idToken } = accessTokenResponse;
        localStorage.setItem('ACCESS_TOKEN', accessToken);
        localStorage.setItem('ID_TOKEN', idToken);
        localStorage.setItem('ACCESS_EXPIRY', (JSON.parse(Buffer.from(accessToken.split('.')[1], 'base64').toString('ascii')).exp * 1000).toString());
      } catch (err) {
        msalInstance.logoutRedirect();
      }
    } else {
      msalInstance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse: any) => {
          const { accessToken, idToken } = accessTokenResponse;
          localStorage.setItem('ACCESS_TOKEN', accessToken);
          localStorage.setItem('ID_TOKEN', idToken);
          localStorage.setItem('ACCESS_EXPIRY', (JSON.parse(Buffer.from(accessToken.split('.')[1], 'base64').toString('ascii')).exp * 1000).toString());
        })
        .catch((error: any) => {
          console.error(error);
        });
    }
    request.headers['Content-Type'] = 'application/json';
    request.headers.Authorization = `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`;
    request.headers['Ocp-Apim-Subscription-Key'] = process.env.REACT_APP_OCP_APIM_SUB_KEY ?? '';
    return request;
  });
}
