import { Checkbox, Table } from "antd";
import { ColumnType } from "antd/es/table";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Icon from "../components/atoms/Icon";
import Profile from "../components/molecules/Profile";
import AddBulk from "../components/organisms/Clients/AddBulk";
import AddSingle from "../components/organisms/Clients/AddSingle";
import { RootState } from "../configureStore";
import { Client, getClients } from "../redux/reducer/clients";
import { formatPhoneNumber } from "../utils";
import colors from "../utils/colors";
import EmployeeName from "../components/atoms/EmployeeName";
import { getInsights } from "../redux/reducer/insight";

const getNameFilters = (clients: Client[] = []) => {
    const names = Array.from(new Set<string>(clients.map((r: any) => `${r.firstName} ${r.lastName}`)));
    return names.map((clientName) => ({
        text: clientName,
        value: clientName,
    }));
};

const Clients = ({
    isAdmin,
    advisorId,
}: {
    isAdmin?: boolean;
    advisorId?: string;
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const [active, setActive] = useState<Client>();
    const [single, setSingle] = useState(false);
    const [bulk, setBulk] = useState(false);
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['client/GET_CLIENTS'] || status.loading['client/CREATE_CLIENT']);
    const data = useSelector<RootState, Client[]>(({ clients: { clients, allClients } }) => clients.map((id: string) => allClients[id]));
    const isManager = useSelector<RootState, boolean>(({ auth: { roles: { isManager: isMan } } }) => isMan);
    const viewAs = useSelector<RootState, string>(({ administration }) => administration.viewAs);

    const queryParameters = new URLSearchParams(window.location.search);
    const email = queryParameters.get("email");
    const navved = localStorage.getItem('navved');

    useEffect(() => {
        dispatch(getClients(
            {
                onSuccess: ({ data: clientData }: { data: Client[] }) => {
                    if (email && clientData?.length > 0) {
                        const idx = clientData.findIndex(({ email: uEmail }) => uEmail === atob(email));
                        if (idx >= 0) {
                            localStorage.setItem('navved', 'true');
                            if (navved !== 'true') navigate(`/clients/${clientData[idx].id}`);
                        }
                    }
                },
            },
            advisorId,
        ));
        dispatch(getInsights({}, advisorId));
    }, [
        advisorId,
        email,
        navved,
    ]);

    const clients = useMemo(() => data, [
        data,
    ]);

    const columns: ColumnType<any>[] = useMemo(() => [
        {
            title: 'Client',
            dataIndex: 'firstName',
            sorter: (a: any, b: any) => (`${a.lastName} ${a.firstName}` > `${b.lastName} ${b.firstName}` ? -1 : 1),
            render: (_item: string, record: Client) => (
                <Profile
                    firstName={record.firstName}
                    lastName={record.lastName}
                    image={record.imageUrl}
                    className="mr-2 br-sm"
                    size="52px"
                    onClick={() => { navigate(isAdmin ? `/manager/${advisorId}/client/${record.id}` : `/clients/${record.id}`); }}
                />
            ),
            filters: getNameFilters(data),
            filterSearch: true,
            onFilter: (value: any, r: any) => `${r.firstName} ${r.lastName}`.includes(value),
        },
        {
            title: 'Contact Info',
            dataIndex: 'contactInfo',
            render: (_item: string, record: Client) => (
                <div>
                    <a href={`mailto:${record.email}`}><h4>{record.email}</h4></a>
                    <h5>{formatPhoneNumber(record.phone)}</h5>
                </div>
            ),
        },
        {
            title: 'Address',
            dataIndex: 'address',
            render: (_item: string, record: Client) => (
                <div>
                    {record.address?.addressLineOne && <p>{record.address?.addressLineOne}</p>}
                    {record.address?.addressLineTwo && <p>{record.address?.addressLineTwo}</p>}
                    {record.address?.city && <p>{record.address?.city} {record.address?.state}, {record.address?.zipCode}</p>}
                </div>
            ),
        },
        {
            title: isManager ? 'Advisor' : 'Location',
            dataIndex: isManager ? 'advisorId' : 'location',
            render: (item: string) => (isManager ? <EmployeeName id={item} /> : undefined),
        },
        {
            title: 'Accepted Invite',
            dataIndex: 'accepted',
            render: (item: boolean) => (<Checkbox checked={item} />),
        },
        {
            title: <Icon name="MenuOutlined" />,
            dataIndex: 'id',
            render: (_id: boolean, item: Client) => (
                <Icon
                    className="pointer"
                    name="EllipsisOutlined"
                    onClick={() => { setActive(item); setSingle(true); }}
                />
            ),
        },
    ], [data, isManager, navigate, isAdmin, advisorId]);

    return (
        <div>
            <AddSingle
                open={single}
                setOpen={setSingle}
                user={active}
                advisorId={advisorId}
            />
            <AddBulk
                open={bulk}
                setOpen={setBulk}
            />
            <div className="flex-center-end my-2">
                <Icon
                    name="PlusSquareOutlined"
                    size="32px"
                    className="mr-2"
                    color={colors.lightBlue}
                    onClick={() => { setActive(undefined); setSingle(true); }}
                />
                {
                    (!advisorId && !viewAs) && (
                        <div
                            className="flex-center pa-2 br-sm pointer"
                            style={{ borderWidth: 1, borderStyle: 'solid', borderColor: colors.darkBlue }}
                            onClick={() => setBulk(true)}
                        >
                            <h5 className="mr-1">
                                Bulk Upload
                            </h5>
                        </div>
                    )
                }
            </div>
            <Table
                dataSource={clients}
                columns={columns}
                loading={loading}
                rowKey={(record) => record.id}
            />
        </div>
    );
};

export default Clients;
