/* eslint-disable default-param-last */
import { del, get, patch, post } from "../http/request";
import execute from "../http/execute";
import success from "../http/success";
import failure from "../http/failure";

export const GET_EMPLOYEES_REQUEST = 'employees/GET_EMPLOYEES_REQUEST';
export const GET_EMPLOYEES_SUCCESS = 'employees/GET_EMPLOYEES_SUCCESS';
export const GET_EMPLOYEES_FAILURE = 'employees/GET_EMPLOYEES_FAILURE';

export const CREATE_EMPLOYEE_REQUEST = 'employees/CREATE_EMPLOYEE_REQUEST';
export const CREATE_EMPLOYEE_SUCCESS = 'employees/CREATE_EMPLOYEE_SUCCESS';
export const CREATE_EMPLOYEE_FAILURE = 'employees/CREATE_EMPLOYEE_FAILURE';

export const UPDATE_EMPLOYEE_REQUEST = 'employees/UPDATE_EMPLOYEE_REQUEST';
export const UPDATE_EMPLOYEE_SUCCESS = 'employees/UPDATE_EMPLOYEE_SUCCESS';
export const UPDATE_EMPLOYEE_FAILURE = 'employees/UPDATE_EMPLOYEE_FAILURE';

export const REMOVE_EMPLOYEE_REQUEST = 'employees/REMOVE_EMPLOYEE_REQUEST';
export const REMOVE_EMPLOYEE_SUCCESS = 'employees/REMOVE_EMPLOYEE_SUCCESS';
export const REMOVE_EMPLOYEE_FAILURE = 'employees/REMOVE_EMPLOYEE_FAILURE';

export const GET_PERFORMERS_REQUEST = 'employees/GET_PERFORMERS_REQUEST';
export const GET_PERFORMERS_SUCCESS = 'employees/GET_PERFORMERS_SUCCESS';
export const GET_PERFORMERS_FAILURE = 'employees/GET_PERFORMERS_FAILURE';

export type Performance = {
    advisorId: string;
    completedCount: number;
    completedAmount: number;
    inProgressCount: number;
    inProgressAmount: number;
    notStartedCount: number;
    notStartedAmount: number;
    totalCount: number;
    totalAmount: number;
}

export interface Employee {
    id: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    salesforceEmail: string;
    managerId: string;
    managerName: string;
    tlr: string;
    imageUrl: string;
}

const initialState: {
    allEmployees: {
        [key: string]: Employee;
    };
    employees: string[];
    myEmployees: string[];
    performance: {
        top: Performance[];
        under: Performance[];
    }
} = {
    allEmployees: {},
    employees: [],
    myEmployees: [],
    performance: {
        top: [],
        under: [],
    },
};

function reducer(state = initialState, action: any) {
    const ids: string[] = [];
    let allEmployees: {
        [key: string]: Employee;
    } = {};
    let myEmployees: string[] = [];
    let id: string;

    switch (action.type) {
        case REMOVE_EMPLOYEE_SUCCESS:
            allEmployees = state.allEmployees;
            delete allEmployees[action.data.removed.id];

            myEmployees = state.myEmployees.filter((empId) => empId !== action.data.removed.id);
            action.data.employees.forEach((emp: Employee) => {
                myEmployees.push(emp.id);
                allEmployees[emp.id] = emp;
            });

            return {
                ...state,
                allEmployees,
                employees: state.employees.filter((empId) => empId !== action.data.removed.id),
                myEmployees: [...myEmployees, ...action.data.employees.map(({ id: empId }: { id: string }) => empId)],
            };

        case UPDATE_EMPLOYEE_SUCCESS:
            return {
                ...state,
                allEmployees: {
                    ...state.allEmployees,
                    [action.data.id]: action.data,
                },
            };

        case CREATE_EMPLOYEE_SUCCESS:
            id = action.data.id;
            return {
                ...state,
                employees: [action.data.id, ...state.employees],
                allEmployees: {
                    ...state.allEmployees,
                    [id]: action.data,
                },
                myEmployees: action.isMe ? [action.data.id, ...state.myEmployees] : state.myEmployees,
            };

        case GET_EMPLOYEES_SUCCESS:
            action.data.forEach((emp: Employee) => {
                allEmployees[emp.id] = emp;
                ids.push(emp.id);
                if (emp.managerId === action.me) myEmployees.push(emp.id);
            });
            return {
                ...state,
                employees: ids,
                allEmployees,
                myEmployees,
            };

        case GET_PERFORMERS_SUCCESS:
            return {
                ...state,
                performance: {
                    top: action.data.top,
                    under: action.data.under,
                },
            };

        default:
            return state;
    }
}

export const createEmployee = (payload: any) => (dispatch: any) => {
    execute(post, '/employees', payload)
        .then(success(dispatch, CREATE_EMPLOYEE_SUCCESS, payload))
        .catch(failure(dispatch, CREATE_EMPLOYEE_FAILURE, payload));

    dispatch({
        type: CREATE_EMPLOYEE_REQUEST,
        payload,
    });
};

export const updateEmployee = (payload: any) => (dispatch: any) => {
    execute(patch, '/employees', payload)
        .then(success(dispatch, UPDATE_EMPLOYEE_SUCCESS, payload))
        .catch(failure(dispatch, UPDATE_EMPLOYEE_FAILURE, payload));

    dispatch({
        type: UPDATE_EMPLOYEE_REQUEST,
        payload,
    });
};

export const removeEmployee = (payload: { id: string }) => (dispatch: any) => {
    execute(del, `/employees?id=${payload.id}`)
        .then(success(dispatch, REMOVE_EMPLOYEE_SUCCESS, payload))
        .catch(failure(dispatch, REMOVE_EMPLOYEE_FAILURE, payload));

    dispatch({
        type: REMOVE_EMPLOYEE_REQUEST,
        payload,
    });
};

export const getEmployees = (payload: any, empId?: string) => (dispatch: any) => {
    execute(get, `/employees${empId ? `?id=${empId}` : ''}`)
        .then(success(dispatch, GET_EMPLOYEES_SUCCESS, payload))
        .catch(failure(dispatch, GET_EMPLOYEES_FAILURE, payload));

    dispatch({
        type: GET_EMPLOYEES_REQUEST,
        payload,
    });
};

export const getPerformers = (payload?: any, empId?: string) => (dispatch: any) => {
    execute(get, `/employees/performers${empId ? `?empId=${empId}` : ''}`)
        .then(success(dispatch, GET_PERFORMERS_SUCCESS, payload))
        .catch(failure(dispatch, GET_PERFORMERS_FAILURE, payload));

    dispatch({
        type: GET_PERFORMERS_REQUEST,
        payload,
    });
};

export default reducer;
