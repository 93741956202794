import { useSelector } from "react-redux";
import { RootState } from "../../configureStore";
import { Employee } from "../../redux/reducer/employees";
import { Me } from "../../redux/reducer/auth";

interface EmployeeNameParams {
    id: string;
}

const EmployeeName = ({
    id,
}: EmployeeNameParams) => {
    const employee = useSelector<RootState, Employee>(({ employees }) => employees.allEmployees[id]);
    const me = useSelector<RootState, Me>(({ auth }) => auth.me);

    return (
        <div>
            <p>
                {employee ? `${employee.firstName} ${employee.lastName}` : `${me.firstName} ${me.lastName}`}
            </p>
        </div>
    );
};

export default EmployeeName;
