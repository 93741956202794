/* eslint-disable no-param-reassign */
const success = (dispatch: any, type: string, input: any) => (payload: any) => {
    try {
        if (input?.onSuccess) input.onSuccess(payload);
    } catch (err) {
        console.error(err);
        /* empty */
    }

    dispatch({
        type,
        ...input,
        data: payload.data,
    });
};

export default success;
