const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
});

export const formatPhoneNumber = (phoneNumberString: string) => {
    // eslint-disable-next-line prefer-template
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        const intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
};

export const formatUSD = (amt?: number | bigint): string => (amt == null ? '-' : formatter.format(amt));

export const formatShortUSD = (num: number | bigint): string => {
    const BILLION = 1_000_000_000n;
    const MILLION = 1_000_000n;
    const THOUSAND = 1_000n;

    let sigDigs = '';

    const divisor = num >= BILLION
        ? 1_000_000_000
        : num >= MILLION
            ? 1_000_000
            : num >= THOUSAND
                ? 1_000
                : 1;
    const suffix = num >= BILLION
        ? 'B'
        : num >= MILLION
            ? "M"
            : num >= THOUSAND
                ? 'k'
                : '';

    const rndNum = Number(num) / divisor;
    const retNum = String(Number(num) / divisor).split('.')[0];
    const lngth = retNum.length;

    if (lngth === 1) {
        const substr = String(rndNum)?.split('.')?.[1]?.substring(0, 2);
        if (substr) {
            sigDigs = `.${substr.length === 2 ? substr : `${substr}0`}`;
        } else {
            sigDigs = '.00';
        }
    } else if (lngth === 2) {
        sigDigs = `.${String(rndNum)?.split('.')?.[1]?.substring(0, 2) ?? '0'}`;
    }

    return `$${retNum}${sigDigs}${suffix}`;
};

export const STATES = [
    { value: "AL" },
    { value: "AK" },
    { value: "AS" },
    { value: "AZ" },
    { value: "AR" },
    { value: "CA" },
    { value: "CO" },
    { value: "CT" },
    { value: "DC" },
    { value: "DE" },
    { value: "FL" },
    { value: "GA" },
    { value: "GU" },
    { value: "HI" },
    { value: "ID" },
    { value: "IL" },
    { value: "IN" },
    { value: "IA" },
    { value: "KS" },
    { value: "KY" },
    { value: "LA" },
    { value: "ME" },
    { value: "MD" },
    { value: "MA" },
    { value: "MI" },
    { value: "MN" },
    { value: "MS" },
    { value: "MO" },
    { value: "MP" },
    { value: "MT" },
    { value: "NE" },
    { value: "NV" },
    { value: "NH" },
    { value: "NJ" },
    { value: "NM" },
    { value: "NY" },
    { value: "NC" },
    { value: "ND" },
    { value: "OH" },
    { value: "OK" },
    { value: "OR" },
    { value: "PA" },
    { value: "PR" },
    { value: "RI" },
    { value: "SC" },
    { value: "SD" },
    { value: "TN" },
    { value: "TX" },
    { value: "UT" },
    { value: "VT" },
    { value: "VA" },
    { value: "VI" },
    { value: "WA" },
    { value: "WV" },
    { value: "WI" },
    { value: "WY" },
];

export type InsightCategory = 'Income' | 'Investment' | 'Other' | 'Property';

export const INSIGHT_CODES: { [code: string]: { name: string; category: InsightCategory } } = {
    "I-WS": { category: "Income", name: "Wage Summary" },
    "I-BusinessIncome": { category: "Income", name: "Business Income" },
    "I-DisabilitySickPay": { category: "Income", name: "Disability Sick Pay" },
    "I-ContractorIncomeMiscIncome": { category: "Income", name: "Contractor Income (Misc Income)" },
    "I-Royalties": { category: "Income", name: "Royalties" },
    "I-UnemploymentCompensation": { category: "Income", name: "Unemployment Compensation" },
    "I-EarlyRetirementDistribution&Penalty": { category: "Income", name: "Early Retirement Distribution & Penalty" },
    "I-RetirementDistributionPre72": { category: "Income", name: "Retirement Distribution Pre 72" },
    "I-NonStandardDistribution": { category: "Income", name: "Non Standard Distribution" },
    "I-InheritedDistribution": { category: "Income", name: "Inherited Distribution" },
    "I-CharitableGiftAnnuity": { category: "Income", name: "Charitable Gift Annuity" },
    "I-PTP": { category: "Income", name: "Publicly Traded Partnership" },
    "I-EarlySocialSecurityBenefits": { category: "Income", name: "Early Social Security Benefits" },
    "I-TrustEstateIncome": { category: "Income", name: "Trust/Estate Income" },
    "I-SecuredLoan": { category: "Property", name: "Secured Loan" },
    "I-LoansAway": { category: "Property", name: "Loans Away" },
    "I-RecentFinancingActivity": { category: "Property", name: "Recent Financing Activity" },
    "I-AnnualPropertyTax": { category: "Property", name: "Annual Property Tax" },
    "I-GrossProceeds": { category: "Investment", name: "Gross Proceeds" },
    "I-OrdinaryDividend": { category: "Investment", name: "Ordinary Dividend" },
    "I-DividendOthers1": { category: "Investment", name: "Dividend Others" },
    "I-DepositInterestCON": { category: "Investment", name: "Deposit Interest" },
    "I-OIDCON": { category: "Investment", name: "Original Issue Discount (OID)" },
    "I-DepositInterestINT": { category: "Investment", name: "Deposit Interest" },
    "I-DividendOthersDIV": { category: "Investment", name: "Ordinary Dividend" },
    "I-OIDINT": { category: "Investment", name: "Original Issue Discount (OID)" },
    "I-AIPIPart": { category: "Investment", name: "Alternate Investment-Passive Income (Partnership)" },
    "I-AILE": { category: "Investment", name: "Alternate Investment-Liquidation Event" },
    "I-ERUC": { category: "Investment", name: "Employee Retirement Under Contributed" },
};
