import { useMemo, useState } from "react";
import { Button, Col, Form, Modal, Row, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import colors from "../../utils/colors";
import Icon from "../atoms/Icon";
import { RootState } from "../../configureStore";
import InputField from "../atoms/InputField";
import { INSIGHT_CODES } from "../../utils";
import { addInsight } from "../../redux/reducer/insight";

const EMPTY_PROP = {
    likelihood: 0,
    propType: "",
};

interface AddInsightParams {
    clientId?: string
}

const AddInsight = ({
    clientId,
}: AddInsightParams) => {
    const dispatch = useDispatch<any>();
    const [add, setAdd] = useState(false);
    const [insightName, setInsightName] = useState<string>();
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [props, setProps] = useState<{ propType: string; likelihood: number }[]>([EMPTY_PROP]);

    const clients = useSelector<RootState, any[]>(({ clients: { clients: clnts, allClients } }) => (clientId
        ? [{
            value: allClients[clientId].id,
            label: `${allClients[clientId].firstName} ${allClients[clientId].lastName}`,
        }]
        : clnts.map((id: string) => ({
            value: allClients[id].id,
            label: `${allClients[id].firstName} ${allClients[id].lastName}`,
        }))));

    const names = useMemo(() => Object.keys(INSIGHT_CODES).map((iName) => ({ value: iName })), []);

    const categories = useMemo(() => {
        if (!insightName) return [];
        return [{ value: INSIGHT_CODES[insightName].category }];
    }, [insightName]);

    const submitInsight = (res: {
        clientId: string;
        insight: string;
        institution: string;
        category: string;
        code: string;
        taxYear: string;
        amount: string;
        file?: string;
        loanNumber?: string;
        document?: string;
        form?: string;
        propensities: {
            propType: string;
            likelihood: string;
        }[]
    }) => {
        dispatch(addInsight({
            clientId: res.clientId,
            insight: res.insight,
            institution: res.institution,
            category: res.category,
            code: res.code,
            taxYear: Number(res.taxYear),
            amount: Number(res.amount),
            file: res.file,
            loanNumber: res.loanNumber,
            document: res.document,
            form: res.form,
            propensities: res.propensities.map(({ likelihood, propType }) => ({
                likelihood: Number(likelihood) / 100,
                propType,
            })),
        }));
        setAdd(false);
    };

    const insName = useMemo(() => (insightName ? INSIGHT_CODES[insightName].name : ""), [insightName]);

    return (
        <>
            <Modal
                open={add}
                onOk={() => setAdd(false)}
                onCancel={() => setAdd(false)}
                destroyOnClose
                closable={false}
                footer={null}
            >
                <Form
                    onFinish={submitInsight}
                    initialValues={{ taxYear: 2022, clientId: clients.length === 1 ? clients[0].value : undefined }}
                >
                    <Row className="flex-center-between">
                        <Col span={24}>
                            <Form.Item name="clientId" required>
                                <Select
                                    className="background required br-md"
                                    style={{ backgroundColor: colors.background }}
                                    placeholder="Client"
                                    options={clients}
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="flex-center-between">
                        <Col span={11}>
                            <Form.Item name="insight" required>
                                <Select
                                    onChange={setInsightName}
                                    className="background required br-md"
                                    style={{ backgroundColor: colors.background }}
                                    placeholder="Insight Code"
                                    options={names}
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item name="insight" required initialValue={insName}>
                                {insName}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="flex-center-between">
                        <Col span={11}>
                            <Form.Item name="category" required>
                                <Select
                                    className="background required br-md"
                                    style={{ backgroundColor: colors.background }}
                                    placeholder="Insight Category"
                                    options={categories}
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={11}><Form.Item name="institution" required><InputField required placeholder="Institution Name" /></Form.Item></Col>
                    </Row>
                    <Row className="flex-center-between">
                        <Col span={11}><Form.Item name="taxYear" required><InputField required type="number" min={2018} max={moment().year()} /></Form.Item></Col>
                        <Col span={11}><Form.Item name="amount" required><InputField required type="number" min={0} pattern="^\$?\d{1,3}(,\d{3})*(\.\d{2})?$" placeholder="$0.00" /></Form.Item></Col>
                    </Row>
                    <h4 className="mb-2">
                        Propensities
                    </h4>
                    {
                        props.map((_, idx) => (
                            <Row key={`prop-${idx}`} className="flex-start-between">
                                <Col span={8}><Form.Item name={["propensities", idx, "propType"]}><InputField placeholder="Propensity" /></Form.Item></Col>
                                <Col span={5}><Form.Item name={["propensities", idx, "likelihood"]}><InputField placeholder="Likelihood" type="number" min={0} step={1} max={100} /></Form.Item></Col>
                                <Col span={2} className="mt-1"><Button disabled={idx !== props.length - 1} onClick={() => { setProps([...props, EMPTY_PROP]); }} type="primary">New</Button></Col>
                                <Col span={5} className="flex-center-center mt-1"><Button disabled={!idx || idx !== props.length - 1} onClick={() => { setProps(props.slice(0, props.length - 1)); }}>Remove</Button></Col>
                            </Row>
                        ))
                    }
                    {
                        showAdvanced && (
                            <>
                                <Row className="flex-center-between">
                                    <Col span={11}><Form.Item name="file"><InputField placeholder="File Name" /></Form.Item></Col>
                                    <Col span={11}><Form.Item name="loanNumber"><InputField placeholder="Loan Number" /></Form.Item></Col>
                                </Row>
                                <Row className="flex-center-between">
                                    <Col span={11}>
                                        <Form.Item name="document">
                                            <Select className="background" placeholder="Document" size="large" options={[{ value: 'WAID' }, { value: 'RECA' }, { value: 'RETR' }]} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                        <Form.Item name="form">
                                            <InputField placeholder="Form Name" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        )
                    }
                    <Row className="flex-center-between">
                        <Col span={12}>
                            <Button type="ghost" className="lightBlue--text" onClick={() => { setShowAdvanced(!showAdvanced); }}>
                                {showAdvanced ? 'Less options' : 'Show advanced'}
                            </Button>
                        </Col>
                        <Col span={12} className="flex-center row-r">
                            <Button type="primary" htmlType="submit">
                                Save
                            </Button>
                            <Button type="ghost" onClick={() => { setProps([EMPTY_PROP]); setAdd(false); }}>
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Icon
                name="PlusOutlined"
                backgroundColor={colors.lightBlue}
                borderRadius={4}
                padding={8}
                color={colors.white}
                onClick={() => {
                    setAdd(true);
                }}
            />
        </>
    );
};

// const AddInsight = (_props: any) => <div />;

export default AddInsight;
