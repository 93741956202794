import AddEditUser from "../organisms/AddEditUser";

interface SettingsParams {
}

const Settings = ({
}: SettingsParams) => (
    <div className="white br-md pa-4">
        <AddEditUser editing isMe />
    </div>
);

export default Settings;
