import { Modal } from "antd";
import AddEditUser from "./AddEditUser";
import { Employee } from "../../redux/reducer/employees";

interface AddEditUserModalParams {
    setConfirm: (bool: boolean) => void;
    setOpen: (bool: boolean) => void;
    open: boolean;
    user?: Employee;
}

const AddEditUserModal = ({
    user,
    open,
    setConfirm,
    setOpen,
}: AddEditUserModalParams) => (
    <Modal
        open={open}
        // onOk={() => { setOpen(false); }}
        onCancel={() => { setOpen(false); }}
        destroyOnClose
        footer={null}
        closable={false}
        width="70%"
        zIndex={800}
    >
        <AddEditUser
            user={user}
            editing
            setConfirm={setConfirm}
            setOpen={setOpen}
        />
    </Modal>
);

export default AddEditUserModal;
