import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import InsightsTable from "../organisms/Insights/Table";
import { RootState } from "../../configureStore";
import { getClients } from "../../redux/reducer/clients";
import HeaderBack from "../molecules/HeaderBack";
import { Employee, getEmployees } from "../../redux/reducer/employees";
import { getInsights } from "../../redux/reducer/insight";
import Filters from "../organisms/Insights/Filters";

const InsightsView = () => {
    const dispatch = useDispatch<any>();
    const [status, setStatus] = useState<string | null>();
    const params = useParams();
    const itemCounts = useSelector<RootState, { 'Not Started': number; 'In Progress': number; 'Completed': number; }>(({ insight }) => Object.values(insight.allInsights).reduce((acc, curr) => {
        const statusTemp = curr.status === 'Not started' ? 'Not Started' : curr.status === 'In progress' ? 'In Progress' : curr.status === 'Completed' ? 'Completed' : null;
        if (statusTemp) {
            return {
                ...acc,
                [statusTemp]: acc[statusTemp] + 1,
            };
        }
        return acc;
    }, {
        'Not Started': 0,
        'In Progress': 0,
        Completed: 0,
    }));
    const advisor = useSelector<RootState, Employee>(({ employees: { allEmployees } }) => allEmployees[params?.id ?? '']);
    const isEmployer = useSelector<RootState, boolean>(({ auth: { roles: { isAdmin, isDefaultAdmin, isManager } } }) => isAdmin || isDefaultAdmin || isManager);
    const adminAcc = useSelector<RootState, boolean>(({ auth: { roles: { isAdmin } } }) => isAdmin);

    useEffect(() => {
        dispatch(getClients({}, params?.id ?? ''));
        if (isEmployer) dispatch(getEmployees({}, params?.id ?? ''));
        dispatch(getInsights({}));
    }, [params]);

    return (
        <div id="insightsView">
            <div className="flex-center-between mb-4">
                <HeaderBack title={advisor ? `Viewing All Insights for ${advisor.firstName} ${advisor.lastName}` : 'Insights'} />
                {(!params?.id && adminAcc) && <Filters />}
            </div>
            <div className="flex-center-around br-md w100 white">
                <div
                    className={`pointer br-md-l py-2 flex-center-center col w25 border-r ${!status && 'active-bg'}`}
                    onClick={() => { setStatus(null); }}
                >
                    <h4>All</h4>
                    <p>{Object.values(itemCounts).reduce((acc: number, curr) => acc + curr, 0)} total</p>
                </div>
                <div
                    className={`pointer py-2 flex-center-center col w25 border-r ${status === 'Not started' && 'active-bg'}`}
                    onClick={() => { setStatus('Not started'); }}
                >
                    <h4>Not started</h4>
                    <p>{itemCounts["Not Started"]} total</p>
                </div>
                <div
                    className={`pointer py-2 flex-center-center col w25 border-r ${status === 'In progress' && 'active-bg'}`}
                    onClick={() => { setStatus('In progress'); }}
                >
                    <h4>In progress</h4>
                    <p>{itemCounts["In Progress"]} total</p>
                </div>
                <div
                    className={`pointer br-md-r py-2 flex-center-center col w25 border-r ${status === 'Completed' && 'active-bg'}`}
                    onClick={() => { setStatus('Completed'); }}
                >
                    <h4>Complete</h4>
                    <p>{itemCounts.Completed} total</p>
                </div>
            </div>
            <InsightsTable
                status={status}
            />
        </div>
    );
};

export default InsightsView;
