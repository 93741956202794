import React from 'react';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import "./App.css";
import reportWebVitals from './reportWebVitals';
import store from './configureStore';
import { msalConfig } from './authConfig';
import { jwtInterceptor } from './jwtInterceptor';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

const msalInstance = new PublicClientApplication(msalConfig);
jwtInterceptor(msalInstance);

root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <Provider store={store}>
                <App />
            </Provider>
        </MsalProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console. log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
