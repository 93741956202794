/* eslint-disable max-len */
import { Divider, Dropdown, MenuProps, Progress } from "antd";
import moment from "moment";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../configureStore";
import { Client } from "../../../redux/reducer/clients";
import { Insight } from "../../../redux/reducer/insight";
import { formatShortUSD } from "../../../utils";
import Icon from "../../atoms/Icon";
import PriorityTag from "../../atoms/PriorityTag";
import Profile from "../../molecules/Profile";
import InsightModal from "./InsightModal";

interface InsightCardParams {
    insight: Insight;
}

const InsightCard = ({
    insight,
}: InsightCardParams) => {
    const [modal, setModal] = useState<boolean>(false);
    const client = useSelector<RootState, Client>(({ clients }) => clients.allClients[insight.clientId] ?? {});

    const items: MenuProps['items'] = [
        {
            key: 1,
            label: 'Complete task',
            onClick: (_e) => { },
        },
        {
            key: 2,
            label: 'Delete task',
            onClick: () => { },
            danger: true,
        },
    ];

    const tasksDone = useMemo(() => {
        let total = 0;
        let done = 0;
        if (insight.task) {
            done = insight?.task?.subtasks?.reduce((acc, curr) => acc + (curr.completed ? 1 : 0), 0) ?? 0;
            total = insight?.task?.subtasks?.length;
        }
        return {
            done,
            total,
        };
    }, [insight]);

    return (
        <>
            <InsightModal
                setOpen={setModal}
                open={modal}
                insight={insight}
                client={client}
            />
            <div
                className="card insight-card mr-2 pointer"
                onClick={() => {
                    setModal(!modal);
                }}
            >
                <div className="flex-between mb-3">
                    <h5>
                        {insight.category}
                    </h5>
                    <Dropdown
                        menu={{ items }}
                        trigger={["hover", "click"]}
                    >
                        <div>
                            <Icon
                                name="EllipsisOutlined"
                                rotate={90}
                            />
                        </div>
                    </Dropdown>
                </div>
                <Progress
                    showInfo={false}
                />
                <div className="flex-between">
                    <h5>
                        <Icon
                            name="DollarOutlined"
                            className="mr-1"
                        />
                        {formatShortUSD(Number(insight.amount))}
                    </h5>
                    <h5>
                        <Icon
                            name="UnorderedListOutlined"
                            className="mr-1"
                        />
                        {tasksDone.done}/{tasksDone.total}
                    </h5>
                    <h5 className="danger--text">
                        <Icon
                            name="CalendarOutlined"
                            color="red"
                            className="mr-1"
                        />
                        {moment(insight.doBye).format("MMM DD yyy")}
                    </h5>
                </div>
                <Divider className="my-3" />
                <div className="flex-between">
                    <Profile
                        firstName={client.firstName}
                        lastName={client.lastName}
                        image={client.imageUrl}
                    />
                    <PriorityTag
                        priority={insight.priority}
                    />
                </div>
            </div>
        </>
    );
};

export default InsightCard;
