import { Button, Col, Divider, Form, Modal, Row, Select, Tag } from "antd";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import Datetime from 'react-datetime';

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Client } from "../../../redux/reducer/clients";
import { Insight, ISubtask, updateInsight } from "../../../redux/reducer/insight";
import colors from "../../../utils/colors";
import Icon from "../../atoms/Icon";
import Profile from "../../molecules/Profile";
import Subtask from "./Subtask";
import { formatShortUSD } from "../../../utils";
import { RootState } from "../../../configureStore";

interface InsightModalParams {
    setOpen: (b: boolean) => void;
    open: boolean;
    insight: Insight;
    client: Client;
}

const InsightModal = ({
    setOpen,
    open,
    insight,
    client,
}: InsightModalParams) => {
    const dispatch = useDispatch<any>();
    const [show, setShow] = useState<boolean>(false);
    const [desc, setDesc] = useState<string>(insight?.task?.description ?? '');
    const [subtasks, setSubtasks] = useState<ISubtask[]>(insight?.task?.subtasks ?? []);
    const [selectedDate, setSelectedDate] = useState('');

    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['insights/UPDATE_INSIGHTS']);

    function handleDateChange(datetime: string | moment.Moment) {
        if (typeof datetime === 'string') {
            setSelectedDate(moment(datetime).format('YYYY-MM-DD HH:mm:ss'));
        } else {
            setSelectedDate(datetime.format('YYYY-MM-DD HH:mm:ss'));
        }
    }

    useEffect(() => {
        if (!show && selectedDate && moment(selectedDate).toISOString() !== moment(insight.doBye).toISOString()) {
            dispatch(updateInsight({
                insight,
                doBye: moment(selectedDate).utc().endOf('day').toISOString(),
            }));
        }
    }, [show, selectedDate, insight]);

    const handleDescription = () => {
        dispatch(
            updateInsight({
                insight,
                task: {
                    ...insight.task,
                    description: desc,
                },
            }),
        );
    };

    const deleteSubtask = (subIdx: number) => {
        const subtaskList = subtasks;

        subtaskList.splice(subIdx, 1);
        setSubtasks(subtaskList);

        dispatch(
            updateInsight({
                insight,
                task: {
                    ...insight.task,
                    subtasks: subtaskList,
                },
            }),
        );
    };

    const saveSubtasks = (newSub: ISubtask, subIdx: number) => {
        const subtaskList = subtasks;

        subtaskList[subIdx] = newSub;
        setSubtasks(subtaskList);
        dispatch(
            updateInsight({
                insight,
                task: {
                    ...insight.task,
                    subtasks: subtaskList,
                },
            }),
        );
    };

    const updatePriority = (priority: string) => {
        dispatch(
            updateInsight({
                insight,
                priority,
            }),
        );
    };

    const updateStatus = (status: string) => {
        dispatch(
            updateInsight({
                insight,
                status,
            }),
        );
    };

    return (
        <Modal
            open={open}
            onCancel={() => { setOpen(false); }}
            onOk={() => { setOpen(false); }}
            okText="Save"
            closable={false}
            width="1000px"
            className="no-pad-modal"
            footer={null}
        >
            <div className="flex-between pa-4">
                <div className="flex-center-row">
                    <Profile
                        firstName={client?.firstName}
                        lastName={client?.lastName}
                        image={client?.imageUrl}
                    />
                    <Tag
                        className="ml-4 success--text br-md px-4 py-1"
                        style={{ backgroundColor: 'rgba(42, 100, 42, 0.06)', border: 'none' }}
                    >
                        {insight.category}
                    </Tag>
                </div>
                <Tag
                    className="flex-center-row mr-4 pointer"
                >
                    <Icon
                        name="EyeOutlined"
                        style={{
                            marginRight: 4,
                        }}
                    />
                    View Details
                </Tag>
            </div>
            <Divider className="mb-0" />
            <Form>
                <Row className="pl-4 justify-between">
                    <Col span={18} className="pr-3 pb-4">
                        <div className="flex-center-between">
                            <h2 className="mt-4 mb-4">
                                {insight.insight}
                            </h2>
                            <Button
                                type="primary"
                                onClick={handleDescription}
                                loading={loading}
                                disabled={insight.task?.description ? insight.task?.description !== desc : !desc}
                            >
                                Save
                            </Button>
                        </div>
                        <TextArea
                            rows={4}
                            value={desc}
                            placeholder="Description"
                            onChange={(ev) => setDesc(ev.target.value)}
                            defaultValue=""
                        />
                        {
                            subtasks.map((subtask, idx) => (
                                <Subtask
                                    key={subtask.description}
                                    completed={subtask.completed}
                                    description={subtask.description}
                                    onSave={(sbtsk) => {
                                        saveSubtasks(sbtsk, idx);
                                    }}
                                    onDelete={() => {
                                        deleteSubtask(idx);
                                    }}
                                />
                            ))
                        }
                        <div
                            className="flex-center-row justify-center pointer pa-2 mt-2 br-sm mediumBlue--text"
                            style={{
                                borderStyle: 'dashed',
                                borderWidth: "1px",
                                borderColor: colors.mediumBlue,
                            }}
                            onClick={() => {
                                setSubtasks(subtasks.concat({ description: '', completed: false }));
                            }}
                        >
                            + Add Subtask
                        </div>
                    </Col>
                    <Col
                        span={6}
                        className="lightBackground py-4"
                        style={{
                            minHeight: "100%",
                            borderBottomRightRadius: 16,
                            borderLeftStyle: "solid",
                            borderLeftWidth: 1,
                            borderLeftColor: 'rgba(5, 5, 5, 0.06)',
                        }}
                    >
                        <div className="px-4">
                            <Row className="flex-center-between mb-2">
                                <Col span={4}>
                                    <Icon
                                        name="DollarOutlined"
                                        className="mr-2"
                                        color={colors.darkBlue}
                                        backgroundColor={colors.white}
                                        borderRadius={4}
                                        size={24}
                                        padding={8}
                                    />
                                </Col>
                                <Col span={18}>
                                    <h5 style={{ fontSize: 16 }}>
                                        {formatShortUSD(Number(insight.amount))}
                                    </h5>
                                </Col>
                            </Row>
                            <Row className="flex-center-between">
                                <Col span={4}>
                                    <Icon
                                        name="CalendarOutlined"
                                        className="mr-2"
                                        color={colors.darkBlue}
                                        backgroundColor={colors.white}
                                        borderRadius={4}
                                        size={24}
                                        padding={8}
                                    />
                                </Col>
                                <Col span={18}>
                                    <h5 style={{ fontSize: 16 }}>
                                        {moment(insight.createdAt).format('DD MMM YYYY')}
                                    </h5>
                                </Col>
                            </Row>
                        </div>
                        <Divider />
                        <div className="px-4">
                            <div className="my-2">
                                <h5>
                                    Due Date
                                </h5>
                                <Datetime
                                    initialValue={moment(insight.doBye).format('YYYY-MM-DD')}
                                    className={`w100 datetimeinput ${show ? '' : 'none'}`}
                                    onChange={(e) => handleDateChange(e)}
                                    inputProps={{
                                        onClick: () => { setShow(!show); },
                                    }}
                                />
                            </div>
                            <div className="my-2">
                                <h5>
                                    Priority
                                </h5>
                                <Select
                                    defaultValue={insight.priority}
                                    onChange={updatePriority}
                                    className="w100"
                                    options={[
                                        { value: 'Low' },
                                        { value: 'Medium' },
                                        { value: 'High' },
                                    ]}
                                />
                            </div>
                            <div className="my-2">
                                <h5>
                                    Status
                                </h5>
                                <Select
                                    defaultValue={insight.status}
                                    onChange={updateStatus}
                                    className="w100"
                                    options={[
                                        { value: 'Not started' },
                                        { value: 'In progress' },
                                        { value: 'Completed' },
                                    ]}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default InsightModal;
