import { Table, Tooltip } from "antd";
import { ColumnType } from "antd/es/table";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import Icon from "../atoms/Icon";
import { RootState } from "../../configureStore";
import { Connector } from "../../redux/reducer/administration";

interface ConnectorsParams {
    onDelete: (id: string) => void;
}

const Connectors = ({
    onDelete,
}: ConnectorsParams) => {
    const isViewAs = useSelector<RootState, boolean>(({ administration: { viewAs } }) => Boolean(viewAs));
    const connectors = useSelector<RootState, Connector[]>(({ administration }) => (
        administration.viewAs
            ? administration.institutions.find(({ id }: { id: string }) => id === administration.viewAs).connectors
            : administration.tenant.connectors
    ));
    const columns: ColumnType<any>[] = useMemo<ColumnType<any>[]>(() => [
        {
            title: (
                <div>
                    Connectors
                    <Tooltip>
                        <Tooltip
                            trigger={['hover', 'focus', "click"]}
                            // eslint-disable-next-line react/no-unstable-nested-components
                            title={() => (
                                <div style={{ textAlign: 'center' }}>
                                    <div>App Registration for API Access</div>
                                </div>
                            )}
                        >
                            <Icon
                                className="ml-1"
                                name="InfoCircleOutlined"
                            />
                        </Tooltip>
                    </Tooltip>
                </div>
            ),
            dataIndex: 'name',
        },
        {
            title: 'App Registration ID',
            dataIndex: 'id',
        },
        {
            title: null,
            dataIndex: '',
            render: (_id: string, record: Connector) => !isViewAs && <Icon onClick={() => { onDelete(record.id); }} name="DeleteOutlined" />,
        },
    ], []);

    return (
        <Table
            rowKey={({ id }) => id}
            dataSource={connectors}
            columns={columns}
        />
    );
};

export default Connectors;
