import moment from "moment";
import { useState } from "react";
import Datetime from 'react-datetime';
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import { RootState } from "../../configureStore";
import { Insight, updateInsight } from "../../redux/reducer/insight";

interface DateTimeParams {
    id: string;
}

const InsightEventPicker = ({
    id,
}: DateTimeParams) => {
    const dispatch = useDispatch<any>();
    const [selectedDate, setSelectedDate] = useState('');
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['insights/UPDATE_INSIGHTS']);
    const insight = useSelector<RootState, Insight>(({ insight: { allInsights } }) => allInsights[id]);

    function handleDateChange(datetime: string | moment.Moment) {
        if (typeof datetime === 'string') {
            setSelectedDate(moment(datetime).format('YYYY-MM-DD HH:mm:ss'));
        } else {
            setSelectedDate(datetime.format('YYYY-MM-DD HH:mm:ss'));
        }
    }

    const save = () => {
        if (selectedDate) {
            dispatch(updateInsight({
                insight,
                event: moment(selectedDate).toDate().toISOString(),
            }));
        }
    };

    return (
        <div
            className="flex-center-center col"
            onClick={(e) => { e.stopPropagation(); }}
        >
            <Datetime
                initialValue={insight.event ? moment(insight.event) : undefined}
                onChange={(e) => { handleDateChange(e); }}
                inputProps={{ placeholder: 'Select date and time' }}
                open
                input={false}
            />

            <p className="w100">Selected date: {selectedDate}</p>

            <Button className="w100 mt-2" loading={loading} onClick={save}>Save</Button>
        </div>
    );
};

export default InsightEventPicker;
