import { Button, Form, Modal, Select, Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../configureStore";
import { addGroup, delGroup, getTenant } from "../../redux/reducer/administration";
import Icon from "../atoms/Icon";
import InputField from "../atoms/InputField";

const Groups = () => {
    const dispatch = useDispatch<any>();
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['administration/GET_TENANT']);
    const data = useSelector<RootState, any>(({ administration }) => administration);

    const [addUser, setAddUser] = useState(false);

    useEffect(() => {
        dispatch(getTenant());
    }, []);

    const addGroupSubmit = (role: {
        id: string;
        name: string;
        role: string;
    }) => {
        dispatch(addGroup(role));
        setAddUser(false);
    };

    const delGroupSubmit = (id: string) => {
        dispatch(delGroup({ id }));
    };

    return (
        <div>
            <Modal
                open={addUser}
                onCancel={() => { setAddUser(false); }}
                destroyOnClose
                onOk={() => {
                    setAddUser(false);
                }}
                closable={false}
                footer={null}
            >
                <Form onFinish={addGroupSubmit}>
                    <Form.Item name="id"><InputField placeholder="Group ID" required /></Form.Item>
                    <Form.Item name="name"><InputField placeholder="Group Name" required /></Form.Item>
                    <Form.Item name="role">
                        <Select
                            className="background required"
                            placeholder="Role"
                            options={data.roleNames.map((r: string) => ({ value: r }))}
                            size="large"
                        />
                    </Form.Item>
                    <div className="flex-center-end">
                        <Button className="mx-1" type="ghost" htmlType="reset">Reset</Button>
                        <Button className="mx-1" type="primary" htmlType="submit">Save</Button>
                    </div>
                </Form>
            </Modal>
            <div className="flex-center-between ma-2">
                <h2>
                    User Roles
                </h2>
                <Button type="primary" onClick={() => setAddUser(true)}>
                    Add
                </Button>
            </div>
            <Table
                columns={[
                    {
                        title: 'Group ID',
                        dataIndex: 'id',
                    },
                    {
                        title: 'Group Name',
                        dataIndex: 'name',
                    },
                    {
                        title: 'Group Role',
                        dataIndex: 'role',
                    },
                    {
                        title: 'Delete',
                        dataIndex: 'id',
                        render: (id) => <Button type="default" onClick={() => delGroupSubmit(id)}><Icon name="DeleteOutlined" /></Button>,
                    },
                ]}
                dataSource={data.tenant.userRoles}
                loading={loading}
            />
        </div>
    );
};

export default Groups;
