/* eslint-disable react/no-unstable-nested-components */
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Table } from "antd";
import { ColumnType } from "antd/es/table";
import { useLocation } from "react-router-dom";
import { formatUSD } from "../../../utils";
import { RootState } from "../../../configureStore";
import Details from "./Details";
import Profile from "../../molecules/Profile";
import { Client } from "../../../redux/reducer/clients";
import { Insight } from "../../../redux/reducer/insight";
import InsightMenu from "../../molecules/InsightMenu";
import { Employee } from "../../../redux/reducer/employees";
import { Me } from "../../../redux/reducer/auth";
import InsightInstitutions from "../../molecules/InsightInstitutions";

interface InsightsTableParams {
    priority?: string | null;
    status?: string | null;
    clientId?: string | null;
    myClients?: boolean;
}

const getNameFilters = (clients: any[] = []) => {
    const names = Array.from(new Set<string>(clients.map((client: any) => client.clientName)));
    return names.map((clientName) => ({
        text: clientName,
        value: clientName,
    }));
};

const getFilters = (clients: any[] = []) => {
    const names = Array.from(new Set<string>(clients.map((client: any) => client.insight)));
    return names.map((insight) => ({
        text: insight,
        value: insight,
    }));
};

const getEmployeeNames = (employees: Employee[], me: Me) => employees.map(({ firstName, lastName }) => ({
    text: `${firstName} ${lastName}`,
    value: `${firstName} ${lastName}`,
})).concat({
    text: `${me.firstName} ${me.lastName}`,
    value: `${me.firstName} ${me.lastName}`,
});

export const ClientCol = (
    { record, isClient = false }: { record: any; isClient?: boolean },
) => {
    const client = useSelector<RootState, Client>(({ clients }) => clients.allClients[isClient ? record.id : record.clientId] ?? {});
    return (
        <div>
            <Profile
                firstName={client?.firstName}
                lastName={client?.lastName}
                image={client?.imageUrl}
                className="mr-2 br-sm"
                size="52px"
            />
        </div>
    );
};

const catFil = [
    { text: 'Income', value: 'Income' },
    { text: 'Investment', value: 'Investment' },
    { text: 'Property', value: 'Property' },
    { text: 'Other', value: 'Other' },
    { text: 'Life Event', value: 'Life Event' },
];

const InsightsTable = ({
    priority,
    status,
    clientId,
    myClients = false,
}: InsightsTableParams) => {
    const location = useLocation();
    const [pageSize, setPageSize] = useState(10);
    const loading = useSelector<RootState, boolean>(({ status: stateStat }) => stateStat.loading['insights/GET_INSIGHTS']);
    const me = useSelector<RootState, Me>(({ auth }) => auth.me);
    const data = useSelector<RootState, Insight[]>(({ insight: { insights, allInsights }, clients: { allClients } }) => {
        const arr: Insight[] = [];
        insights.forEach((id: string) => {
            const insight: Insight = allInsights[id];
            if (clientId) {
                if (clientId === insight.clientId) {
                    arr.push(allInsights[id]);
                }
            } else if (myClients) {
                if (me.id === allClients[insight.clientId]?.advisorId) {
                    arr.push(allInsights[id]);
                }
            } else {
                arr.push(allInsights[id]);
            }
        });
        return arr;
    });
    const tlr = useSelector<RootState, any>(({ auth }) => auth.tlr);
    const employees = useSelector<RootState, { [key: string]: Employee }>(({ employees: { allEmployees } }) => allEmployees);
    const clients = useSelector<RootState, { [key: string]: Client }>(({ clients: { allClients } }) => allClients);

    const onPageChange = (pageNum: number, pageSizeNew: number) => {
        setPageSize(pageSizeNew);
    };

    const columns: ColumnType<any>[] = useMemo(() => {
        const cols: ColumnType<any>[] = [
            {
                title: 'Category',
                dataIndex: 'category',
                sorter: (a: any, b: any) => (a.category > b.category ? -1 : 1),
                filters: catFil,
                onFilter: (value: any, record: any) => record.category.includes(value),
            },
            {
                title: 'Event',
                dataIndex: 'insight',
                sorter: (a: any, b: any) => (a.insight > b.insight ? -1 : 1),
                filters: getFilters(data),
                filterSearch: true,
                onFilter: (value: any, record: any) => record.insight.includes(value),
            },
            {
                title: 'Institution',
                dataIndex: 'institution',
                render: (inst: any, record: Insight) => (
                    record?.parameters?.length > 1
                        ? (
                            <InsightInstitutions
                                institutionName={inst}
                                parameters={record.parameters}
                            />
                        )
                        : (<div style={{ textTransform: 'capitalize' }}>{inst?.toLowerCase()}</div>)
                ),
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                sorter: ({ amount: a }: any, { amount: b }: any) => a - b,
                render: (item: any) => (
                    <div>
                        {formatUSD(item)}
                    </div>
                ),
            },
            {
                title: 'Date',
                dataIndex: 'createdAt',
                sorter: ({ createdAt: a }: any, { createdAt: b }: any) => a - b,
                render: (date: string) => (
                    <div>
                        {moment(date).format('MMM DD yyyy')}
                    </div>
                ),
            },
            {
                title: '',
                dataIndex: 'id',
                render: (id: string) => <InsightMenu id={id} />,
            },
        ];
        if (!(location.pathname.startsWith('/clients') || location.pathname.startsWith('/manager'))) {
            cols.unshift({
                title: 'Client',
                dataIndex: 'clientName',
                sorter: (a: any, b: any) => (a.clientName > b.clientName ? -1 : 1),
                render: (_item: string, record: any) => (
                    <ClientCol record={record} />
                ),
                filters: getNameFilters(data),
                filterSearch: true,
                onFilter: (value: any, record: any) => record.clientName.includes(value),
            });
        }

        if (tlr !== 'advisor' && !(location.pathname.startsWith('/clients') || location.pathname.startsWith('/manager'))) {
            const col: ColumnType<any> = {
                title: 'Advisor',
                dataIndex: 'clientId',
                render: (id: string) => (
                    <Profile
                        firstName={employees?.[clients?.[id]?.advisorId]?.firstName ?? me.firstName}
                        lastName={employees?.[clients?.[id]?.advisorId]?.lastName ?? me.lastName}
                        image={employees?.[clients?.[id]?.advisorId] ? employees?.[clients?.[id]?.advisorId].imageUrl : me?.imageUrl}
                        className="mr-2 br-sm"
                        size="52px"
                    />
                ),
                filters: getEmployeeNames(Object.values(employees), me),
                filterSearch: true,
                onFilter: (value: any, { id }) => {
                    const firstName = employees?.[clients?.[id]?.advisorId]?.firstName ?? me.firstName;
                    const lastName = employees?.[clients?.[id]?.advisorId]?.lastName ?? me.lastName;
                    const name = `${firstName} ${lastName}`;
                    return name.includes(value);
                },
            };
            cols.unshift(col);
        }

        return cols;
    }, [data, location.pathname, tlr, employees, me]);

    const insights = useMemo(() => {
        if (priority || status) {
            return data.filter((datum: any) => {
                const pMatch = priority ? datum.priority === priority : true;
                const sMatch = status ? datum.status === status : true;
                return (pMatch && sMatch);
            });
        }
        return data;
    }, [
        priority,
        status,
        data,
    ]);

    return (
        <Table
            className="mt-4"
            expandRowByClick
            dataSource={insights}
            columns={columns}
            pagination={{ pageSize, total: insights?.length ?? 0, onChange: onPageChange }}
            rowKey={(record) => record.id}
            loading={loading}
            expandedRowRender={(record) => (
                <Details insightId={record.id} />
            )}
        />
    );
};

export default InsightsTable;
