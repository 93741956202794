import { Button, Col, Form, Row, Select, Upload } from "antd";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../configureStore";
import { Me, uploadImage } from "../../redux/reducer/auth";
import { createEmployee, Employee, updateEmployee } from "../../redux/reducer/employees";
import InputField from "../atoms/InputField";
import Icon from "../atoms/Icon";
import Profile from "../molecules/Profile";
import colors from "../../utils/colors";

interface AddEditUserParams {
    user?: Employee;
    setConfirm?: (val: any) => void;
    editing?: boolean;
    setOpen?: (val: boolean) => void;
    allowAssign?: boolean;
    isMe?: boolean;
}

interface Validations {
    firstName: "success" | "error" | "warning" | "validating";
    lastName: "success" | "error" | "warning" | "validating";
    salesforceEmail: "success" | "error" | "warning" | "validating";
    email: "success" | "error" | "warning" | "validating";
    phone: "success" | "error" | "warning" | "validating";
}

const AddEditUser = ({
    user,
    setConfirm,
    editing = false,
    setOpen = () => { },
    allowAssign = true,
    isMe = false,
}: AddEditUserParams) => {
    const dispatch = useDispatch<any>();
    const myEmployees = useSelector<RootState, { value: string; label: string }[]>(({ employees: { myEmployees: mine, allEmployees } }) => mine.map((id) => {
        const allEmp: {
            [key: string]: Employee;
        } = allEmployees;
        return {
            value: id,
            label: allEmp[id].email,
        };
    }));
    const allEmp = useSelector<RootState, { [key: string]: Employee; }>(({ employees }) => employees.allEmployees);
    const viewAs = useSelector<RootState, string>(({ administration }) => administration.viewAs);
    const me = useSelector<RootState, Me>(({ auth }) => auth.me);
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['employees/CREATE_EMPLOYEE'] || status.loading['employees/UPDATE_EMPLOYEE']);

    const editUser = (info: {
        firstName: string;
        lastName: string;
        email: string;
        managerId: any;
        phone?: string;
        salesforceEmail?: string;
    }) => {
        const toUser = isMe ? me : user;
        if (
            info.firstName !== toUser?.firstName
            || info.lastName !== toUser?.lastName
            || info.email !== toUser?.email
            || info.phone !== toUser?.phone
            || info.salesforceEmail !== toUser?.salesforceEmail
            || (!isMe && info.managerId !== toUser?.managerId)
        ) {
            dispatch(updateEmployee({
                id: toUser?.id,
                ...(info.firstName && info.firstName !== toUser?.firstName) && { firstName: info.firstName },
                ...(info.lastName && info.lastName !== toUser?.lastName) && { lastName: info.lastName },
                ...(info.email && info.email !== toUser?.email) && { email: info.email },
                ...(!isMe && info.managerId && info.managerId !== toUser?.managerId) && { managerId: info.managerId },
                phone: info.phone,
            }));
            setOpen(false);
        }
    };

    const createUser = (info: {
        managerId: any;
        firstName: string;
        lastName: string;
        email: string;
        salesforceUser?: string;
        phone?: string;
    }) => {
        dispatch(createEmployee({
            ...info,
            managerId: allowAssign ? info.managerId.value ?? info.managerId : me.id,
            isMe: allowAssign ? info.managerId.value === me.id : true,
        }));
        setOpen(false);
    };

    const [validations, setValidations] = useState<Validations>({
        firstName: user?.firstName.length ? "success" : "error",
        lastName: user?.lastName.length ? "success" : "error",
        email: user?.email.match(/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/) ? "success" : "error",
        salesforceEmail: (!user?.salesforceEmail || user?.salesforceEmail.length === 0 || user?.salesforceEmail?.match(/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/)) ? "success" : "error",
        phone: user?.phone ? user?.phone.match(/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/) ? "success" : "error" : "success",
    });

    const onChange = (e: any) => {
        switch (e.target.id) {
            case 'firstName':
                setValidations({
                    ...validations,
                    firstName: e.target.value?.length ? "success" : "error",
                });
                break;
            case 'lastName':
                setValidations({
                    ...validations,
                    lastName: e.target.value?.length ? "success" : "error",
                });
                break;
            case 'email':
                setValidations({
                    ...validations,
                    email: e.target.value?.match(/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/) ? "success" : "error",
                });
                break;
            case 'salesforceEmail':
                setValidations({
                    ...validations,
                    salesforceEmail: (e.target.value.length === 0 || e.target.value?.match(/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/)) ? "success" : "error",
                });
                break;
            case 'phone':
                setValidations({
                    ...validations,
                    phone: e.target.value?.match(/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/) || !e.target.value ? "success" : "error",
                });
                break;
            default: break;
        }
    };

    const handleUpload = (e: any) => {
        const data = new FormData();
        data.append('file', e.file);

        dispatch(uploadImage({
            data,
        }));
    };

    const assignmentOptions = useMemo<{ value: string; label: string }[]>(() => {
        if (viewAs) {
            const temp: { value: string; label: string }[] = [];
            Object.values(allEmp).forEach((emp) => {
                if (emp.tlr && emp.tlr !== 'advisor') {
                    temp.push({
                        value: emp.id,
                        label: emp.email,
                    });
                }
            });
            return temp;
        }
        return [{ value: me.id, label: me.email }, ...myEmployees];
    }, [viewAs, me, myEmployees]);

    return (
        <>
            <h3 className="mb-4">
                {isMe ? 'Personal Information' : `${editing ? 'Edit' : 'Add'} User`}
            </h3>
            <Form
                title="user"
                onFinish={editing ? editUser : createUser}
                onChange={onChange}
                initialValues={(isMe ? me : user) ?? { managerId: assignmentOptions[0] }}
            >
                {(isMe) && (
                    <Row className="mb-4">
                        <Col>
                            <Profile
                                noName
                                firstName={me.firstName}
                                lastName={me.lastName}
                                image={me.imageUrl}
                                size={64}
                            />
                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: -8,
                                    right: 0,
                                }}
                            >
                                <Upload
                                    accept=".jpg,.png,.jpeg"
                                    onChange={(e) => {
                                        if (!e.file || !e.file.size) return;
                                        // eslint-disable-next-line no-alert
                                        if (e.file.size > 614400) alert('Max file size 600KB');
                                        else {
                                            handleUpload(e);
                                        }
                                    }}
                                    beforeUpload={() => false}
                                    // eslint-disable-next-line react/no-unstable-nested-components
                                    itemRender={() => <div style={{ display: 'none' }} />}
                                    listType="picture"
                                    method="PATCH"
                                >
                                    <Icon
                                        name="EditOutlined"
                                        backgroundColor={colors.background}
                                        color={colors.black}
                                        className="pointer"
                                        padding={8}
                                    />
                                </Upload>
                            </div>
                        </Col>
                    </Row>
                )}
                <Row className="flex-center-between">
                    <Col span={11}><Form.Item name="firstName"><InputField placeholder="First Name" required /></Form.Item></Col>
                    <Col span={11}><Form.Item name="lastName"><InputField placeholder="Last Name" required /></Form.Item></Col>
                </Row>
                <Row className="flex-center-between">
                    <Col span={11}><Form.Item name="email"><InputField disabled={editing} placeholder="Email" required /></Form.Item></Col>
                    <Col span={11}><Form.Item validateStatus={validations.phone} name="phone"><InputField placeholder="Phone" /></Form.Item></Col>
                </Row>
                <Row className="flex-center-between">
                    <Col span={11}><Form.Item name="salesforceEmail"><InputField placeholder="Salesforce Email (if necessary or different than email)" /></Form.Item></Col>
                </Row>
                {
                    (allowAssign && !isMe) && (
                        <Row>
                            <Col span={11}>
                                <h4 className="mb-1">
                                    Assigned to:
                                </h4>
                                <Form.Item name="managerId" required>
                                    <Select
                                        disabled={user?.tlr === 'defaultadmin'}
                                        className="background required br-md"
                                        placeholder="Manager"
                                        options={assignmentOptions}
                                        size="large"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    )
                }
                <div className="flex-center row-r">
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        {editing ? 'Save' : 'Create'}
                    </Button>
                    {(editing && !isMe) && (
                        <Button
                            // type="primary"
                            className="dangerButton mx-2"
                            onClick={() => {
                                if (setConfirm) setConfirm(true);
                            }}
                        >
                            Delete
                        </Button>
                    )}
                </div>
            </Form>
        </>
    );
};

export default AddEditUser;
